import { FormLabel as Label } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as w05f0004Actions from "../../../../redux/W0X/W05F0004/W05F0004_actions";
import ButtonGeneral from "../../../common/button/button-general";
import { Combo, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import MForm from "../../../common/form-material/form";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Filter from "../../../filter/filter";
import W05F0004DataGrid from "./W05F0004DataGrid";

const styles = {
    viewMore: {
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        background: '#F7F9FC',
    },
};

class W05F0004 extends Component {
    constructor(props) {
        super(props);
        const url = new window.URLSearchParams(window.location.search);
        this.state = {
            loaded: true,
            loadedReadMore: false,
            listContract: { rows: [], total: 0 },
            isPermission: 0,
            dataCboListObjects: {
                rows: [],
                total: 0,
            },
            total: 0,
            dataSources: [],
            tabStatus: [],
            loadingCboListObjects: false,
            loadingCboStatus: false,
            error: {},
            showFormItem: false,
            cboStatus: [],
            modalOpen: true,
            slicePage: 2,
            btnReadMore: false,
            disabled: false,
            ContractNo: "",
            dateFrom: moment().format('YYYY-MM-DD'),
            dateTo: moment().format('YYYY-MM-DD'),
        };
        this.filter = {
            Status: "",
            ContractNo: url.get('ContractNo') ?? "",
            Object: [],
            selectedDate: 7, // 7 Default giá trị là Year
            firstDayMonthDateFrom: moment().startOf("year").format("YYYY-MM-DD"), // 7 Default giá trị là Year
            lastDayMonthDateTo: moment().endOf("year").format("YYYY-MM-DD"), // 7 Default giá trị là Year
            skip: 0,
            limit: 2,
            StrSearch: "",
            ContractItemID: url.get('ContractItemID') ?? "",
            FormID: url.get('FormID') ?? "W05F0004"
        };
        this.filterCboListObject = {
            strSearch: "",
            ObjectTypeID: "",
            skip: 0,
            limit: 50,
        };
        this.filterCboStatus = {
            GroupID: "",
            Language: "",
        };
        this.toolFilter = null;
        this.dataDayByCombo = [
            {
                id: 1,
                fieldName: Config.lang("ERP_Hom_nay"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
            {
                id: 2,
                fieldName: Config.lang("ERP_Hom_qua"),
                dateFrom: moment().subtract(1, 'day').format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, 'day').format("YYYY-MM-DD")
            },
            {
                id: 3,
                fieldName: Config.lang("ERP_Tuan_nay"),
                dateFrom: moment().startOf('week').format("YYYY-MM-DD"),
                dateTo: moment().endOf('week').format("YYYY-MM-DD")
            },
            {
                id: 4,
                fieldName: Config.lang("ERP_Tuan_truoc"),
                dateFrom: moment().subtract(1, 'week').startOf('week').format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, 'week').endOf('week').format("YYYY-MM-DD")
            },
            {
                id: 5,
                fieldName: Config.lang("ERP_Thang_nay"),
                dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 6,
                fieldName: Config.lang("ERP_Thang_truoc"),
                dateFrom: moment().subtract(1, 'months').startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 7,
                fieldName: Config.lang("ERP_Nam_nay"),
                dateFrom: moment().startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 8,
                fieldName: Config.lang("ERP_Nam_truoc"),
                dateFrom: moment().subtract(1, 'years').startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, 'years').endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 9,
                fieldName: Config.lang("ERP_Chon_thoi_gian"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
        ];
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ["loading" + key]: status });
    };

    loadCboListObjects = (isReset) => {
        const { skip, limit, ObjectTypeID, strSearch } = this.filterCboListObject;
        const params = {
            ObjectTypeID: ObjectTypeID ? ObjectTypeID : "KH",
            StrSearch: strSearch ? strSearch : "",
            skip: skip,
            limit: limit,
        };
        this.setLoading("CboListObjects", true);
        this.props.w05f0004Actions.getListObjects(params, (error, data) => {
            this.setLoading("CboListObjects", false);
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListObjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboListObjects: {
                        rows: isReset ? rows : dataCboListObjects.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadCboStatus = (type) => {
        this.setLoading("CboStatus", true);
        const params = {
            GroupID: type === 'ChangeStatus' ? "D" : "M",
            Language: Config.language || "84",
        };
        this.props.w05f0004Actions.getCboStatus(params, (error) => {
            this.setLoading("CboStatus", false);
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    handleFilterChange(key, data) {
        if (!key) return false;
        switch (key) {
            case "dateFrom":
                this.setState({ dateFrom: data.value });
                this.filter.firstDayMonthDateFrom = data.value;
                break;
            case "id":
                this.showFormSearchDay(key, data);
                break;
            case "dateTo":
                this.setState({ dateTo: data.value });
                this.filter.lastDayMonthDateTo = data.value;
                break;
            case "ObjectID":
                this.filter.Object = data.value;
                break;
            case "ContractNo":
                this.setState({ ContractNo: data.target.value })
                this.filter.ContractNo = data.target.value;
                break;
            case "Status":
                this.filter.Status = data.value;
                break;
            default:
                break;
        }
    }

    showFormSearchDay = (key, data) => {
        if (!key || !data || !data.value || _.isEmpty(this.dataDayByCombo)) return false;
        const checked = this.dataDayByCombo.find(value => value.id === data.value);
        const showFormItem = checked.id === 9; // 9 Là tùy Chọn Ngày
        this.filter.selectedDate = data.value;
        this.filter.firstDayMonthDateFrom = checked.dateFrom;
        this.filter.lastDayMonthDateTo = checked.dateTo;
        this.setState({ showFormItem });
    };

    renderFilters = () => {
        const { getCboStatus } = this.props;
        const {
            Object,
            Status,
            selectedDate,
            firstDayMonthDateFrom,
            lastDayMonthDateTo,
        } = this.filter;
        const {
            dataCboListObjects,
            loadingCboListObjects,
            error,
            showFormItem,
            loadingCboStatus,
            ContractNo,
            dateFrom,
            dateTo
        } = this.state;
        return (
            <Filter
                dropdownProps={{ ref: ref => this.toolFilter = ref }}
                onTextChanged={this.onSearch}
                placeholder={Config.lang("ERP_Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={Config.storeDataSoureDevExtreme(this.dataDayByCombo)}
                                            valueExpr={"id"}
                                            stylingMode={"outlined"}
                                            label={Config.lang("ERP_Chon_thoi_gian")}
                                            displayExpr={"fieldName"}
                                            value={selectedDate || 7}
                                            onValueChanged={(e) => this.handleFilterChange("id", e)}
                                        />
                                    </Col>
                                </Row>

                                {showFormItem ? (
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <div className={"display_row align-center"}>
                                                <Label className={"mgr5"}>
                                                    {Config.lang("ERP_Tu")}
                                                </Label>
                                                <DateBoxPicker
                                                    error={error && error["DateFrom"]}
                                                    max={dateTo}
                                                    placeholder={"DD/MM/YYYY"}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    width={"100%"}
                                                    value={firstDayMonthDateFrom}
                                                    onValueChanged={(e) =>
                                                        this.handleFilterChange("dateFrom", e)
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <div className={"display_row align-center"}>
                                                <Label className={"mgr5"}>
                                                    {Config.lang("ERP_Den")}
                                                </Label>
                                                <DateBoxPicker
                                                    error={error && error["DateTo"]}
                                                    min={dateFrom}
                                                    placeholder={"DD/MM/YYYY"}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    width={"100%"}
                                                    value={lastDayMonthDateTo}
                                                    onValueChanged={(e) =>
                                                        this.handleFilterChange("dateTo", e)
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                ) : (
                                    ""
                                )}
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div>
                                            <TextField
                                                label={Config.lang("ERP_So_hop_dong")}
                                                variant="outlined"
                                                value={ContractNo}
                                                onChange={(e) =>
                                                    this.handleFilterChange("ContractNo", e)
                                                }
                                                fullWidth
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboListObjects}
                                            skip={this.filterCboListObject.skip}
                                            limit={this.filterCboListObject.limit}
                                            displayExpr={"ObjectNameU"}
                                            keyExpr={"ObjectID"}
                                            valueExpr={"ObjectID"}
                                            value={Object}
                                            loading={loadingCboListObjects}
                                            stylingMode={"outlined"}
                                            placeholder={Config.lang("ERP_Khach_hang")}
                                            showClearButton={true}
                                            onValueChanged={(e) =>
                                                this.handleFilterChange("ObjectID", e)
                                            }
                                            onInputChanged={(e) => {
                                                this.filterCboListObject.strSearch = e.target.value;
                                                this.filterCboListObject.skip = 0;
                                                this.loadCboListObjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboListObject.skip = e.skip;
                                                this.filterCboListObject.limit = e.limit;
                                                this.loadCboListObjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={getCboStatus}
                                            valueExpr={"Status"}
                                            loading={loadingCboStatus}
                                            stylingMode={"outlined"}
                                            label={Config.lang("ERP_Trang_thai")}
                                            displayExpr={"StatusName"}
                                            showClearButton={true}
                                            value={Status}
                                            onValueChanged={(e) => {
                                                this.handleFilterChange("Status", e)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("ERP_Tim_kiem")}
                                    size={"large"}
                                    typeButton={"search"}
                                    onClick={this.onFilter}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    redirectScreen = (mode, e = "") => {
        let screen = "";
        let params = {};
        switch (mode) {
            case "add":
                screen = "W05F0005";
                params.mode = 'add';
                break;
            default:
                break;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: params
        });
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    loadListDeployments = (eReset, cb, toolFilterStatus) => {
        const { firstDayMonthDateFrom, lastDayMonthDateTo } = this.filter;

        if (
            (firstDayMonthDateFrom !== null && lastDayMonthDateTo === null) ||
            (firstDayMonthDateFrom === null && lastDayMonthDateTo !== null)
        ) {
            const validateRules = [
                {
                    name: "DateFrom",
                    rules: "isRequired",
                    value: firstDayMonthDateFrom,
                },
                {
                    name: "DateTo",
                    rules: "isRequired",
                    value: lastDayMonthDateTo,
                },
            ];
            const validateFrom = MForm.formValidation(validateRules);
            if (Object.keys(validateFrom).length > 0) {
                this.setStateErrorText(validateFrom);
                return false;
            }
        } else {
            this.setState({ error: {} });
            const { ContractNo, Object, skip, Status, limit, StrSearch, ContractItemID, FormID } = this.filter;
            const params = {
                Language: Config.language || "84",
                DateFrom: firstDayMonthDateFrom,
                DateTo: lastDayMonthDateTo,
                ContractNo: ContractNo ? ContractNo : "",
                ObjectID: Object && Object.ObjectID ? Object.ObjectID : "",
                Status: Status ? Status : "",
                skip: skip,
                limit: limit,
                StrSearch: StrSearch,
                ContractItemID: ContractItemID,
                FormID: FormID
            };
            this.setState({ loaded: true });
            this.props.w05f0004Actions.getListDeployments(params, (error, data) => {
                if (this.toolFilter && toolFilterStatus) {// Đóng dropdown
                    this.toolFilter.instance.close();
                }
                if (error) {
                    let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    this.setState({ loaded: false, loadedReadMore: false });
                    return false;
                }
                if (data) {
                    let { dataSources } = this.state;
                    const newData = data.rows ? data.rows : [];
                    if (cb) cb(data)
                    this.setState(
                        {
                            dataSources: !eReset ? dataSources.concat(newData) : newData,
                            total: data.total ? data.total : 0,
                        },
                        () => {
                            this.setState({ loaded: false, disabled: false, loadedReadMore: false });
                        }
                    );
                }
            });
        }
    };

    onLoadDataFilter = () => {
        // this.loadCboEmployees()
        this.loadCboStatus();
        this.loadCboListObjects();
    };

    onSearch = (e) => {
        if (this.timer) clearTimeout(this.timer);
        const value = e.target.value;
        this.timer = setTimeout(() => {
            this.filter.StrSearch = value;
            this.filter.skip = 0;
            this.filter.limit = 2;
            this.loadListDeployments(true, ({ total, rows }) => {
                this.setState({ btnReadMore: rows.length >= this.filter.limit ? total <= this.filter.limit ? false : true : false });
            });
        }, 500);
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W05F0004", (isPer) => {
            this.setState({ isPermission: isPer });
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.isPermission <= 0) return;
        this.loadListDeployments(null, ({ total }) => {
            this.setState({ btnReadMore: total <= this.filter.limit ? false : true })
        });
        this.loadCboStatus();
    };

    onFilter = () => {
        this.loadListDeployments(true, ({ total, rows }) => {
            this.setState({ btnReadMore: rows.length >= this.filter.limit ? total <= this.filter.limit ? false : true : false });
        }, true);
    };

    onReadMore = () => {
        this.setState({ disabled: true, loadedReadMore: true });
        const { total } = this.state;
        this.filter.skip += this.filter.limit;
        if (this.filter.skip + this.filter.limit >= total) {
            this.setState({ btnReadMore: false }, () => {
                this.filter.skip = 0;
            });
        }
        this.loadListDeployments();
    };

    render() {
        const { classes } = this.props;
        const {
            loaded,
            isPermission,
            dataSources,
            btnReadMore,
            disabled,
            loadedReadMore,
        } = this.state;
        return (
            <>
                <ActionToolbar allwaysTop title={Config.lang("ERP_Danh_sach_dot_trien_khai")} onBack={() => browserHistory.goBack()} >
                    <ButtonGeneral
                        disabled={!(isPermission > 1)}
                        name={Config.lang("ERP_Them")}
                        typeButton={"add"}
                        style={{ textTransform: "uppercase" }}
                        onClick={() => this.redirectScreen("add")}
                    />
                </ActionToolbar>
                <div className={"hidden"}>{this.renderFilters()}</div>
                {
                    dataSources &&
                    dataSources.map(({ detail, master }, index) => {
                        return (
                            <W05F0004DataGrid
                                key={index}
                                loadListDeployments={() => this.loadListDeployments(true)}
                                loadCboStatusDetail={(type) => this.loadCboStatus(type)}
                                loadingCboStatus={this.state.loadingCboStatus}
                                master={master}
                                dataSource={detail}
                                loaded={loaded}
                                loadedReadMore={loadedReadMore}
                                isPermission={isPermission && isPermission}
                            />
                        );
                    })
                }
                {
                    dataSources && dataSources.length < 1 && (
                        <W05F0004DataGrid master={{}} dataSource={[]} loaded={loaded} />
                    )
                }
                {
                    btnReadMore && (
                        <FormGroup className={classes.viewMore}>
                            <ButtonGeneral
                                fullWidth
                                size={"large"}
                                name={Config.lang("ERP_Xem_them")}
                                variant={"text"}
                                style={{
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                    color: '#687799',
                                }}
                                typeButton={"add"}
                                icon={require('./viewmore.svg')}
                                loading={disabled}
                                color={"inherit"}
                                onClick={() => this.onReadMore()}
                            />
                        </FormGroup>
                    )
                }
            </>
        );
    }
}

W05F0004.propsTypes = {
    w05f0004Actions: PropTypes.any,
};

export default compose(
    connect(
        (state) => ({
            getListDeployments: state.W05F0004.getListDeployments,
            getCboStatus: state.W05F0004.getCboStatus,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w05f0004Actions: bindActionCreators(w05f0004Actions, dispatch),
        })
    ),
    withStyles(styles)
)(W05F0004);
