/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/07/2020
 * @Example
 */
import { withStyles } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { compose } from "redux";
import Config from "../../../../config/index";
import { TextField } from "../../../common/form-material";

const styles = {
  labelNumberFormat: {
    padding: "6.5px 0 !important",
    transform: "translateY(10px) !important",
    color: "#8F9BB3"
  },
};
class W05F0003ShippingPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledQD: false
    };
  }

  componentDidMount = () => {
    const { getBaseCurrency, formDataMaster } = this.props;
    if (getBaseCurrency && getBaseCurrency.BaseCurrencyID === formDataMaster.CurrencyID) {
      this.setState({ disabledQD: true });
    }
  }

  render() {
    const { disabledQD } = this.state;
    const { formDataMaster, getDecimalQuantity, classes } = this.props;
    const { DeliveryMethodName, PortNameF, PortNameT, BankName, DepRate, SendDate, PaymentMethodName, DepAmount, DepCAmount, ReturnDate, DepositStatusName } = formDataMaster;

    return (
      <div style={{ width: "100%" }}>
        <Row>
          <Col md={12} lg={9}>
            <Row>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Phuong_tien_van_chuyen")}
                  variant={"standard"}
                  margin={"normal"}
                  value={DeliveryMethodName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("Thoi_gian_giao_hang_tu_ngay")}
                  variant={"standard"}
                  margin={"normal"}
                  value={!_.isNull(SendDate) ? SendDate : ""}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("Thoi_gian_giao_hang_den_ngay")}
                  variant={"standard"}
                  margin={"normal"}
                  value={!_.isNull(ReturnDate) ? ReturnDate : ""}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Cang_di")}
                  variant={"standard"}
                  margin={"normal"}
                  value={PortNameF}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Cang_den")}
                  variant={"standard"}
                  margin={"normal"}
                  value={PortNameT}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Phuong_thuc_thanh_toan")}
                  variant={"standard"}
                  margin={"normal"}
                  value={PaymentMethodName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Ngan_hang")}
                  variant={"standard"}
                  margin={"normal"}
                  value={BankName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <label className={"MuiFormLabel-root " + classes.labelNumberFormat}>
                  {Config.lang("ERP_Ty_le_deposit")}
                </label>
                <NumberFormat
                  fullWidth
                  customInput={TextField}
                  decimalScale={getDecimalQuantity.ExchangeRateDecimals}
                  thousandSeparator={true}
                  isNumericString={true}
                  disabled={true}
                  value={DepRate}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <label className={"MuiFormLabel-root " + classes.labelNumberFormat}>
                  {Config.lang("ERP_Thanh_tien_deposit")}
                </label>
                <NumberFormat
                  fullWidth
                  customInput={TextField}
                  decimalScale={getDecimalQuantity.OriginalDecimal}
                  thousandSeparator={true}
                  isNumericString={true}
                  disabled={true}
                  value={DepAmount}
                />
              </Col>
            </Row>
            <Row>
              {!disabledQD &&
                <Col xs={12} sm={6} md={4} lg={4}>
                  <label className={"MuiFormLabel-root " + classes.labelNumberFormat}>
                    {Config.lang("ERP_Thanh_tien_deposit_QD")}
                  </label>
                  <NumberFormat
                    fullWidth
                    customInput={TextField}
                    decimalScale={getDecimalQuantity.ConvertedDecimal}
                    thousandSeparator={true}
                    isNumericString={true}
                    disabled={true}
                    value={DepCAmount}
                  />
                </Col>
              }
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("Trang_thai_deposit")}
                  variant={"standard"}
                  margin={"normal"}
                  value={DepositStatusName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
            </Row>
          </Col>
          <Col md={0} lg={3}></Col>
        </Row>
      </div>
    );
  }
}

W05F0003ShippingPayment.propTypes = {
  formDataMaster: PropTypes.object,
  getDecimalQuantity: PropTypes.object,
};

export default compose(
  connect(state => ({
    getBaseCurrency: state.W05F0003.getBaseCurrency,
  })),
  withStyles(styles, { withTheme: true }))(W05F0003ShippingPayment);