/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/07/2020
 * @Example
 */
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { TextField } from "../../../common/form-material";
class W05F0003OtherInfo extends Component {

  renderSup = (dataList) => {
    const { formDataMaster } = this.props;
    const supColumn = dataList.map((item, idx) => {
      const { DataType, FieldName, Caption } = item;
      const numberToStringValue = _.isNumber(formDataMaster[FieldName]) ? (formDataMaster[FieldName]).toString() : formDataMaster[FieldName];
      const valueData = !_.isNull(numberToStringValue) ? numberToStringValue : "";
      return (
        <Col xs={12} sm={6} md={6} lg={6} key={idx}>
          <TextField
            label={Caption}
            variant={"standard"}
            margin={"normal"}
            disabled={true}
            multiline={(DataType.search("decimail") === 0 || DataType.search("datetime") === 0) ? false : true}
            value={valueData}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
          />
        </Col>
      )
    });
    return supColumn;
  }

  render() {
    const { dataMasterSupplement } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Row>
          <Col md={12} lg={9}>
            <Row>
              {dataMasterSupplement && this.renderSup(dataMasterSupplement)}
            </Row>
          </Col>
          <Col md={0} lg={3}></Col>
        </Row>
      </div>
    );
  }
}

W05F0003OtherInfo.propTypes = {
  formDataMaster: PropTypes.object,
};

export default compose(
  connect(state => ({
    dataMasterSupplement: state.W05F0003.dataMasterSupplement,
  }))
)(W05F0003OtherInfo);