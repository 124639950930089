import { FormLabel as Label } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Column } from "devextreme-react/data-grid";
import { LoadPanel } from 'devextreme-react/load-panel';
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as w05f0004Actions from "../../../../redux/W0X/W05F0004/W05F0004_actions";
import ButtonGeneral from "../../../common/button/button-general";
import Icons from "../../../common/icons/";
import GridActionBar from '../../../grid-container/grid-actionbar';
import GridContainer from "../../../grid-container/grid-container";
import PopoverAction from "../../../grid-container/popover-action";
import W05F0004FumiModal from "./W05F0004FumiModal";
import W05F0004LotModal from "./W05F0004LotModal";
import W05F0004PaymentModal from "./W05F0004PaymentModal";
import W05F0004VoucherModal from "./W05F0004VoucherModal";

const styles = {
    divIcon: {
        right: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        height: 36,
        padding: 0,
        zIndex: 999,
        position: 'absolute',
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        listStyle: 'none',
        marginTop: '-17px',
        borderRadius: 4,
    },
    divIconItem: {
        height: 30,
        width: 30,
        padding: '0 5px',
        whiteSpace: 'nowrap'
    },
    divRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    hiddenList: {
        opacity: 0,
        visibility: 'hidden',
    },
    divHeaderTitle: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '0.85rem',
        textTransform: 'uppercase',
        color: '#8F9BB3',
    },
    divHeaderItem: {
        marginLeft: 8,
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '16px',
    },
    divActionName: {
        height: 30,
        width: '100%',
        textAlign: 'left',
        display: 'block',
        padding: '0 5px'
    },
};

class W05F0004DataGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            openLotModal: false,
            openVoucherModal: false,
            openPaymentModal: false,
            isPermission: 0,
            currentFumi: {},
            loadCboFumiMethod: false,
            popOver: null,
        };
        this.filter = {
            limit: 5,
            skip: 0,
        };
        this.dataGrid = null;
        this.popoverAction = null;
        this.popoverGrid = null;
    }

    handleActionGridClick = (e, data) => {
        const target = e.currentTarget;
        this.setState({ currentFumi: data && data });
        if (this.popoverGrid) this.popoverGrid.instance.show(target);
    };

    handleActionClick = (e) => {
        const target = e.currentTarget;
        if (this.popoverAction) this.popoverAction.instance.show(target);
    };

    handleClose = (stateName) => {
        this.setState({ [stateName]: null });
    };

    renderButtonAction = () => {
        const { classes, master } = this.props;
        return (
            <>
                <div className={classes.divRight}>
                    <IconButton
                        aria-label={"view"}
                        aria-describedby={master.QuotationID}
                        className={classes.divIconItem}
                        onClick={(e) => this.handleActionClick(e)}
                        disabled={false}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </div>
            </>
        );
    };

    renderFumigation = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        return (
            <GridActionBar>
                <div>
                    <IconButton
                        aria-describedby={data.QuotationItemID}
                        aria-label={"view"}
                        className={classes.divIconItem}
                        onClick={(e) => this.handleActionGridClick(e, data)}
                        disabled={false}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </div>
            </GridActionBar>
        );
    };

    actionGridList = () => {
        const { isPermission, classes } = this.props;
        return (
            <>
                <PopoverAction
                    reference={ref => this.popoverGrid = ref}
                    position={Config.isMobile ? "right" : "left"}
                    maxWidth={300}
                    deferRendering={false}>
                    <div style={{ margin: '5px 10px' }}>
                        <Tooltip title={Config.lang("ERP_Phun_trung")}>
                            <ButtonGeneral
                                disabled={!(isPermission > 2)}
                                variant={"custom"}
                                aria-label={Config.lang("ERP_Phun_trung")}
                                name={Config.lang("ERP_Phun_trung")}
                                className={classes.divActionName}
                                onClick={() => {
                                    this.setState({ modalOpen: true });
                                    this.handleClose("popOver");
                                    this.popoverGrid.instance.hide(); // Modal nào mở thì ẩn tooltip
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Chuyen_trang_thai_Lot")}>
                            <ButtonGeneral
                                disabled={!(isPermission > 2)}
                                variant={"custom"}
                                aria-label={Config.lang("ERP_Chuyen_trang_thai_Lot")}
                                name={Config.lang("ERP_Chuyen_trang_thai_Lot")}
                                className={classes.divActionName}
                                onClick={() => {
                                    this.setState({ openLotModal: true });
                                    this.handleClose("popOver");
                                    this.props.loadCboStatusDetail("ChangeStatus");
                                    this.popoverGrid.instance.hide(); // Modal nào mở thì ẩn tooltip
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Cap_nhat_chung_tu")}>
                            <ButtonGeneral
                                disabled={!(isPermission > 2)}
                                variant={"custom"}
                                aria-label={Config.lang("ERP_Cap_nhat_chung_tu")}
                                name={Config.lang("ERP_Cap_nhat_chung_tu")}
                                className={classes.divActionName}
                                onClick={() => {
                                    this.setState({ openVoucherModal: true });
                                    this.handleClose("popOver");
                                    this.popoverGrid.instance.hide(); // Modal nào mở thì ẩn tooltip
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Thong_tin_thanh_toan")}>
                            <ButtonGeneral
                                disabled={!(isPermission > 2)}
                                variant={"custom"}
                                aria-label={Config.lang("ERP_Thong_tin_thanh_toan")}
                                name={Config.lang("ERP_Thong_tin_thanh_toan")}
                                className={classes.divActionName}
                                onClick={() => {
                                    this.setState({ openPaymentModal: true });
                                    this.handleClose("popOver");
                                    this.popoverGrid.instance.hide(); // Modal nào mở thì ẩn tooltip
                                }}
                            />
                        </Tooltip>
                    </div>
                </PopoverAction>
            </>
        )
    }

    showListAction = (e) => {
        const { classes, master, isPermission } = this.props;
        return (
            <>
                <PopoverAction
                    reference={ref => this.popoverAction = ref}
                    position={"left"}
                    maxWidth={300}
                    deferRendering={false}>
                    <div style={{ padding: 5 }}>
                        <Tooltip title={Config.lang("ERP_Xem")}>
                            <span>
                                <IconButton
                                    disabled={!(isPermission > 0)}
                                    aria-label={"View"}
                                    className={classes.divIconItem}
                                    onClick={() => this.redirectScreen("view", master)}
                                >
                                    <Icons name={"view"} />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Sua")}>
                            <span>
                                <IconButton
                                    disabled={!(isPermission > 2)}
                                    aria-label={"Edit"}
                                    className={classes.divIconItem}
                                    onClick={() => this.redirectScreen("edit", master)}
                                >
                                    <Icons name={"edit"} />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Sao_chep")}>
                            <span>
                                <IconButton
                                    disabled={!(isPermission > 1)}
                                    aria-label={"copy"}
                                    className={classes.divIconItem}
                                    onClick={() => this.redirectScreen("copy", master)}
                                >
                                    <Icons name={"copy"} />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Xoa")}>
                            <span>
                                <IconButton
                                    disabled={!(isPermission > 3)}
                                    aria-label={"delete"}
                                    className={classes.divIconItem}
                                    onClick={() => this.onDelete(master)}
                                >
                                    <Icons name={"delete"} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                </PopoverAction>
            </>
        )
    };

    onCloseModal = (type, rowData) => {
        this.setState({
            [type]: false,
            currentFumi: {},
        });
        if (rowData && this.dataGrid) {
            const dataSource = this.dataGrid.instance.option("dataSource");
            dataSource.forEach(data => {
                if (data.LotNo === rowData.LotNo) {
                    data.StatusDetail = rowData.StatusDetail;
                    data.StatusDetailName = rowData.StatusDetailName;
                }
            });
        }
    };

    sendRequestDeleteLot = (master) => {
        const { QuotationID = "", ContractNo = "", VoucherNum = "" } = master;
        const params = {
            QuotationID,
            QuotationItemID: "",
            ContractNo,
            VoucherNum,
            LotNo: "",
            UserName: Config.profile.UserNameU || "",
        }
        this.props.w05f0004Actions.sendRequestDelete(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                Config.notify.show("success", Config.lang("ERP_Gui_yeu_cau_thanh_cong"), 2000);
            }
        })
    }

    onDelete = (master) => {
        if (master && master.Status && master.Status === "3") {
            Config.popup.show("INFO", Config.lang("ERP_Trang_thai_hoan_tat_ban_khong_duoc_phep_xoa"))
        } else if (master && master.IsMapping === 1) {
            Config.popup.show("YES_NO", Config.lang("ERP_Dot_trien_khai_co_lot_da_mapping_ban_khong_the_xoa_ban_co_muon_gui_yeu_cau_xoa_mapping_khong"), () => {
                this.sendRequestDeleteLot(master);
            });
        } else {
            Config.popup.show(
                "YES_NO",
                Config.lang("ERP_Ban_co_chac_muon_xoa?"),
                () => {
                    const params = {
                        QuotationID: master.QuotationID || "",
                        ContractID: master.ContractID || "",
                    };
                    // console.log(params);
                    // this.saveHistoryChangeStatus(master.ContractID, master.VoucherNum);
                    this.props.w05f0004Actions.deleteItemDeloyment(params, async (errors) => {
                        if (errors) {
                            let message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
                            Config.popup.show("INFO", message);
                            return false;
                        } else {
                            const historyStatus = await this.saveHistoryChangeStatus(master.ContractID, master.VoucherNum);
                            if (historyStatus !== 200) {
                                Config.popup.show("INFO", Config.lang("ERP_Co_loi_khi_luu"));
                                return false;
                            }
                            Config.notify.show("success", Config.lang("ERP_Xoa_thanh_cong"), 2000);
                            this.props.loadListDeployments();
                        }
                    })
                }
            );
        }
    };

    redirectScreen = (mode, e = "") => {
        let screen = "";
        let params = {
            ContractID: e.ContractID,
            QuotationID: e.QuotationID,
        };
        switch (mode) {
            case "view":
                params.mode = "view";
                screen = "W05F0005";
                break;
            case "copy":
                params.mode = "copy";
                screen = "W05F0005";
                break;
            case "edit":
                params.mode = "edit";
                screen = "W05F0005";
                break;
            default:
                break;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: params
        });
    };

    saveHistoryChangeStatus = async (ContractID, oldValue) => {
        let el = {};
        let data = [];
        let status = "";
        el.codeID = ContractID;
        el.formID = "W05F0004";
        el.linkedTrans = "";
        el.oldValue = oldValue;
        el.hasURL = false;
        el.description84 = "Đợt triển khai";
        el.description01 = "Deployment";
        el.action = 3;
        data.push(el);
        const params = {
            attributes: JSON.stringify(data)
        };
        // console.log(params);
        await this.props.w05f0004Actions.addHistory(params, (error) => {
            status = error ? 400 : 200
        });

        return status;
    };

    numberFormat = (number, limitNumber = 0) => {
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: limitNumber,
            maximumFractionDigits: limitNumber,
        });
        const _number = formatter.format(String(number));
        return !_number || _number === "NaN" ? 0 : _number;
    };

    cellRenderNumber = (e, decimal) => {
        if (Config.isEmpty(e.displayValue) && e.displayValue !== 0) return null;
        return this.numberFormat(e.displayValue, 0);
    }

    render() {
        const { classes } = this.props;
        const { dataSource, loaded, master, loadingCboStatus, loadedReadMore } = this.props;
        const { currentFumi, openLotModal, modalOpen, openVoucherModal, openPaymentModal } = this.state;

        return (
            <>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ my: 'center', of: "#content" }}
                    visible={!loadedReadMore ? loaded : !loadedReadMore}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                {modalOpen && <W05F0004FumiModal
                    master={master}
                    modalOpen={modalOpen}
                    onCloseModal={() => this.onCloseModal("modalOpen")}
                    rowDetail={currentFumi}
                />}

                {openLotModal && <W05F0004LotModal
                    master={master}
                    loadingCboStatus={loadingCboStatus}
                    openLotModal={openLotModal}
                    onCloseLotModal={(rowData) => this.onCloseModal("openLotModal", rowData)}
                    rowDetail={currentFumi}
                />}
                {openVoucherModal && <W05F0004VoucherModal
                    master={master}
                    openVoucherModal={openVoucherModal}
                    onCloseVoucherModal={(rowData) => this.onCloseModal("openVoucherModal", rowData)}
                    rowDetail={currentFumi}
                />}
                {openPaymentModal && <W05F0004PaymentModal
                    master={master}
                    openPaymentModal={openPaymentModal}
                    onClosePaymentModal={(rowData) => this.onCloseModal("openPaymentModal", rowData)}
                    rowDetail={currentFumi}
                />}
                <Row className={"display_row align-center flex-wrap"} style={{ minHeight: 50 }} onDoubleClick={() => this.redirectScreen("view", master)}>
                    <Col xs={12} sm={3} md={3} lg={3}>
                        <div>
                            <Label className={classes.divHeaderTitle}>
                                {`${Config.lang("ERP_Hop_dong")}:`}
                            </Label>
                            <Label className={classes.divHeaderItem}>
                                {master.ContractNo || ""}
                            </Label>
                        </div>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3}>
                        <div>
                            <Label className={classes.divHeaderTitle}>
                                {`${Config.lang("ERP_Dot_trien_khai")}:`}
                            </Label>
                            <Label className={classes.divHeaderItem}>
                                {master.VoucherNum || ""}
                            </Label>
                        </div>

                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3}>
                        <div>
                            <Label className={classes.divHeaderTitle}>
                                {`${Config.lang("ERP_Trang_thai")}:`}
                            </Label>
                            <Label className={classes.divHeaderItem}>
                                {master.StatusName || ""}
                            </Label>
                        </div>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3}>{this.renderButtonAction()}</Col>
                </Row>
                <GridContainer
                    reference={(ref) => (this.dataGrid = ref)}
                    dataSource={dataSource}
                    loading={!loadedReadMore ? false : !loadedReadMore}
                    showBorders={false}
                    keyExpr={"QuotationItemID"}
                    typePaging={"normal"}
                    pagerFullScreen={false}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    showRowLines={false}
                    allowColumnResizing
                    columnResizingMode='widget'
                >
                    <Column
                        dataField={"LotNo"}
                        sortOrder={"asc"}
                        visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_lot")}
                        dataField={"LotNo"}
                        alignment={"left"}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_hang")}
                        dataField={"InventoryID"}
                        alignment={"left"}
                        width={180}
                    />
                    <Column
                        caption={Config.lang("ERP_Ten_hang")}
                        dataField={"ItemNameU"}
                        width={180}
                        alignment={"left"}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong")}
                        dataField={"Quantity"}
                        width={100}
                        alignment={"right"}
                        cellRender={(e) => this.cellRenderNumber(e)}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_da_giao")}
                        dataField={"DeliveryQuantity"}
                        width={160}
                        alignment={"right"}
                        cellRender={(e) => this.cellRenderNumber(e)}
                    />
                    <Column
                        caption={Config.lang("ERP_Quy_cach_dong_goi")}
                        dataField={"PackingMethodName"}
                        width={200}
                        alignment={"left"}
                    />
                    <Column
                        caption={Config.lang("ERP_Loai_bao_dong_goi")}
                        dataField={"PackingTypeName"}
                        width={200}
                        alignment={"left"}
                    />
                    <Column
                        caption={Config.lang("ERP_Nhan_mac")}
                        dataField={"MarkingName"}
                        width={180}
                    />
                    <Column
                        caption={Config.lang("ERP_Thoi_gian_giao_hang_du_kien")}
                        dataField={"DeliveryDateTo"}
                        width={245}
                        alignment={"center"}
                        dataType={"date"}
                        cellRender={(e) => {
                            const { data } = e.row;
                            let from = moment(data.DeliveryDateFrom).isValid() ? moment(data.DeliveryDateFrom).format('DD/MM/YYYY') : null;
                            let to = moment(data.DeliveryDateTo).isValid() ? moment(data.DeliveryDateTo).format('DD/MM/YYYY') : null;
                            return (
                                <div className={"center-row"} >
                                    <div className={"col-sm-5 pd0"}>{from}</div>
                                    {(from !== null || to !== null) &&
                                        <div className={"col-sm-2"}>-</div>
                                    }
                                    <div className={"col-sm-5 pd0"}>{to}</div>
                                </div>
                            )

                        }}
                    />
                    <Column
                        caption={Config.lang("ERP_Trang_thai")}
                        dataField={"StatusDetailName"}
                        width={150}
                        alignment={"left"}
                    />
                    <Column
                        allowEditing={false}
                        alignment={"right"}
                        fixed={true}
                        fixedPosition={"right"}
                        visible={!Config.isMobile}
                        cellRender={(e) => this.renderFumigation(e, master)}
                    />
                </GridContainer>

                {dataSource.length > 0 && this.actionGridList()}
                {master && this.showListAction()}
            </>
        );
    }
}

W05F0004DataGrid.propsTypes = {
    w05f0004Actions: PropTypes.any,
};

export default compose(
    connect(
        (state) => ({
            getFumigationEdit: state.W05F0004.getFumigationEdit,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w05f0004Actions: bindActionCreators(w05f0004Actions, dispatch),
        })
    ),
    withStyles(styles)
)(W05F0004DataGrid);
