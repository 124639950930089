/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/07/2020
 * @Example
 */
import { FormLabel as Label, withStyles } from "@material-ui/core";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from 'moment';
import PropTypes from "prop-types";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { compose } from "redux";
import Config from "../../../../config/index";
import GridContainer from "../../../grid-container/grid-container";

const styles = {
  distance: {
    marginTop: 15,
    marginBottom: 15,
    width: '100%'
  },
  divHeaderTitle: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: 12,
    textTransform: 'uppercase',
    color: '#8F9BB3',
    paddingRight: 15,
  },
  divHeaderItem: {
    fontWeight: 600,
    fontStyle: 'normal',
    color: '#222B45',
    fontSize: 16
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }
};
class W05F0003Inventory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disabledQDField: false
    };
  }

  componentDidMount = () => {
    const { getBaseCurrency, formDataMaster } = this.props;
    if (getBaseCurrency.BaseCurrencyID === formDataMaster.CurrencyID) this.setState({ disabledQDField: true });
  }

  renderInventoryColumn = (activeInventoryColumn) => {
    const supColumn = activeInventoryColumn.map((item, i) => {
      const { Caption } = item;
      let { FieldName } = item;
      let fieldType = "";
      let formatDate = "";
      let alignment = "left";
      const width = Caption.length > 20 ? 200 : 150;
      const limitFieldName = FieldName.slice(0, parseInt("-" + (FieldName.length - 3).toString()));
      switch (limitFieldName) {
        case "Num":
          fieldType = "number";
          alignment = "right";
          break;
        case "Dat":
          fieldType = "date";
          alignment = "center";
          formatDate = "dd/MM/yyyy";
          break;
        case "Str":
          fieldType = "string";
          FieldName = FieldName.indexOf("U") > -1 && FieldName.indexOf("U") === FieldName.length - 1 ? FieldName : FieldName.concat("U");
          break;
        default:
          break;
      }
      return (
        <Column
          key={i}
          width={width}
          caption={Caption}
          format={formatDate}
          dataType={fieldType}
          dataField={FieldName}
          alignment={alignment}
          allowEditing={false}
        // cellRender={(e) => this.cellRender(e)}
        />
      )
    });
    return supColumn;
  };

  cellRender = (e) => {
    const { data } = e.row;
    const { column } = e;
    const { contractTypesData, classes } = this.props;
    if (!data || !column || !column.dataField) return;
    const limitFieldName = (column.dataField).slice(0, parseInt("-" + ((column.dataField).length - 3).toString()));
    let tranFormField = data[column.dataField];
    let tranFormDefaultField = contractTypesData["Default" + column.dataField];
    if (limitFieldName === "Dat") {
      tranFormField = _.isEmpty(tranFormField) ? moment().format("DD/MM/YYYY") : moment(tranFormField).format("DD/MM/YYYY");
      tranFormDefaultField = _.isEmpty(tranFormField) ? moment().format("DD/MM/YYYY") : moment(tranFormDefaultField).format("DD/MM/YYYY");
    }
    const finalData = data && tranFormField ? tranFormField : tranFormDefaultField;
    return <div className={classes.ellipsis} title={finalData}>{finalData}</div>;
  };

  renderNumberFormat = (e) => {
    if (!e) return false;
    const { data } = e.row;
    const { column } = e;
    const { getDecimalQuantity } = this.props;
    const dataField = column.dataField;
    let decimalValue = data[dataField];
    if (_.isUndefined(decimalValue)) return;
    let limitDecimal = 0;
    if (getDecimalQuantity) {
      const { UnitPriceDecimals, QuantityDecimals, OriginalDecimal, ConvertedDecimal } = getDecimalQuantity;
      switch (dataField) {
        case "UnitPrice":
          limitDecimal = UnitPriceDecimals;
          break;
        case "Quantity":
          limitDecimal = QuantityDecimals;
          break;
        case "OAmount":
          limitDecimal = OriginalDecimal;
          break;
        case "CAmount":
          limitDecimal = ConvertedDecimal;
          break;
        default:
          break;
      }
    }
    return <NumberFormat decimalScale={limitDecimal} value={decimalValue} displayType={"text"} thousandSeparator={true} />
  };

  render() {
    const { classes, loading, formDataInventories, formDataMaster, getDecimalQuantity, activeInventoryColumn } = this.props;
    const { OriginalDecimal, ConvertedDecimal } = getDecimalQuantity;
    const { disabledQDField } = this.state;

    return (
      <React.Fragment>
        <div style={{ width: "100%" }}>
          <Row className={classes.distance}>
            <Col xs={12} sm={6} md={6} lg={6}>
              <div>
                <Label className={classes.divHeaderTitle}>{Config.lang("ERP_Tong_gia_tri_hop_dong")}:</Label>
                <span className={classes.divHeaderItem}><NumberFormat decimalScale={OriginalDecimal} value={formDataMaster.OTotal} displayType={'text'} thousandSeparator={true} /></span>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              {!disabledQDField &&
                <div>
                  <Label className={classes.divHeaderTitle}>{Config.lang("ERP_Tong_gia_tri_hop_dong_QD")}:</Label>
                  <span className={classes.divHeaderItem}><NumberFormat decimalScale={ConvertedDecimal} value={formDataMaster.Ctotal} displayType={'text'} thousandSeparator={true} /></span>
                </div>}
            </Col>
          </Row>
          <FormGroup>
            <GridContainer
              columnResizingMode={"widget"}
              totalItems={formDataInventories.length}
              dataSource={formDataInventories}
              typeShort={window.innerWidth < 768}
              loading={loading}
              typePaging={"normal"}
              elementAttr={{ class: `noBGSelect`, style: 'min-height: 300px' }}
              showBorders={false}
              showRowLines={false}
              columnAutoWidth={true}
              pagerFullScreen={false}
              showColumnLines={false}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
            >
              <Column
                fixed={!Config.isMobile}
                caption={Config.lang("ERP_Ma_hang")}
                dataField={"InventoryID"}
                allowEditing={false}
                width={220}
              />
              <Column
                fixed={!Config.isMobile}
                caption={Config.lang("ERP_Ten_hang")}
                dataField={"InventoryNameU"}
                allowEditing={false}
                width={220}
              />
              <Column
                caption={Config.lang("ERP_Don_vi_tinh")}
                width={120}
                dataField={"UnitName"}
                allowEditing={false}
              />
              <Column
                caption={Config.lang("ERP_So_luong")}
                dataField={"Quantity"}
                allowEditing={false}
                width={150}
                alignment={"right"}
                cellRender={this.renderNumberFormat}
              />
              <Column
                caption={Config.lang("ERP_Gia")}
                dataField={"UnitPrice"}
                allowEditing={false}
                width={150}
                alignment={"right"}
                cellRender={this.renderNumberFormat}
              />
              <Column
                caption={Config.lang("ERP_Thanh_tien")}
                dataField={"OAmount"}
                allowEditing={false}
                width={150}
                alignment={"right"}
                cellRender={this.renderNumberFormat}
              />
              {!disabledQDField &&
                <Column
                  caption={Config.lang("ERP_Thanh_tien_QD")}
                  width={150}
                  dataField={"CAmount"}
                  allowEditing={false}
                  alignment={"right"}
                  cellRender={this.renderNumberFormat}
                />
              }
              <Column
                caption={Config.lang("ERP_Quy_cach_dong_goi")}
                dataField={"PackingMethodName"}
                allowEditing={false}
                width={180}
              />
              <Column
                caption={Config.lang("ERP_Loai_bao_dong_goi")}
                dataField={"PackingTypeName"}
                allowEditing={false}
                width={250}
              />
              <Column
                caption={Config.lang("ERP_Nhan_mac")}
                dataField={"MarkingName"}
                allowEditing={false}
                width={250}
              />
              <Column
                caption={Config.lang("ERP_Thoi_gian_giao_bao")}
                allowEditing={false}
                dataField={"ParkingDeliveryDate"}
                dataType={"date"}
                format={"dd/MM/yyyy"}
                alignment={"center"}
                width={150}
                visible={false}
              />
              <Column
                caption={Config.lang("ERP_Dieu_khoan_thuong_mai")}
                dataField={"PaymentTermName"}
                allowEditing={false}
                width={200}
              />
              {!_.isEmpty(activeInventoryColumn) && this.renderInventoryColumn(activeInventoryColumn)}
              <Column
                caption={Config.lang("ERP_Tieu_chuan_chat_luong")}
                dataField={"Specification"}
                allowEditing={false}
                width={220}
              />
            </GridContainer>
          </FormGroup>
        </div>
      </React.Fragment>
    );
  }
}

W05F0003Inventory.propTypes = {
  loading: PropTypes.bool,
  getDecimalQuantity: PropTypes.object,
  formDataMaster: PropTypes.object,
  contractTypesData: PropTypes.object,
  cboContractTypes: PropTypes.array,
  formDataInventories: PropTypes.array,
  activeInventoryColumn: PropTypes.array,
};

export default compose(
  connect(state => ({
    getBaseCurrency: state.W05F0003.getBaseCurrency,
  })),
  withStyles(styles, { withTheme: true }))(W05F0003Inventory);