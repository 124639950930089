import { FormLabel as Label, Tab, Tabs, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import ReorderIcon from '@material-ui/icons/Reorder';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as w05f0004Actions from "../../../../redux/W0X/W05F0004/W05F0004_actions";
import { Combo } from "../../../common/form-material";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import TabContent, { TabPanel } from "../../../common/tabs/tab-content";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import CbLoadmore from './cbLoadMore';

const styles = {
  divIcon: {
    right: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    width: 120,
    height: 36,
    padding: 0,
    zIndex: 999,
    position: 'absolute',
    background: '#FFFFFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
    listStyle: 'none',
    marginTop: '-17px',
    borderRadius: 4,
  },
  divIconItem: {
    padding: 0,
    width: 30,
    height: 30,
    marginRight: 4,
  },
  divAddIcon: {
    padding: 0,
    width: 30,
    height: 30,
    margin: '0 0 0 32px',
  },
  columnActions: {
    height: '0px !important',
  },
  divHeaderTitle: {
    marginTop: 17,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '0.85rem',
    textTransform: 'uppercase',
    color: '#8F9BB3',
  },
  divHeaderItem: {
    marginLeft: 8,
    fontWeight: "600",
    fontStyle: 'normal',
    fontSize: '16px',
  },
  dateBoxGrid: {
    padding: '0 !important',
    maxHeight: '36px !important',
    "&:after": {
      content: "none",
    },
    "& input": {
      height: '37px !important',
      minHeight: '37px !important',
      width: '100%'
      // margin: '-7px !important',
    },
    "& .dx-popover ": {
      display: 'none !important',
    },
    "& .dx-invalid-message ": {
      display: 'none !important'
    },
    '& .MuiFormControl-root ': {
      margin: 0
    },
  },
  dataGrid: {
    "& .dx-datagrid-invalid": {
      backgroundColor: 'rgba(139, 195, 74, 0.32) !important'
    }
  },
  btnAddFumi: {
    marginTop: 12
  }
}

class W05F0004FumiModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPermission: 0,
      indexTab: 0,
      // loadCboFumiMethod: false,
      disabledAddFumi: false,
      loadFumi: false,
      dataDetail: [],
      dataMethod: [],
      loadFumigationCompany: false,
      oldDataMethod: [],
      oldDataDetail: [],
      dataCboFumigationCompany: [],
      cboFumigationMethod: [],
      onAddNew: false,
      oldcbFumi: [],
      disabledSave: false,
    };
    this.filter = {
      limit: 5,
      skip: 0,
    };
    this.filterCboFumigationCompany = {
      ObjectTypeID: "CC",
      strSearch: "",
      skip: 0,
      limit: 20,
    };
    this.GridDetail = null;
    this.GridMethod = null;
    this.key = -1;
    this.selectedRowIndx = null;
  }

  componentDidMount() {
    this.loadCboFumigationMethod();
    // this.loadCboFumigationCompany();
    this.loadFumigation(this.props.rowDetail, this.props.master);
  }

  loadFumigation = (detail, master) => {
    const { QuotationItemID = "" } = detail;
    const { ContractID = "", QuotationID = "" } = master;
    const params = {
      Language: Config.language || "84",
      ContractID,
      QuotationID,
      QuotationItemID,
    };
    this.setState({ loadFumi: true });
    this.props.w05f0004Actions.getFumigationEdit(params, (error, data) => {
      this.setState({ loadFumi: false });
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show("INFO", message);
        return false;
      }
      if (data) {
        // const { disabledAddFumi } = this.state;
        // if (data.method.length >= 3 && !disabledAddFumi) this.setState({ disabledAddFumi: true })
        this.setState(
          {
            dataMethod: data?.method.map((mt) => ({ ...mt })),
            dataDetail: data?.detail.map((dt) => {
              this.key++;
              dt.key = this.key;
              return dt;
            }),
          },
          () => {
            this.setState({
              disabledAddFumi: this.state.dataMethod.length >= this.props.getCboFumigationMethod.length ? true : false,
              oldDataDetail: this.state.dataDetail.map((rs) => ({ ...rs })),
              oldDataMethod: this.state.dataMethod.map((rs) => ({ ...rs })),
            });
          }
        );
      }
    });
  };

  loadCboFumigationMethod = () => {
    const params = {
      Language: Config.language || "84",
    };
    this.props.w05f0004Actions.getCboFumigationMethod(params, (error, data) => {
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show("INFO", message);
        return false;
      }
      if (data) {
        this.setState({
          cboFumigationMethod: data.map((item) => ({ ...item })),
          oldcbFumi: data.map((item) => ({ ...item }))
        });
      }
    });
  };

  handleFilterChange(key, data) {
    if (!key) return false;
    switch (key) {
      case "FumigationCompanyName":
        this.filterCboFumigationCompany.strSearch = data.value;
        break;
      default:
        break;
    }
  }

  // loadCboFumigationCompany = () => {
  //   const { skip, limit, o08ID, strSearch } = this.filterCboFumigationCompany;
  //   const params = {
  //     O08ID: o08ID ? o08ID : "30",
  //     StrSearch: strSearch ? strSearch : "",
  //     skip: skip,
  //     limit: limit,
  //   };
  //   this.setState({ loadFumigationCompany: true });
  //   this.props.w05f0004Actions.getComboFumigationCompany(
  //     params,
  //     (error, data) => {
  //       if (error) {
  //         let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
  //         Config.popup.show("INFO", message);
  //         this.setState({ loadFumigationCompany: false });
  //         return false;
  //       }
  //       if (data) {
  //         const rows =
  //           data && data.rows
  //             ? data.rows.map((item) => {
  //               let filterData = {
  //                 FumigationCompany: item.ObjectID,
  //                 FumigationCompanyName: item.ObjectNameU,
  //               };
  //               return filterData;
  //             })
  //             : [];
  //         this.setState(
  //           {
  //             dataCboFumigationCompany: rows,
  //           },
  //           () => {
  //             this.setState({ loadFumigationCompany: false });
  //           }
  //         );
  //       }
  //     }
  //   );
  // };

  renderDetail = () => {
    const { dataDetail } = this.state;
    const { loadFumi, classes } = this.props;
    return (
      <div style={{ paddingBottom: 15 }}>
        <GridContainer
          dataSource={dataDetail}
          loading={loadFumi}
          showBorders={false}
          typePaging={"remote"}
          pagerFullScreen={false}
          showColumnLines={false}
          hoverStateEnabled={true}
          allowCellSelection={true}
          onRowClick={(e) => {
            this.selectedRowIndx = e.rowIndex;
          }}
          elementAttr={{ class: `noBGSelect` }}
          showRowLines={false}
          reference={(ref) => (this.GridDetail = ref)}
          editing={{
            mode: "cell",
            refreshMode: "reshape",
            allowUpdating: true,
            texts: {
              confirmDeleteMessage: "",
            },
            startEditAction: "click",
          }}
          onRowUpdated={() => {
            if (!this.state.disabledSave) this.setState({ disabledSave: true });
          }}
          onRowRemoved={() => {
            if (!this.state.disabledSave) this.setState({ disabledSave: true });
          }}
        >
          <Column
            caption={Config.lang("ERP_Phuong_thuc")}
            dataField={"FumigationTypeName"}
            alignment={"left"}
            allowEditing={false}
            width={245}
          />
          <Column
            caption={Config.lang("ERP_Lan")}
            dataField={"OrderNo"}
            alignment={"center"}
            allowEditing={false}
            width={145}
          />
          <Column dataField={"FumigationCompanyName"} visible={false} />
          <Column
            caption={Config.lang("ERP_Cong_ty_phun")}
            dataField={"FumigationCompany"}
            alignment={"left"}
            width={245}
            allowEditing={false}
            cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
          // lookup={{
          //   dataSource: dataCboFumigationCompany || [],
          //   valueExpr: "FumigationCompany",
          //   displayExpr: "FumigationCompanyName",
          // }}
          // cellRender={(e) => (
          //   <Label>
          //     {e.data.FumigationCompanyName
          //       ? e.data.FumigationCompanyName
          //       : e.displayValue}
          //   </Label>
          // )}
          />
          <Column
            caption={Config.lang("ERP_Ngay_phun")}
            dataField={"FumigationDate"}
            width={145}
            alignment={"center"}
            cssClass={classes.dateBoxGrid}
            dataType={"date"}
            format={"dd/MM/yyyy"}
          />
          <Column
            caption={Config.lang("Ghi_chu")}
            dataField={"Notes"}
            width={250}
          />
        </GridContainer>
      </div >
    );
  };

  renderMethod = () => {
    const {
      loadFumi,
      dataMethod,
      disabledAddFumi,
      cboFumigationMethod,
      oldDataMethod,
    } = this.state;
    const { classes, rowDetail, master } = this.props;
    const dataSourceMethod = this.GridMethod
      ? this.GridMethod.instance.option("dataSource")
      : dataMethod;
    let fumiType =
      dataSourceMethod.length > 0 &&
      dataSourceMethod.map((item) => item.FumigationType);
    let arr = [];
    for (let i = 0; i < fumiType.length; i++) {
      arr.push(
        ...cboFumigationMethod.filter(
          (item) => item.FumigationType === fumiType[i]
        )
      );
    }
    // const dataKeyMoi = dataMethod.map(d => d.FumigationType);
    // let cbDataFumi = cboFumigationMethod.filter(d => {
    //   return dataKeyMoi.indexOf(d.FumigationType) < 0;
    // });
    // cbDataFumi = cbDataFumi.filter((v, i, a) => a.findIndex(t => (t.FumigationType === v.FumigationType)) === i);
    return (
      <div className={classes.dataGrid}>
        <GridContainer
          dataSource={dataMethod}
          reference={(ref) => (this.GridMethod = ref)}
          loading={loadFumi}
          showBorders={false}
          typePaging={"remote"}
          pagerFullScreen={false}
          showColumnLines={false}
          hoverStateEnabled={true}
          showRowLines={false}
          allowCellSelection={true}
          editing={{
            mode: "cell",
            refreshMode: "repaint",
            allowUpdating: true,
            texts: {
              confirmDeleteMessage: "",
            },
            startEditAction: "click",
          }}
          elementAttr={{
            class: `noBGSelect`,
            style: "min-height: 148px"
          }}
          onInitNewRow={(eMethod) => {
            eMethod.data.ContractID = master ? master.ContractID : "";
            eMethod.data.LotNo = rowDetail ? rowDetail.LotNo : "";
            eMethod.data.FumigationType = "";
            eMethod.data.FumigationTypeName = "";
            eMethod.data.Quantity = 1;
            eMethod.data.IsNew = true;
            eMethod.data.QuotationID = master ? master.QuotationID : "";
            eMethod.data.QuotationItemID = rowDetail
              ? rowDetail.QuotationItemID
              : "";
          }}
          onRowInserted={(e) => {
            const { data } = e;
            const dataSource = this.GridMethod.instance.option("dataSource");
            if (dataSource.length >= this.props.getCboFumigationMethod.length) {
              this.setState({ disabledAddFumi: true });
            } else {
              this.setState({ disabledAddFumi: false });
            }
            const FumigationTypeName = data.FumigationTypeName = cboFumigationMethod.find(
              (e) => e.FumigationType === data.FumigationType
            );
            e.data.FumigationTypeName = FumigationTypeName && FumigationTypeName.FumigationTypeName;

            // const dataFilter = cboFumigationMethod.filter(rs => rs.FumigationType !== e.data.FumigationType)
            // this.setState({ cboFumigationMethod: dataFilter });
            if (!this.state.disabledSave) this.setState({ disabledSave: true });
            this.createDetail();
          }}
          onRowRemoved={() => {
            if (!this.state.disabledSave) this.setState({ disabledSave: true });
          }}
          onRowUpdated={(e) => {
            // const { data } = e;
            const dataSource = this.GridMethod.instance.option("dataSource");
            if (dataSource.length >= this.props.getCboFumigationMethod.length) {
              this.setState({ disabledAddFumi: true });
            } else {
              this.setState({ disabledAddFumi: false });
            }
            // data.FumigationTypeName = cboFumigationMethod.find(
            //   (e) => e.FumigationType === data.FumigationType
            // ).FumigationTypeName;
            //xoa tab chi tiet
            oldDataMethod.map((rs) => {
              const dataNew = dataSourceMethod.find(
                (d) => d.FumigationType === rs.FumigationType
              );
              if (dataNew && dataNew.Quantity < rs.Quantity) {
                Config.popup.show(
                  "YES_NO",
                  Config.lang("ERP_Ban_co_muon_tiep_tuc_sua"),
                  () => {
                    this.createDetail();
                  },
                  () => {
                    this.loadFumigation(
                      this.props.rowDetail,
                      this.props.master
                    );
                  }
                );
              }
              return dataNew;
            });
            this.createDetail();
            if (!this.state.disabledSave) this.setState({ disabledSave: true });
          }}
          onRowRemoving={(e) => {
            if (this.state.disabledAddFumi) this.setState({ disabledAddFumi: false })
          }}
          onEditingStart={e => {
            if (e.data.FumigationType !== '' && e.column.dataField === "FumigationType") {
              e.cancel = true;
            }
          }}
        >
          <Column
            caption={Config.lang("ERP_Hanh_dong")}
            alignment={"center"}
            visible={Config.isMobile}
            cellRender={(e) => this.renderRemoveFumigation(e, "mobile")}
          />
          <Column
            caption={Config.lang("ERP_Ma_lot")}
            dataField={"LotNo"}
            alignment={"left"}
            allowEditing={false}
            width={300}
          />
          <Column dataField={"FumigationTypeName"} visible={false} />
          <Column
            caption={Config.lang("ERP_Phuong_thuc")}
            dataField={"FumigationType"}
            alignment={"left"}
            cssClass={classes.dateBoxGrid}
            headerCellRender={e => {
              return (
                <label>{Config.lang("ERP_Phuong_thuc")} <span style={{ color: 'red', fontSize: 15 }}>*</span></label>
              )
            }}
            width={250}
            // lookup={{
            //   dataSource:
            //     cboFumigationMethod.filter((item) => !arr.includes(item)) || [],
            //   valueExpr: "FumigationType",
            //   displayExpr: "FumigationTypeName",
            // }}
            // allowEditing={this.state.onAddNew}
            cellRender={(e) => {
              return (
                <Label>
                  {e.data.FumigationTypeName
                    ? e.data.FumigationTypeName
                    : e.displayValue}
                </Label>
              );
            }}
            // editCellRender={(e) => {
            //   // const selectedItem = cboFumigationMethod.filter((c) => {
            //   //   return c.FumigationType === e.value;
            //   // });
            //   return (
            //     <Combo
            //       dataSource={cboFumigationMethod.filter((item) => !arr.includes(item)) || []}
            //       displayExpr={'FumigationTypeName'}
            //       // valueExpr={'FumigationType'}
            //       stylingMode={'underlined'}
            //       // cssClass={classes.dateBoxGrid}
            //       style={{ backgroundColor: "transparent", marginBottom: -5 }}
            //       placeholder={Config.lang("ERP_Chon")}
            //       // value={selectedItem.length > 0 ? selectedItem[0] : null}
            //       onValueChanged={(rs) => {
            //         e.setValue(rs.value && rs.value.FumigationType ? rs.value.FumigationType : "");
            //         e.component.cellValue(e.rowIndex, "FumigationTypeName", rs.value && rs.value.FumigationTypeName ? rs.value.FumigationTypeName : "");
            //       }}
            //     />
            //   );
            // }}
            editCellRender={(e) => {
              const selectedItem = cboFumigationMethod.filter((c) => {
                return c.FumigationType === e.value;
              });
              return (
                <Combo
                  dataSource={cboFumigationMethod.filter((item) => !arr.includes(item)) || []}
                  displayExpr={"FumigationTypeName"}
                  stylingMode={"underlined"}
                  margin={"dense"}
                  cssClass={classes.dateBoxGrid}
                  className={"no-margin"}
                  style={{ backgroundColor: "transparent" }}
                  shrink={true}
                  placeholder={Config.lang("ERP_Chon")}
                  value={selectedItem.length > 0 ? selectedItem[0] : null}
                  onValueChanged={(rs) => {
                    e.setValue(rs.value && rs.value.FumigationType ? rs.value.FumigationType : "");
                    e.component.cellValue(e.rowIndex, "FumigationTypeName", rs.value && rs.value.FumigationTypeName ? rs.value.FumigationTypeName : "");
                  }}
                />
              );
            }}
          >
            {/* <RequiredRule /> */}
          </Column>
          <Column
            caption={Config.lang("So_lan_khu_trung")}
            dataField={"Quantity"}
            alignment={"right"}
            editorOptions={{ dataType: "number", min: 1, max: 10 }}
            dataType={"number"}
            cssClass={classes.dateBoxGrid}
            minWidth={150}
          />
          <Column
            fixed={true}
            alignment={"right"}
            allowEditing={false}
            fixedPosition={"right"}
            visible={!Config.isMobile}
            width={90}
            cellRender={(e) => this.renderRemoveFumigation(e, "desktop")}
          />
        </GridContainer>
        {!disabledAddFumi && <IconButton
          aria-label={"add"}
          size={"small"}
          className={classes.btnAddFumi}
          disabled={disabledAddFumi}
          onClick={() => this.onAddRowFumi()}
        >
          <Icons name={"plus_filled"} />
        </IconButton>}
      </div>
    );
  };

  renderIconAction = (e) => {
    const { classes } = this.props;
    return (
      <IconButton
        aria-label={"view"}
        className={classes.divIconItem}
        onClick={() => this.onDeleteRowFumi(e)}
      >
        <Icons name={"delete"} />
      </IconButton>
    );
  };

  renderRemoveFumigation = (e, mode) => {
    return (
      mode === "mobile" ?
        this.renderIconAction(e)
        : <GridActionBar>
          {this.renderIconAction(e)}
        </GridActionBar>
    );
  };

  onDeleteRowFumi = async (e) => {
    Config.popup.show(
      "YES_NO",
      Config.lang("ERP_Ban_co_chac_muon_xoa?"),
      () => {
        e.component.deleteRow(e.rowIndex);
        this.createDetail();
        this.GridDetail && this.GridDetail.instance.saveEditData();
      }
    );
  }

  onAddRowFumi = () => {
    if (this.GridMethod) {
      this.GridMethod.instance.saveEditData();
      const dataSourceMethod = this.GridMethod.instance.option("dataSource");
      const dataFilter = dataSourceMethod.filter(rs => rs.FumigationType === '');
      if (dataFilter.length > 0) {
        Config.popup.show("INFO", Config.lang("ERP_Phuong_thuc_khong_duoc_de_trong"));
        return false;
      } else {
        this.setState({ disabledAddFumi: true, onAddNew: true }, () => {
          setTimeout(() => { this.GridMethod.instance.addRow() }, 300);
        })
      };
    }
  }

  detailObject = (dataDetail) => {
    if (dataDetail) {
      let arr = [];
      dataDetail.map((item) => {
        let res = {
          ContractID: item.ContractID,
          FumigationCompany: item.FumigationCompany,
          FumigationCompanyName: item.FumigationCompanyName,
          FumigationDate: item.FumigationDate
            ? moment.utc(item.FumigationDate).format("MM/DD/YYYY")
            : null,
          FumigationType: item.FumigationType,
          FumigationTypeName: item.FumigationTypeName,
          LotNo: item.LotNo,
          OrderNo: item.OrderNo,
          QuotationID: item.QuotationID,
          QuotationItemID: item.QuotationItemID,
          Notes: item.Notes,
        };
        return arr.push(res);
      });
      // console.log(arr);
      return arr;
    }
    return [];
  };

  methodObject = (dataMethod) => {
    if (dataMethod) {
      let arr = [];
      dataMethod.map((item) => {
        let res = {
          ContractID: item.ContractID,
          FumigationType: item.FumigationType,
          FumigationTypeName: item.FumigationTypeName,
          LotNo: item.LotNo,
          Quantity: item.Quantity,
          QuotationID: item.QuotationID,
          QuotationItemID: item.QuotationItemID,
        };
        return arr.push(res);
      });
      // console.log(arr);
      return arr;
    }
    return [];
  };

  createDetail = async (cb) => {
    if (this.GridDetail && this.GridDetail) {
      await this.GridMethod.instance.option("dataSource");
      await this.GridDetail.instance.option("dataSource");
      const dataSourceMethod = this.GridMethod
        ? this.GridMethod.instance.option("dataSource")
        : this.state.dataMethod;
      const dataSourceDetail = this.GridDetail
        ? this.GridDetail.instance.option("dataSource")
        : this.state.dataDetail;
      let newDataSourceDetail = [];
      if (dataSourceMethod) {
        dataSourceMethod.map((item, index) => {
          const dataDetail_FumiType = dataSourceDetail.filter(
            (detail) => detail.FumigationType === item.FumigationType
          );
          let count = 1;

          if (item.Quantity !== dataDetail_FumiType.length) {
            for (let i = 0; i < item.Quantity; i++) {
              if (dataDetail_FumiType[i]) {
                dataDetail_FumiType[i].Quantity = count;
                newDataSourceDetail.push(dataDetail_FumiType[i]);
              } else {
                newDataSourceDetail.push({
                  ContractID: item.ContractID ? item.ContractID : "",
                  FumigationCompany: "",
                  FumigationCompanyName: "",
                  FumigationDate: item.FumigationDate || "",
                  FumigationType: item.FumigationType || "",
                  FumigationTypeName: item.FumigationTypeName || "",
                  LotNo: item.LotNo || "",
                  OrderNo: count,
                  QuotationID: item.QuotationID || "",
                  QuotationItemID: item.QuotationItemID || "",
                  IsNew: true,
                  Notes: ""
                });
              }
              count++;
            }
          } else {
            newDataSourceDetail = newDataSourceDetail.concat(
              dataDetail_FumiType
            );
          }
          return newDataSourceDetail;
        });
        newDataSourceDetail.map((item) => {
          if (item.IsNew) {
            item.key = this.key;
            this.key++;
          }
          return item;
        });
        this.setState({ dataDetail: newDataSourceDetail });
      }
    }
    if (cb) cb();
  };

  handleChanged = (event, value) => {
    if (this.GridDetail && this.GridDetail) {
      this.GridMethod.instance.saveEditData();
      this.GridDetail.instance.saveEditData();
    }
    if (value === 1) {
      const dataSourceMethod =
        this.GridMethod && this.GridMethod.instance.option("dataSource");
      const dataFilter = dataSourceMethod.filter(rs => rs.FumigationType === "");
      if (dataFilter.length > 0) {
        Config.popup.show("INFO", Config.lang('ERP_Phuong_thuc_khong_duoc_de_trong'));
        return null;
      }
      this.createDetail();
    }
    this.setState({ indexTab: value });
  };

  onSaveFumi = async (master) => {
    if (this.GridDetail && this.GridDetail) {
      await this.GridMethod.instance.saveEditData();
      await this.GridDetail.instance.saveEditData();
    }
    const { QuotationItemID = "" } = this.props.rowDetail;
    const { ContractID = "", QuotationID = "" } = master;
    await this.createDetail(async () => {
      const dataSourceMethod = this.GridMethod
        ? this.GridMethod.instance.option("dataSource")
        : this.state.dataMethod;
      const dataSourceDetail = this.GridDetail
        ? this.GridDetail.instance.option("dataSource")
        : this.state.dataDetail;
      // Kiem tra FumigationType !== ""
      const dataFilter = await dataSourceMethod.filter(rs => rs.FumigationType === '');
      if (dataFilter.length > 0) {
        Config.popup.show("INFO", Config.lang('ERP_Phuong_thuc_khong_duoc_de_trong'));
        return false;
      }

      const params = {
        ContractID,
        QuotationID,
        QuotationItemID,
        method: JSON.stringify(this.methodObject(dataSourceMethod)),
        detail: JSON.stringify(this.detailObject(dataSourceDetail)),
      };
      this.setState({ loadFumi: true })
      this.props.w05f0004Actions.saveFumigation(params, async (error, data) => {
        this.setState({ loadFumi: false })
        if (error) {
          let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
          Config.popup.show("INFO", message);
          return false;
        }
        if (data === "successful") {
          const historyStatus = await this.saveHistoryOnSaveFumi(
            dataSourceMethod,
            dataSourceDetail,
            master
          );
          if (historyStatus !== 200) {
            Config.popup.show("INFO", Config.lang("ERP_Co_loi_khi_luu"));
            return false;
          } else {
            Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
            this.setState({
              oldDataDetail: dataSourceDetail.map((rs) => ({ ...rs })),
              oldDataMethod: dataSourceMethod.map((rs) => ({ ...rs })),
            })
            if (this.state.disabledSave) this.setState({ disabledSave: false });
            // if (this.props.onCloseModal) this.props.onCloseModal("modalOpen");
          }
        }
      });
    });
  };

  getNumberByOrderNo = (orderNo) => {
    orderNo = _.toNumber(orderNo);
    let stt = "";
    switch (true) {
      case orderNo === 1:
        stt = "st"
        break;
      case orderNo === 2:
        stt = "nd"
        break;
      case orderNo === 3:
        stt = "rd"
        break;
      case orderNo > 4:
        stt = "th"
        break;
      default:
        break;
    }
    return { stt }
  }

  saveHistoryOnSaveFumi = async (newDataSourceMethod, newDataSourceDetail) => {
    let el = {};
    let data = [];
    let status = "";
    const { oldDataDetail } = this.state;
    const oldData = this.state.oldDataMethod;
    //get "Field Name"
    const keyMethod = Object.keys(
      oldData[0] ? oldData[0] : newDataSourceMethod[0] || []
    );
    const keyDetail = Object.keys(
      oldDataDetail[0] ? oldDataDetail[0] : newDataSourceDetail[0] || []
    );

    if (oldData.length > 0) {
      //DataSource Method
      //Xoa phuong thuc
      let newData = newDataSourceMethod.map((item) => item.FumigationType);
      oldData.forEach((dataCu) => {
        if (newData.indexOf(dataCu.FumigationType) < 0) {
          let el = {};
          // console.log("neu moi nho hon cu", dataCu);
          el.codeID = dataCu.QuotationID;
          el.formID = "W05F0004";
          el.linkedTrans = "";
          el.oldValue = `${dataCu.FumigationTypeName} - ${Config.lang("ERP_So_luong")}: ${dataCu.Quantity} `;
          el.hasURL = false;
          el.description84 = `Phương thức phun trùng của Lot ${dataCu.LotNo}`;
          el.description01 = `Fumigation Type of Lot ${dataCu.LotNo}`;
          el.action = 3;
          data.push(el);
        }
      });
      //Them moi phuong thuc
      newDataSourceMethod.forEach((item) => {
        if (item.IsNew) {
          let el = {};
          el.codeID = item.QuotationID;
          el.formID = "W05F0004";
          el.linkedTrans = "";
          el.newValue = `${item.FumigationTypeName} - ${Config.lang("ERP_So_luong")}: ${item.Quantity}`;
          el.hasURL = false;
          el.description84 = `Phương thức phun trùng của Lot ${item.LotNo}`;
          el.description01 = `Fumigation Type of Lot ${item.LotNo}`;
          el.action = 0;
          data.push(el);
        }
      });
      //Sua phuong thuc
      newDataSourceMethod.forEach((dataMoi) => {
        oldData.forEach((dataCu) => {
          keyMethod.map((fieldName) => {
            if (
              dataMoi[fieldName] !== dataCu[fieldName] &&
              dataMoi.FumigationType === dataCu.FumigationType &&
              dataMoi.IsNew === undefined
            ) {
              // console.log(
              //     fieldName,
              //     dataMoi[fieldName],
              //     "gia tri cu",
              //     dataCu[fieldName]
              // );
              let el = {};
              el.codeID = dataMoi.QuotationID;
              el.formID = "W05F0004";
              el.linkedTrans = "";
              el.oldValue = dataCu["Quantity"];
              el.newValue = dataMoi["Quantity"];
              el.hasURL = false;
              el.description84 = `Số lượng - ${dataMoi.FumigationTypeName} của Lot ${dataMoi.LotNo}`;
              el.description01 = `Quantity - ${dataMoi.FumigationTypeName} of Lot ${dataMoi.LotNo}`;
              el.action = 1;
              return data.push(el);
            }
            return fieldName;
          });
        });
      });
      //dataSource Detail
      const dataKeyCu = oldDataDetail.map((d) => d.key);
      const dataKeyMoi = newDataSourceDetail.map((d) => d.key);
      const added = newDataSourceDetail.filter((d) => {
        return dataKeyCu.indexOf(d.key) < 0;
      });

      const edited = newDataSourceDetail.filter((d) => {
        return dataKeyCu.indexOf(d.key) > -1;
      });

      const deleted = oldDataDetail.filter((d) => {
        return dataKeyMoi.indexOf(d.key) < 0;
      });
      //Them chi tiet
      if (added.length > 0) {
        added.forEach((item) => {
          if (item.IsNew) {
            let el = {};
            el.codeID = item.QuotationID;
            el.formID = "W05F0004";
            el.linkedTrans = "";
            el.newValue = `${item.FumigationTypeName} - ${Config.lang("ERP_Lan")} ${item.OrderNo}`;
            el.hasURL = false;
            el.description84 = `Chi tiết phun trùng của Lot ${item.LotNo}`;
            el.description01 = `Detail Fumigation of Lot ${item.LotNo}`;
            el.action = 0;
            data.push(el);
          }
        });
      }
      //Sua chi tiet
      if (edited.length > 0) {
        edited.forEach((dataMoi) => {
          oldDataDetail.forEach((dataCu) => {
            keyDetail.map((fieldName) => {
              if (
                dataMoi[fieldName] !== dataCu[fieldName] &&
                dataMoi.key === dataCu.key &&
                dataMoi.FumigationType === dataCu.FumigationType &&
                fieldName !== "FumigationCompany"
              ) {
                const { stt } = this.getNumberByOrderNo(dataMoi.OrderNo);
                const de = this.getLang(fieldName);
                let el = {};
                let newValue = "";
                let oldValue = "";
                switch (fieldName) {
                  case "FumigationDate":
                    newValue = dataMoi["FumigationDate"] !== null ? moment(dataMoi["FumigationDate"]).format("DD/MM/YYYY") : "";
                    oldValue = dataCu["FumigationDate"] !== null ? moment(dataCu["FumigationDate"]).format("DD/MM/YYYY") : "";
                    break;
                  default:
                    newValue = dataMoi[fieldName];
                    oldValue = dataCu[fieldName];
                    break;
                }
                el.codeID = dataMoi.QuotationID;
                el.formID = "W05F0004";
                el.linkedTrans = "";
                el.oldValue = oldValue;
                el.newValue = newValue;
                el.hasURL = false;
                el.description84 = `${Config.lang(de, "vi")} ${dataMoi.FumigationTypeName} - ${Config.lang("ERP_Lan", "vi")} ${dataMoi.OrderNo} của Lot ${dataMoi.LotNo}`;
                el.description01 = `${Config.lang(de, "en")} ${dataMoi.FumigationTypeName} - ${Config.lang("ERP_Lan", "en")} ${dataMoi.OrderNo} ${stt} of Lot ${dataMoi.LotNo}`;
                el.action = 1;
                return data.push(el);
              }
              return fieldName;
            });
          });
        });
      }
      //Xoa chi tiet
      if (deleted.length > 0) {
        deleted.forEach((dataMoi) => {
          // console.log(dataMoi)
          let el = {};
          el.codeID = dataMoi.QuotationID;
          el.formID = "W05F0004";
          el.linkedTrans = "";
          el.oldValue = `${dataMoi.FumigationTypeName} - ${Config.lang("ERP_Lan")} ${dataMoi.OrderNo}`;
          el.hasURL = false;
          el.description84 = `Chi tiết phun Trùng của Lot ${dataMoi.LotNo}`;
          el.description01 = `Detail Fumigation of Lot ${dataMoi.LotNo}`;
          el.action = 3;
          data.push(el);
        });
      }
    } else {
      //Them phuong thuc khi du lieu rong
      newDataSourceMethod.forEach((dataMoi) => {
        if (dataMoi.IsNew) {
          // console.log("data rong thi moi thuc hien", dataMoi);
          el.codeID = dataMoi.QuotationID;
          el.formID = "W05F0004";
          el.linkedTrans = "";
          el.newValue = `Lot ${dataMoi.LotNo} ${dataMoi.FumigationTypeName || ""}`;
          el.hasURL = false;
          el.description84 = "Phương thức phun trùng";
          el.description01 = "Fumigation Type";
          el.action = 0;
          data.push(el);
        }
      });
      newDataSourceDetail.forEach((dataMoi) => {
        if (dataMoi.IsNew) {
          // console.log("data rong thi moi thuc hien", dataMoi);
          let el = {};
          el.codeID = dataMoi.QuotationID;
          el.formID = "W05F0004";
          el.linkedTrans = "";
          el.newValue = `Lot ${dataMoi.LotNo} ${dataMoi.FumigationTypeName} ${dataMoi.FumigationCompanyName || ""} ${dataMoi.FumigationDate || ""}`;
          el.hasURL = false;
          el.description84 = "Chi tiết";
          el.description01 = "Fumigation Details";
          el.action = 0;
          data.push(el);
        }
      });
    }
    // console.log("========data========>", data);
    const params = {
      attributes: JSON.stringify(data),
    };
    await this.props.w05f0004Actions.addHistory(params, (error) => {
      status = error ? 400 : 200;
    });
    return status;
  };

  getLang(fieldName) {
    let listField = {
      FumigationCompany: "ERP_Cong_ty_phun",
      FumigationCompanyName: "ERP_Cong_ty_phun",
      FumigationDate: "ERP_Ngay_phun",
      Notes: "Ghi_chu",
    };
    return listField[fieldName];
  }
  cellRenderPopupAction = (cellData) => {
    const dataField = cellData.column.dataField,
      rowData = cellData.data;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={"pull-left mgr5"}>
          <IconButton size={'small'} style={{ display: `${this.props.isView ? 'none' : 'block'}` }} onClick={() => this.showPopup(rowData[dataField], dataField)}>
            <ReorderIcon fontSize={"small"} />
          </IconButton>
        </div>
        <div className={"pull-left"} title={rowData.FumigationCompanyName}>{rowData.FumigationCompanyName}</div>
      </div>
    );
  };
  showPopup = (data, dataField) => {
    const value = data ? data.replace(/,/g, ';').split(';') : [];
    this.popupCompany.tmpSelectedRowKeys = value;
    setTimeout(() => {
      this.popupCompany && this.popupCompany.open();
    }, 100);
  };
  onSelectedPopup = (data, field) => {
    this.filterCboFumigationCompany.limit = 20;
    this.filterCboFumigationCompany.skip = 0;
    this.GridDetail.instance.cellValue(this.selectedRowIndx, field, data[0].FumigationCompany);
    this.GridDetail.instance.cellValue(this.selectedRowIndx, "FumigationCompanyName", data[0].FumigationCompanyName);
    this.GridDetail.instance.saveEditData();
  };
  render() {
    const { master, modalOpen, onCloseModal, rowDetail, classes } = this.props;
    const { indexTab, disabledSave } = this.state;
    const columnCompany = [
      {
        caption: Config.lang("ERP_Ma"),
        dataField: "FumigationCompany",
        width: 200
      },
      {
        caption: Config.lang("ERP_Ten"),
        dataField: "FumigationCompanyName"
      }
    ];
    return (
      <>
        <CbLoadmore
          ref={ref => this.popupCompany = ref}
          mode={"single"}
          maxWidth={"sml"}
          keyExpr={"FumigationCompany"}
          title={Config.lang("ERP_Cong_ty_phun")}
          onClose={() => {
            this.filterCboFumigationCompany.skip = 0;
            this.filterCboFumigationCompany.limit = 20;
          }}
          filter={this.filterCboFumigationCompany}
          column={columnCompany}
          onSave={(data) => (this.onSelectedPopup(data, "FumigationCompany"))}
          listPerPage={[15, 30, 45, 60]}
        />
        <Modal
          open={modalOpen}
          maxWidth={"md"}
          fullWidth={true}
          position={"center"}
        >
          <Modal.Title disableTypography>
            <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%", margin: '4px 0' }}>
              <div className={"display_row align-center"}>
                <Typography variant="h6" className={"mgr10 text-uppercase"}>{Config.lang("ERP_Phun_trung")}</Typography>
              </div>
              <div>
                <IconButton
                  disabled={!disabledSave}
                  aria-label={"save"}
                  onClick={() => {
                    this.onSaveFumi(master);
                  }}
                  size={"small"}
                  className={"mgr10"}
                >
                  <Icons name={"save_filled"} />
                </IconButton>
                <IconButton
                  aria-label={"close"}
                  onClick={onCloseModal}
                  size={"small"}
                >
                  <Icons name={"cancel_filled"} />
                </IconButton>
              </div>
            </div>
          </Modal.Title>
          <Modal.Content>
            <Row>
              <Col xs={6} sm={6} md={6} lg={6}>
                <Tabs
                  indicatorColor={"primary"}
                  textColor={"primary"}
                  value={indexTab}
                  onChange={this.handleChanged}
                >
                  <Tab value={0} label={Config.lang("ERP_Phuong_thuc")} />
                  <Tab value={1} label={Config.lang("ERP_Chi_tiet")} />
                </Tabs>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'right' }}>
                {indexTab === 1 && (
                  <>
                    <Label className={classes.divHeaderTitle}>
                      {`${Config.lang("ERP_Ma_lot")}:`}
                    </Label>
                    <Label className={classes.divHeaderItem}>
                      {Config.lang(rowDetail && rowDetail.LotNo)}
                    </Label>
                  </>
                )}
              </Col>
            </Row>
            <TabContent
              activeKey={indexTab}
              disableSwipe={true}
              lazyLoading={false}
            >
              <TabPanel index={0}>{this.renderMethod()}</TabPanel>
              <TabPanel index={1}>{this.renderDetail()}</TabPanel>
            </TabContent>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

W05F0004FumiModal.propsTypes = {
  w05f0004Actions: PropTypes.any,
};

export default compose(
  connect(
    (state) => ({
      getFumigationEdit: state.W05F0004.getFumigationEdit,
      getCboFumigationMethod: state.W05F0004.getCboFumigationMethod,
    }),
    (dispatch) => ({
      generalActions: bindActionCreators(generalActions, dispatch),
      w05f0004Actions: bindActionCreators(w05f0004Actions, dispatch),
    })
  ),
  withStyles(styles)
)(W05F0004FumiModal);
