import { FormLabel as Label } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as w05f0003Actions from "../../../../redux/W0X/W05F0003/W05F0003_actions";
import ButtonGeneral from "../../../common/button/button-general";
import Icons from "../../../common/icons/";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import PopoverAction from "../../../grid-container/popover-action";
import W05F0003FumiModal from "./W05F0003FumiModal";
import W05F0003LotModal from "./W05F0003LotModal";
import W05F0003PaymentModal from "./W05F0003PaymentModal";
import W05F0003ReceiptModal from "./W05F0003ReceiptModal";

const styles = {
    divIcon: {
        right: 50,
        height: 36,
        padding: 0,
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        position: 'absolute',
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        listStyle: 'none',
        marginTop: '-17px',
        borderRadius: 4,
    },
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
        '&:last-child': {
            marginRight: 0
        }
    },
    divActionName: {
        height: 30,
        width: '100%',
        textAlign: 'left',
        display: "block",
        padding: '0 5px'
    },
    divRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    divHeaderTitle: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: 12,
        textTransform: 'uppercase',
        color: '#8F9BB3',
    },
    divHeaderItem: {
        marginLeft: 8,
        fontSize: 16,
        fontWeight: 600,
        fontStyle: 'normal',
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '28px'
    }
};

class W05F0003PhaseGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fumiModal: false,
            lotModal: false,
            receiptsModal: false,
            paymentModal: false,
            loadingCboStatus: false,
            currentRowSelected: {},
            popOver: null,
            popOverAction: null,
        };
        this.dataGrid = null;
        this.popoverAction = null;
        this.popoverGrid = null;
    }

    onAction = (e) => {
        const target = e.currentTarget;
        if (this.popoverAction) this.popoverAction.instance.show(target);
    };

    onActionGrid = (e, currentRowSelected) => {
        const target = e.currentTarget;
        this.setState({ currentRowSelected });
        if (this.popoverGrid) this.popoverGrid.instance.show(target);
    };

    handleClose = (stateName) => {
        this.setState({ [stateName]: null });
    };

    renderButtonAction = () => {
        const { classes, master } = this.props;
        return (
            <div className={classes.divRight}>
                <IconButton
                    aria-label={"view"}
                    aria-describedby={master.QuotationID}
                    className={classes.divIconItem}
                    onClick={(e) => this.onAction(e)}
                // disabled={!_.isNull(this.state.popOverAction)}
                >
                    <MoreVertIcon />
                </IconButton>
            </div>
        );
    };

    popupOpen = (e) => {
        const { classes } = this.props;
        return (
            <>
                <PopoverAction
                    reference={ref => this.popoverGrid = ref}
                    position={"right"}
                    maxWidth={300}
                    deferRendering={false}>
                    <div style={{ margin: '5px 10px' }}>
                        <Tooltip title={Config.lang("ERP_Phun_trung")}>
                            <ButtonGeneral
                                variant={"custom"}
                                aria-label={Config.lang("ERP_Phun_trung")}
                                name={Config.lang("ERP_Phun_trung")}
                                className={classes.divActionName}
                                onClick={() => {
                                    this.setState({ fumiModal: true });
                                    this.handleClose("popOver");
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Chuyen_trang_thai_Lot")}>
                            <ButtonGeneral
                                variant={"custom"}
                                aria-label={Config.lang("ERP_Chuyen_trang_thai_Lot")}
                                name={Config.lang("ERP_Chuyen_trang_thai_Lot")}
                                className={classes.divActionName}
                                onClick={() => {
                                    this.setState({ lotModal: true });
                                    this.handleClose("popOver");
                                    const { getCboStatus } = this.props;
                                    if (_.isEmpty(getCboStatus)) {
                                        this.loadCboStatus("ChangeStatus");
                                    }
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Cap_nhat_chung_tu")}>
                            <ButtonGeneral
                                variant={"custom"}
                                aria-label={Config.lang("ERP_Cap_nhat_chung_tu")}
                                name={Config.lang("ERP_Cap_nhat_chung_tu")}
                                className={classes.divActionName}
                                onClick={() => {
                                    this.setState({ receiptsModal: true });
                                    this.handleClose("popOver");
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Thong_tin_thanh_toan_lot")}>
                            <ButtonGeneral
                                variant={"custom"}
                                aria-label={Config.lang("ERP_Thong_tin_thanh_toan_lot")}
                                name={Config.lang("ERP_Thong_tin_thanh_toan_lot")}
                                className={classes.divActionName}
                                onClick={() => {
                                    this.setState({ paymentModal: true });
                                    this.handleClose("popOver");
                                }}
                            />
                        </Tooltip>
                    </div>
                </PopoverAction>
            </>
        )
    }

    showListAction = (e) => {
        const { classes, master, permission, dataSource } = this.props;
        return (
            <>
                <PopoverAction
                    reference={ref => this.popoverAction = ref}
                    position={"right"}
                    maxWidth={300}
                    deferRendering={false}>
                    <div style={{ padding: 4 }}>
                        <Tooltip title={Config.lang("ERP_Xem")}>
                            <span>
                                <IconButton
                                    disabled={!(permission > 0) || _.isEmpty(dataSource)}
                                    aria-label={"View"}
                                    className={classes.divIconItem}
                                    onClick={() => this.redirectScreen("view", master)}
                                >
                                    <Icons name={"view"} />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Sua")}>
                            <span>
                                <IconButton
                                    disabled={!(permission > 2) || _.isEmpty(dataSource)}
                                    aria-label={"Edit"}
                                    className={classes.divIconItem}
                                    onClick={() => this.redirectScreen("edit", master)}
                                >
                                    <Icons name={"edit"} />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Sao_chep")}>
                            <span> <IconButton
                                disabled={!(permission > 1) || _.isEmpty(dataSource)}
                                aria-label={"copy"}
                                className={classes.divIconItem}
                                onClick={() => this.redirectScreen("copy", master)}
                            >
                                <Icons name={"copy"} />
                            </IconButton></span>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Xoa")}>
                            <span><IconButton
                                disabled={!(permission > 3) || _.isEmpty(dataSource)}
                                aria-label={"delete"}
                                className={classes.divIconItem}
                                onClick={() => this.onDelete(master)}
                            >
                                <Icons name={"delete"} />
                            </IconButton></span>
                        </Tooltip>
                    </div>
                </PopoverAction>
            </>
        )
    };

    onCloseModal = (type, rowData) => {
        this.setState({
            [type]: false,
            currentRowSelected: {},
        });
        if (rowData && this.dataGrid) {
            const dataSource = this.dataGrid.instance.option("dataSource");
            dataSource.forEach(data => {
                if (data.LotNo === rowData.LotNo) {
                    data.StatusDetail = rowData.StatusDetail;
                    data.StatusDetailName = rowData.StatusDetailName;
                }
            });
        }
    };

    onDelete = (master) => {
        let sumQuantity = this.props.dataSource.map(item => item.Quantity).reduce((a, b) => a + b);
        if (master && master.Status && master.Status === "3") {
            Config.popup.show("INFO", Config.lang("ERP_Trang_thai_hoan_tat_ban_khong_duoc_phep_xoa"))
        } else if (sumQuantity > 0) {
            Config.popup.show("INFO", Config.lang("ERP_Co_so_luong_ban_khong_duoc_phep_xoa"))
        } else {
            Config.popup.show(
                "YES_NO",
                Config.lang("ERP_Ban_co_chac_muon_xoa?"),
                () => {
                    const params = {
                        QuotationID: master.QuotationID || "",
                        ContractID: master.ContractID || "",
                    };
                    this.props.w05f0003Actions.deleteItemDeloyment(params, async (errors) => {
                        if (errors) {
                            let message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
                            Config.popup.show("INFO", message);
                            return false;
                        } else {
                            const historyStatus = await this.saveHistoryChangeStatus(master.ContractID, master.VoucherNum);
                            if (historyStatus !== 200) {
                                Config.popup.show("INFO", Config.lang("ERP_Co_loi_khi_luu"));
                                return false;
                            }
                            Config.notify.show("success", Config.lang("ERP_Xoa_thanh_cong"), 2000);
                            this.props.loadListDeployments();
                        }
                    })
                }
            );
        }
    };

    redirectScreen = (mode, e = "") => {
        let screen = "";
        let params = {
            ContractID: e.ContractID,
            QuotationID: e.QuotationID,
        };
        switch (mode) {
            case "view":
                params.mode = "view";
                screen = "W05F0005";
                break;
            case "copy":
                params.mode = "copy";
                screen = "W05F0005";
                break;
            case "edit":
                params.mode = "edit";
                screen = "W05F0005";
                break;
            default:
                break;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: params
        });
    }

    saveHistoryChangeStatus = async (ContractID, oldValue) => {
        let el = {};
        let data = [];
        let status = "";
        el.codeID = ContractID;
        el.formID = "W05F0003";
        el.linkedTrans = "";
        el.oldValue = oldValue;
        el.hasURL = false;
        el.description84 = "Đợt triển khai";
        el.description01 = "Deployment";
        el.action = 3;
        data.push(el);
        const params = {
            attributes: JSON.stringify(data)
        };
        await this.props.w05f0003Actions.addHistory(params, (error) => {
            status = error ? 400 : 200
        });

        return status;
    };

    loadCboStatus = (type) => {
        this.setState({ loadingCboStatus: true });
        const params = {
            GroupID: type === "ChangeStatus" ? "D" : "M",
            Language: Config.language || "84",
        };
        this.props.w05f0003Actions.getCboStatus(params, (error) => {
            this.setState({ loadingCboStatus: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    renderIconAction = (e) => {
        const { classes } = this.props;
        const { popOverAction } = this.state;
        const { data } = e.row;
        return (
            <IconButton
                aria-label={"view"}
                aria-describedby={data.QuotationItemID}
                className={classes.divIconItem}
                onClick={(e) => this.onActionGrid(e, data)}
                disabled={!_.isNull(popOverAction)}
            >
                <MoreVertIcon />
            </IconButton>
        )
    }

    renderBtnMappingAction = (e, mode) => {
        return (
            mode === "mobile" ?
                this.renderIconAction(e)
                : <GridActionBar>
                    {this.renderIconAction(e)}
                </GridActionBar>
        );
    };

    render() {
        const { classes, dataSource, phaseLoading, master } = this.props;
        const { currentRowSelected, lotModal, fumiModal, loadingCboStatus, receiptsModal, paymentModal } = this.state;
        return (
            <>
                {fumiModal &&
                    <W05F0003FumiModal
                        master={master}
                        modalOpen={fumiModal}
                        rowDetail={currentRowSelected}
                        onCloseModal={() => this.onCloseModal('fumiModal')}
                    />}
                {lotModal &&
                    <W05F0003LotModal
                        master={master}
                        rowDetail={currentRowSelected}
                        openLotModal={lotModal}
                        loadingCboStatus={loadingCboStatus}
                        onCloseLotModal={(rowData) => this.onCloseModal('lotModal', rowData)}
                    />}
                {receiptsModal &&
                    <W05F0003ReceiptModal
                        master={master}
                        rowDetail={currentRowSelected}
                        onOpenReceiptModal={receiptsModal}
                        onCloseReceiptModal={() => this.onCloseModal('receiptsModal')}
                    />}
                {paymentModal &&
                    <W05F0003PaymentModal
                        master={master}
                        rowDetail={currentRowSelected}
                        onOpenPaymentModal={paymentModal}
                        onClosePaymentModal={() => this.onCloseModal('paymentModal')}
                    />}
                <FormGroup className={"mgb5"}>
                    <Row style={{ marginTop: Config.isMobile ? 18 : 24, position: 'relative' }}>
                        <Col lg={11} md={11} sm={11} xs={10}>
                            <Row>
                                <Col lg={4} md={4} sm={6} xs={12}>
                                    <div className={classes.ellipsis}>
                                        <Label className={classes.divHeaderTitle}
                                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                                            {Config.lang("ERP_Hop_dong") + ":"}
                                        </Label>
                                        <span className={`${classes.divHeaderItem}`}>
                                            {master.ContractNo}
                                        </span>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={6} xs={12}>
                                    <div className={classes.ellipsis}>
                                        <Label className={classes.divHeaderTitle}
                                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                                            {Config.lang("ERP_Dot_trien_khai") + ":"}
                                        </Label>
                                        <span className={classes.divHeaderItem}>
                                            {master.VoucherNum}
                                        </span>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={6} xs={12}>
                                    <div className={classes.ellipsis}>
                                        <Label className={classes.divHeaderTitle}
                                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                                            {Config.lang("ERP_Trang_thai") + ":"}
                                        </Label>
                                        <span className={classes.divHeaderItem}>
                                            {master.StatusName}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ position: 'absolute', right: 0, bottom: 0 }} lg={1} md={1} sm={1} xs={2}>{this.renderButtonAction()}</Col>
                    </Row>
                </FormGroup>
                <GridContainer
                    reference={(ref) => (this.dataGrid = ref)}
                    dataSource={dataSource}
                    loading={phaseLoading}
                    keyExpr={"QuotationItemID"}
                    typePaging={"normal"}
                    showBorders={false}
                    columnAutoWidth={true}
                    pagerFullScreen={false}
                    hoverStateEnabled={true}
                    rowAlternationEnabled={true}
                    elementAttr={{ style: 'min-height: 180px' }}
                >
                    <Column
                        dataField={"LotNo"}
                        sortOrder={"asc"}
                        visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_Hanh_dong")}
                        alignment={"center"}
                        visible={Config.isMobile}
                        cellRender={(e) => this.renderBtnMappingAction(e, 'mobile')}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_lot")}
                        dataField={"LotNo"}
                        alignment={"left"}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_hang")}
                        dataField={"InventoryID"}
                        alignment={"left"}
                        width={150}
                    />
                    <Column
                        caption={Config.lang("ERP_Ten_hang")}
                        dataField={"ItemNameU"}
                        width={150}
                        alignment={"left"}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong")}
                        dataField={"Quantity"}
                        width={100}
                        alignment={"right"}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_da_giao")}
                        dataField={"DeliveryQuantity"}
                        width={160}
                        alignment={"right"}
                    />
                    <Column
                        caption={Config.lang("ERP_Quy_cach_dong_goi")}
                        dataField={"PackingMethodName"}
                        width={200}
                        alignment={"left"}
                    />
                    <Column
                        caption={Config.lang("ERP_Loai_bao_dong_goi")}
                        dataField={"PackingTypeName"}
                        width={200}
                        alignment={"left"}
                    />
                    <Column
                        caption={Config.lang("ERP_Nhan_mac")}
                        dataField={"MarkingName"}
                        width={180}
                    />
                    <Column
                        caption={Config.lang("ERP_Thoi_gian_giao_hang_du_kien")}
                        dataField={"DeliveryDateTo"}
                        width={245}
                        alignment={"center"}
                        dataType={"date"}
                        cellRender={(e) => {
                            const { data } = e.row;
                            if (Config.isValidDateTime(data.DeliveryDateTo) && Config.isValidDateTime(data.DeliveryDateFrom)) {
                                return `${moment(data.DeliveryDateFrom).format("DD/MM/YYYY")} - ${moment(data.DeliveryDateTo).format("DD/MM/YYYY")}`
                            } else {
                                return null;
                            }
                        }}
                    />
                    <Column
                        caption={Config.lang("ERP_Trang_thai")}
                        dataField={"StatusDetailName"}
                        width={120}
                        alignment={"left"}
                    />
                    <Column
                        alignment={"right"}
                        fixed={true}
                        fixedPosition={"right"}
                        visible={!Config.isMobile}
                        cellRender={(e) => this.renderBtnMappingAction(e, 'desktop')}
                    />
                </GridContainer>
                { dataSource.length > 0 && this.popupOpen()}
                { master && this.showListAction()}
            </>
        );
    }
}

W05F0003PhaseGrid.propsTypes = {
    permission: PropTypes.number,
    phaseLoading: PropTypes.bool,
    master: PropTypes.object,
    dataSource: PropTypes.array,
    loadListDeployments: PropTypes.func,
};

export default compose(
    connect((state) => ({
        getCboStatus: state.W05F0003.getCboStatus,
    }),
        (dispatch) => ({ w05f0003Actions: bindActionCreators(w05f0003Actions, dispatch) })
    ),
    withStyles(styles)
)(W05F0003PhaseGrid);