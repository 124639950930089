import { FormLabel as Label, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as w05f0004Actions from "../../../../redux/W0X/W05F0004/W05F0004_actions";
import { Combo, TextField } from "../../../common/form-material";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";

const styles = {
    divHeaderTitle: {
        marginTop: "10px !important",
        fontWeight: "bold",
        fontStyle: "normal",
        fontSize: "0.85rem",
        textTransform: "uppercase",
        color: "#8F9BB3",
    },
};

class W05F0004LotModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Status: 0,
            StatusDetailName: "",
        };
    }

    saveHistoryChangeStatus = async (ContractID, oldValue, newValue, LotNo) => {
        let el = {};
        let data = [];
        let status = "";
        el.codeID = ContractID;
        el.formID = "W05F0004";
        el.linkedTrans = "";
        el.oldValue = oldValue;
        el.newValue = newValue;
        el.hasURL = false;
        el.description84 = Config.lang("Trạng thái ") + Config.lang("Lot ") + LotNo;
        el.description01 = Config.lang("Status ") + Config.lang("Lot ") + LotNo;
        el.action = 1;
        data.push(el);
        const params = {
            attributes: JSON.stringify(data),
        };
        await this.props.w05f0004Actions.addHistory(params, (error) => {
            status = error ? 400 : 200
        });
        return status;
    };

    onSaveChangeStatus = () => {
        const { rowDetail, onCloseLotModal } = this.props;
        const { Status, StatusDetailName } = this.state;
        const { QuotationID, ContractID } = this.props.master;
        let rowData = { ...rowDetail };
        const params = {
            Status: Status ? Status : "",
            QuotationID: QuotationID ? QuotationID : "",
            QuotationItemID: rowDetail?.QuotationItemID,
        };
        rowData.StatusDetail = Status ? Status : 0;
        rowData.StatusDetailName = StatusDetailName ? StatusDetailName : "";
        this.props.w05f0004Actions.saveChangeStatus(params, async (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data === "successful") {
                const historyStatus = await this.saveHistoryChangeStatus(
                    ContractID,
                    rowDetail.StatusDetail,
                    Status,
                    rowDetail.LotNo
                );
                if (historyStatus !== 200) {
                    Config.popup.show("INFO", Config.lang("ERP_Co_loi_khi_luu"));
                    return false;
                } else {
                    Config.notify.show(
                        "success",
                        Config.lang("ERP_Luu_thanh_cong"),
                        2000
                    );
                    if (onCloseLotModal) {
                        this.setState({ Status: 0, StatusDetailName: "" });
                        onCloseLotModal(rowData);
                    }
                }
            }
        });
    };

    render() {
        const { Status } = this.state;
        const {
            openLotModal,
            onCloseLotModal,
            classes,
            getCboStatus,
            rowDetail,
            loadingCboStatus,
        } = this.props;
        return (
            <Modal
                open={openLotModal}
                maxWidth={"sm"}
                fullWidth={true}
                position={"center"}
            >
                <Modal.Title disableTypography>
                    <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%", margin: '4px 0' }}>
                        <div className={"display_row align-center"}>
                            <Typography variant="h6" className={"mgr10 text-uppercase"}>{Config.lang("ERP_Chuyen_doi_trang_thai_LOT")}</Typography>
                        </div>
                        <div>
                            <IconButton
                                disabled={false}
                                aria-label="save"
                                size={"small"}
                                className={"mgr10"}
                                onClick={() => {
                                    this.onSaveChangeStatus();
                                }}
                            >
                                <Icons name={"save_filled"} />
                            </IconButton>
                            <IconButton
                                aria-label="close"
                                size={"small"}
                                onClick={() => {
                                    this.setState({ Status: 0, StatusDetailName: "" });
                                    onCloseLotModal(null);
                                }}
                            >
                                <Icons name={"cancel_filled"} />
                            </IconButton>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Content style={{ marginBottom: 15 }}>
                    <Row style={{ margin: 0, display: "flex", alignItems: "center" }}>
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <Label className={classes.divHeaderTitle}>
                                {Config.lang("ERP_Ma_lot")}
                            </Label>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <TextField
                                variant={"standard"}
                                margin={"normal"}
                                value={rowDetail?.LotNo}
                                inputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row style={{ margin: 0, display: "flex", alignItems: "center" }}>
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <Label className={classes.divHeaderTitle}>
                                {Config.lang("ERP_Trang_thai_cu")}
                            </Label>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <TextField
                                variant={"standard"}
                                margin={"normal"}
                                value={rowDetail?.StatusDetailName}
                                inputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row style={{ margin: 0, display: "flex", alignItems: "center" }}>
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <Label className={classes.divHeaderTitle}>
                                {Config.lang("ERP_Trang_thai_moi")}
                            </Label>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <Combo
                                dataSource={getCboStatus}
                                valueExpr={"Status"}
                                stylingMode={"underlined"}
                                displayExpr={"StatusName"}
                                showClearButton={true}
                                loading={loadingCboStatus}
                                value={Status ? Status : ""}
                                onValueChanged={(e) => {
                                    let selectedItem = getCboStatus.filter((item) => {
                                        return item.Status === e.value;
                                    });
                                    let isCheck = rowDetail.StatusDetail === e.value;
                                    if (isCheck) {
                                        Config.popup.show(
                                            "INFO",
                                            Config.lang("ERP_Ban_da_chon_phuong_thuc_nay"),
                                            () => {
                                                this.setState({
                                                    Status: 0,
                                                    StatusDetailName: "",
                                                });
                                            }
                                        );
                                        return false;
                                    } else {
                                        this.setState({
                                            Status: e.value,
                                            StatusDetailName:
                                                selectedItem.length > 0
                                                    ? selectedItem[0].StatusName
                                                    : "",
                                        });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Modal.Content>
            </Modal>
        );
    }
}

W05F0004LotModal.propsTypes = {
    w05f0004Actions: PropTypes.any,
};

export default compose(
    connect(
        (state) => ({
            getCboStatus: state.W05F0004.getCboStatus,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w05f0004Actions: bindActionCreators(w05f0004Actions, dispatch),
        })
    ),
    withStyles(styles)
)(W05F0004LotModal);
