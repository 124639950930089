import { Checkbox, FormLabel as Label, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as w05f0003Actions from "../../../../redux/W0X/W05F0003/W05F0003_actions";
import ButtonGeneral from "../../../common/button/button-general";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";

const styles = {
    lotCode: {
        width: '100%',
        marginBottom: 15,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    divHeaderTitle: {
        fontWeight: "bold",
        fontStyle: "normal",
        fontSize: "0.85rem",
        textTransform: "uppercase",
        color: "#8F9BB3",
    },
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
    },
    divHeaderItem: {
        marginLeft: 8,
        fontSize: 16,
        fontWeight: "600",
        fontStyle: "normal",
    },
    cellPadding: {
        '& .dx-datagrid-content': {
            '& td': {
                paddingTop: '8px !important',
                paddingBottom: '8px !important',
            }
        }
    }
};

class W05F0003ReceiptModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalReceipt: 0,
            btnSaveStatus: true,
            allowEditing: false,
            dataReceipt: []
        };
        this.filterReceipt = {
            QuotationItemID: ''
        };
        this.receiptGrid = null;
        this.dataReceiptOld = [];
    }

    componentDidMount = () => {
        if (_.isEmpty(this.props.rowDetail)) return;
        const { QuotationItemID } = this.props.rowDetail;
        if (!_.isUndefined(QuotationItemID)) this.filterReceipt.QuotationItemID = QuotationItemID;
        this.loadReceipts();
    }

    loadReceipts = () => {
        if (this.state.receiptsLoaded === false) this.setState({ receiptsLoaded: true })
        const { QuotationItemID } = this.filterReceipt;
        const params = {
            QuotationItemID,
            Language: Config.language || "84"
        };
        this.props.w05f0003Actions.loadReceipt(params, async (error, data) => {
            this.setState({ receiptsLoaded: false })
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data.length > 0) {
                const allowEditing = (data[0]).IsCompleteVoucher === 1; // View only 
                this.setState({
                    dataReceipt: data,
                    totalReceipt: data.length,
                    allowEditing
                }, () => {
                    this.dataReceiptOld = this.state.dataReceipt.map(item => ({ ...item }));
                })
            }
        });
    };

    onSaveHistory = async (newData, saveReceiptStatus) => {
        let params = {};
        let status = 0;
        const oldData = this.dataReceiptOld;
        const { LotNo } = this.props.rowDetail;
        const captions = {
            //master
            VoucherTypeName: "ERP_Chung_tu",
            IsUse: "ERP_Su_dung",
            IsComplete: "ERP_Hoan_thanh"
        };
        const options = {
            codeID: newData[0].QuotationID,
            keyExpr: "VoucherTypeID",
            formID: "W05F0003",
            mode: "edit",
            itemRender: (e, type) => {
                const { mode, data, captions, dataField, oldData } = e;
                if (mode === "editGrid") {
                    const description84 = dataField !== 'VoucherTypeName' ? Config.lang(captions[dataField], "vi") + ' chứng từ ' + data.VoucherTypeName :
                        Config.lang('Ten_dieu_chinh_chung_tu_cua_lot', 'vi') + ' ' + LotNo;
                    const description01 = dataField !== 'VoucherTypeName' ? Config.lang(captions[dataField], "en") + ' Lot ' + data.VoucherTypeName :
                        Config.lang('Ten_dieu_chinh_chung_tu_cua_lot', 'en') + ' ' + LotNo;
                    if (type === "description84") return description84;
                    if (type === "description01") return description01;
                    if (type === "newValue") return dataField !== 'VoucherTypeName' ? data[dataField] === 0 ? 'UnChecked' : 'Checked' : data[dataField];
                    if (type === "oldValue") return dataField !== 'VoucherTypeName' ? oldData[dataField] === 0 ? 'UnChecked' : 'Checked' : oldData[dataField];
                }
            }
        };
        let data = await Config.createSaveHistory(newData, oldData, captions, options, true);
        if (data.length > 0) {
            // data.map(rs => {
            //     rs.oldValue = rs.oldValue === 0 ? 'UnChecked' : 'Checked'
            //     rs.newValue = rs.newValue === 0 ? 'UnChecked' : 'Checked'
            //     return rs;
            // });
            if (saveReceiptStatus) {
                //Nếu Save complete có data thì nối chuỗi của save complete luôn
                await this.saveHistoryCompleteReceipt(true).then(dataHistoryComplete => {
                    data = data.concat(dataHistoryComplete);
                })
            }
            params = { attributes: JSON.stringify(data) }
            status = await Config.saveHistory(params);
        }
        return status;
    }

    saveHistoryCompleteReceipt = async (historySave) => {
        const { LotNo } = this.props.rowDetail;
        const { QuotationID } = this.props.master;
        let el = {};
        let data = [];
        let status = "";
        el.codeID = QuotationID;
        el.formID = "W05F0003";
        el.linkedTrans = "";
        el.hasURL = false;
        el.description84 = Config.lang("Cập nhật lại trạng thái thông tin chứng từ của") + " Lot " + LotNo;
        el.description01 = Config.lang("Changed the status vourcher of") + " Lot " + LotNo;
        el.action = 2;
        data.push(el);
        if (historySave) {
            status = data;
        } else {
            const params = { attributes: JSON.stringify(data) };
            await this.props.generalActions.saveHistory(params, (error) => {
                status = error ? 400 : 200
            });
        }
        return status;
    }

    onSaveReceipt = async (noLoadHistory) => {
        const { master } = this.props;
        const { dataReceipt, receiptsLoaded } = this.state;
        await this.receiptGrid.instance.saveEditData();
        const dataReceiptSource = this.receiptGrid.instance.option("dataSource");
        const dataReceipts = dataReceiptSource ? dataReceiptSource : dataReceipt;
        const { QuotationItemID } = this.filterReceipt;
        const { ContractID, QuotationID } = master;
        const params = {
            ContractID,
            QuotationItemID,
            QuotationID,
            receipts: JSON.stringify(dataReceipts)
        };
        if (!receiptsLoaded) this.setState({ receiptsLoaded: true })
        await this.props.w05f0003Actions.saveDataReceipt(params, async (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                this.setState({ receiptsLoaded: false })
                return false;
            }
            if (data) {
                this.setState({ receiptsLoaded: false })
                if (noLoadHistory) { // Không cần load save lịch sử
                    Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
                    this.dataReceiptOld = dataReceipts.map(item => ({ ...item }));
                    this.loadReceipts(); // Reload Data Popup receipt
                } else {
                    this.onSaveHistory(dataReceiptSource, false).then(status => {
                        if (status === 200 || status === 0) {
                            Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
                            if (status === 200) this.dataReceiptOld = dataReceipts.map(item => ({ ...item })); // ReSet Old Data
                        } else { // 400
                            Config.notify.show("error", Config.lang("ERP_Luu_lich_su_khong_thanh_cong"), 2000);
                        }
                    })
                }
            }
        });
    };

    onSaveBtnCompleteReceipt = async () => {
        const { allowEditing, receiptsLoaded } = this.state;
        if (!receiptsLoaded) this.setState({ receiptsLoaded: true })
        await this.receiptGrid.instance.saveEditData();
        const dataReceiptSource = this.receiptGrid.instance.option("dataSource");
        const { QuotationItemID } = this.filterReceipt;
        const IsCompleteVoucher = allowEditing ? 1 : 0;
        const params = {
            IsCompleteVoucher,
            QuotationItemID
        };
        await this.props.w05f0003Actions.saveCompleteReceipt(params, async (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                this.setState({ receiptsLoaded: false })
                return false;
            }
            if (data) {
                this.onSaveHistory(dataReceiptSource, true).then(async status => { // Kiểm tra 
                    if (status === 200) {//Save lịch sử chứng từ + nút hoàn thành và tiếp tục save chứng từ đó 
                        await this.onSaveReceipt(true);
                    } else { //Chỉ save lịch sử nút hoàn thành
                        await this.saveHistoryCompleteReceipt().then(status => {
                            if (receiptsLoaded) this.setState({ receiptsLoaded: false })
                            if (status === 200) {
                                Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000); //Chút tô ma te 
                                this.loadReceipts(); // Reload Data Popup receipt
                            } else {
                                Config.notify.show("error", Config.lang("ERP_Luu_lich_su_khong_thanh_cong"), 2000);
                            }
                        })
                    }
                });
            }
        });
    };

    confirmBtnComplete = () => {
        const { allowEditing } = this.state;
        const messageBtnComplete = allowEditing ? "ERP_Ban_co_muon_chuyen_trang_thai_chung_tu" : "ERP_Thong_bao_xac_nhan_chung_tu";
        Config.popup.show("YES_NO", Config.lang(messageBtnComplete), () => {
            this.onSaveBtnCompleteReceipt();
        });
    }

    render() {
        const { receiptsLoaded, dataReceipt, totalReceipt, allowEditing, btnSaveStatus } = this.state;
        const { onOpenReceiptModal, onCloseReceiptModal, rowDetail, classes } = this.props;
        const btnCompleteTitle = allowEditing ? "ERP_Da_hoan_tat" : "ERP_Hoan_tat";

        return (
            <Modal
                open={onOpenReceiptModal}
                maxWidth={"sm"}
                fullWidth={true}
                position={"center"}
            >
                <Modal.Title disableTypography>
                    <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                        <div className={"display_row align-center"}>
                            <Typography variant="h6" className={"mgr10 text-uppercase"}>{Config.lang("ERP_Cap_nhat_chung_tu")}</Typography>
                            <Tooltip title={Config.lang(btnCompleteTitle)}>
                                <span>
                                    <ButtonGeneral
                                        variant={"outlined"}
                                        color={"primary"}
                                        aria-label={Config.lang(btnCompleteTitle)}
                                        name={Config.lang(btnCompleteTitle)}
                                        className={classes.divIconItem}
                                        style={{ minWidth: "6rem" }}
                                        onClick={() => this.confirmBtnComplete()}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                            <IconButton
                                disabled={allowEditing || btnSaveStatus}
                                aria-label={"save"}
                                size={"small"}
                                className={"mgr10"}
                                onClick={() => this.onSaveReceipt()}
                            >
                                <Icons name={"save_filled"} />
                            </IconButton>
                            <IconButton
                                aria-label={"close"}
                                size={"small"}
                                onClick={() => {
                                    this.setState({ Status: 0, StatusDetailName: "" });
                                    onCloseReceiptModal(null);
                                }}
                            >
                                <Icons name={"cancel_filled"} />
                            </IconButton>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Content>
                    <div className={classes.lotCode} style={{ textAlign: 'right' }}>
                        <Label className={classes.divHeaderTitle}
                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                            {Config.lang("ERP_Ma_lot") + ":"}
                        </Label>
                        <span className={`${classes.divHeaderItem}`}
                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                            {Config.lang(rowDetail.LotNo)}
                        </span>
                    </div>
                    <div className={classes.cellPadding}>
                        <GridContainer
                            totalItems={totalReceipt}
                            dataSource={dataReceipt}
                            reference={(ref) => (this.receiptGrid = ref)}
                            loading={receiptsLoaded}
                            keyExpr={'VoucherTypeID'}
                            typePaging={"normal"}
                            typeShort={window.innerWidth < 768}
                            rowAlternationEnabled={true}
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            pagerFullScreen={false}
                            columnAutoWidth={true}
                            showBorders={false}
                            loadPanel={{ enabled: receiptsLoaded }}
                            elementAttr={{
                                style: 'max-height: 480px',
                                class: `noBGSelect`

                            }}
                            editing={{
                                mode: 'cell',
                                refreshMode: "reshape",
                                allowUpdating: true,
                                texts: { confirmDeleteMessage: "" }
                            }}
                            onRowUpdated={(e) => {
                                if (this.state.btnSaveStatus) this.setState({ btnSaveStatus: false })
                            }}
                        >
                            <Column
                                caption={Config.lang("ERP_Chung_tu")}
                                dataField={'VoucherTypeName'}
                                width={200}
                            />
                            <Column
                                caption={Config.lang("ERP_Su_dung")}
                                dataField={'IsUse'}
                                alignment={'center'}
                                width={180}
                                allowEditing={false}
                                cellRender={(e) => {
                                    const { data } = e;
                                    return <Checkbox label={""}
                                        disabled={allowEditing}
                                        checked={!!data.IsUse}
                                        color="primary"
                                        style={{ padding: 0 }}
                                        onChange={(chk) => {
                                            if (allowEditing) return;
                                            if (data.IsComplete === 1) {
                                                const message = Config.lang("ERP_Ban_da_check_hoan_thanh_chung_tu_nay");
                                                Config.popup.show("INFO", message);
                                            } else {
                                                e.component.cellValue(e.rowIndex, "IsUse", chk.target.checked ? 1 : 0)
                                                e.component.saveEditData();
                                            }
                                        }}
                                    />
                                }}
                            />
                            <Column
                                caption={Config.lang("ERP_Hoan_thanh")}
                                dataField={'IsComplete'}
                                alignment={'center'}
                                width={180}
                                allowEditing={false}
                                cellRender={(e) => {
                                    const { data } = e;
                                    return <Checkbox label={""}
                                        disabled={allowEditing}
                                        checked={!!data.IsComplete}
                                        color="primary"
                                        style={{ padding: 0 }}
                                        onChange={(chk) => {
                                            if (allowEditing) return;
                                            if (data.IsUse === 1) {
                                                e.component.cellValue(e.rowIndex, "IsComplete", chk.target.checked ? 1 : 0);
                                                e.component.saveEditData();
                                            } else {
                                                const message = Config.lang("ERP_Ban_phai_check_su_dung_truoc");
                                                Config.popup.show("INFO", message);
                                            }
                                        }}
                                    />
                                }}
                            />
                        </GridContainer>
                    </div>
                </Modal.Content>
            </Modal >
        );
    }
}

W05F0003ReceiptModal.propsTypes = {
    onOpenReceiptModal: PropTypes.bool,
    master: PropTypes.object,
    rowDetail: PropTypes.object,
    onCloseReceiptModal: PropTypes.func,
};

export default compose(connect(null,
    (dispatch) => ({
        w05f0003Actions: bindActionCreators(w05f0003Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
    })
),
    withStyles(styles)
)(W05F0003ReceiptModal);
