import { FormLabel as Label, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Column, RequiredRule } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from 'moment';
import PropTypes from "prop-types";
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as w05f0003Actions from "../../../../redux/W0X/W05F0003/W05F0003_actions";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import PopoverGrid from "../../../grid-container/popover-grid";

const styles = {
    lotCode: {
        width: '100%',
        marginBottom: 15,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    divHeaderTitle: {
        fontWeight: "bold",
        fontStyle: "normal",
        fontSize: 12,
        textTransform: "uppercase",
        color: "#8F9BB3",
    },
    divHeaderItem: {
        marginLeft: 8,
        fontSize: 16,
        fontWeight: "600",
        fontStyle: "normal",
    },
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
        '&:last-child': {
            marginRight: 0
        }
    },
    numberFormatInput: {
        backgroundColor: 'transparent',
        border: 'none',
        margin: 0
    }
};

class W05F0003PaymentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPayment: 0,
            btnSaveStatus: true,
            paymentLoaded: false,
            btnActionStatus: false,
            currentRowSelected: {},
            defaultPaymentMethodData: {},
            dataPayment: [],
            paymentPopupAction: null,
            actionRow: null
        };
        this.filterPayment = {
            PaymentInforID: "",
            ContractID: "",
            QuotationID: "",
            QuotationItemID: ""
        };
        this.paymentGrid = null;
        this.dataPaymentOld = [];
        this.countPaymentInforID = 0;
        this.selectedRange = {};
    }

    componentDidMount = () => {
        const { getCboPaymentMethod, getFlexCaption } = this.props;
        if (_.isEmpty(getCboPaymentMethod)) this.getPaymentMethod();
        if (_.isEmpty(getFlexCaption)) this.getFlexCaption();
        const { rowDetail, master } = this.props;
        const { ContractID, QuotationID } = master;
        const { QuotationItemID } = rowDetail;
        if (!_.isEmpty(rowDetail) || !_.isEmpty(master)) {
            this.filterPayment.ContractID = ContractID;
            this.filterPayment.QuotationID = QuotationID;
            if (!_.isUndefined(QuotationItemID)) {
                this.filterPayment.QuotationItemID = QuotationItemID;
                this.loadPayments();
            }
        }
    }

    loadPayments = () => {
        const { PaymentInforID, ContractID, QuotationID, QuotationItemID } = this.filterPayment;
        const params = {
            ContractID,
            QuotationID,
            QuotationItemID,
            PaymentInforID
        };
        if (this.state.paymentLoaded === false) this.setState({ paymentLoaded: true })
        this.props.w05f0003Actions.getPopupPayment(params, async (error, data) => {
            this.setState({ paymentLoaded: false })
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data.length > 0) {
                this.countPaymentInforID = data.length;
                data.forEach((item, i) => {
                    item['keyData'] = this.countPaymentInforID - (i + 1);
                });
                let formatDateTimePayment = data.map(dataPayment => {
                    Object.keys(dataPayment).forEach(key => {
                        if (Config.isValidDateTime(dataPayment[key])) {
                            dataPayment[key] = moment(dataPayment[key]).format("YYYY-MM-DD")
                        }
                    });
                    return dataPayment;
                });
                this.setState({
                    dataPayment: formatDateTimePayment,
                    totalPayment: data.length
                }, () => {
                    this.dataPaymentOld = this.state.dataPayment.map(item => ({ ...item }));
                })
            }
        });
    };

    getFlexCaption = async () => {
        const GroupInfo = "V";
        const InfoID = "W05V";
        const params = {
            GroupInfo,
            InfoID,
            Language: Config.language || "84",
        };
        this.setState({ paymentLoaded: true })
        await this.props.w05f0003Actions.getFlexCaption(params, async (error) => {
            this.setState({ paymentLoaded: false })
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    getPaymentMethod = () => {
        this.props.w05f0003Actions.loadCboPaymentMethod(async (error) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    getPaymentMethodDefault = async (ContractID, cb) => {
        const { master } = this.props;
        if (_.isEmpty(master)) return;
        const params = {
            ContractID
        };
        await this.props.w05f0003Actions.getPaymentMethodDefault(params, cb)
    };

    onSaveHistory = async (newData) => {
        let params = {};
        let status = 200;
        const { rowDetail, master, getFlexCaption } = this.props;
        const { QuotationID } = master;
        const { LotNo } = rowDetail;
        const oldData = this.dataPaymentOld;
        const captions = {
            PaymentName: "ERP_Ten_thong_tin_thanh_toan",
            PaymentMethodID: "ERP_Phuong_thuc_thanh_toan",
        };
        const options = {
            codeID: QuotationID,
            keyExpr: "PaymentInforID",
            formID: "W05F0003",
            excludeFields: ["keyData"],
            linkedTrans: "",
            itemRender: (e, type) => {
                const { mode, data, captions, dataField } = e;
                let caption84 = captions[dataField];
                let caption01 = captions[dataField];
                if (mode === "addGrid" || mode === "removeGrid") {
                    if (type === "newValue" || type === "oldValue") return e.data.PaymentName;
                    if (type === "description84") return Config.lang("ERP_Thong_tin_thanh_toan_cua_lot", "vi") + " " + LotNo;
                    if (type === "description01") return Config.lang("ERP_Thong_tin_thanh_toan_cua_lot", "en") + " " + LotNo;
                }
                if (mode === "editGrid") {
                    if (_.isUndefined(captions[dataField])) {
                        const flexCaptionField = getFlexCaption.find(item => item.FieldName === dataField);
                        caption84 = flexCaptionField.Caption84;
                        caption01 = flexCaptionField.Caption01;
                    }
                    if (type === "description84") return Config.lang(caption84, "vi") + " " + Config.lang("ERP_Thong_tin_thanh_toan", "vi") + " " + data.PaymentMethodName + ' của LOT ' + LotNo;
                    if (type === "description01") return Config.lang(caption01, "en") + " " + Config.lang("ERP_Thong_tin_thanh_toan", "en") + " " + data.PaymentMethodName + ' of LOT ' + LotNo;
                }
            }
        };
        let data = await Config.createSaveHistory(newData, oldData, captions, options, true);
        if (data.length > 0) {
            params = { attributes: JSON.stringify(data) }
            status = await Config.saveHistory(params);
        }
        return status;
    }

    formatDateTimeData = (dataList) => {
        dataList.forEach(data => {
            // if (data.hasOwnProperty('keyData')) delete data.keyData;
            Object.keys(data).forEach(key => {
                if (Config.isValidDateTime(data[key])) {
                    data[key] = moment(data[key]).format("YYYY-MM-DD")
                }
            });
        });
        return dataList;
    }

    onSavePayment = async () => {
        if (this.paymentGrid) {
            await this.paymentGrid.instance.saveEditData();
            await new Promise(resolve => setTimeout(resolve, 0));
        }
        let dataPaymentsOder = [];
        const { dataPayment } = this.state;
        const oldData = this.dataPaymentOld;
        const { ContractID, QuotationID, QuotationItemID } = this.filterPayment;
        const dataPaymentSource = this.paymentGrid.instance.option("dataSource");
        const dataPayments = dataPaymentSource ? dataPaymentSource : dataPayment;
        let dataPaymentAddNew = dataPayments.filter((obj) => !oldData.some((obj2) => obj.PaymentInforID === obj2.PaymentInforID));
        const newestPaymentData = dataPaymentAddNew.reverse();
        dataPaymentsOder = !_.isEmpty(newestPaymentData) ? [...new Set(newestPaymentData.concat(dataPayments))] : dataPayments;
        const finalDataPayments = this.formatDateTimeData(dataPaymentsOder);
        const params = {
            ContractID,
            QuotationItemID,
            QuotationID,
            payments: JSON.stringify(finalDataPayments)
        };
        this.setState({ paymentLoaded: true })
        await this.props.w05f0003Actions.saveDataPayments(params, async (error, data) => {
            this.setState({ paymentLoaded: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.onSaveHistory(finalDataPayments).then(status => {
                    if (status === 200) {
                        if (!this.state.btnSaveStatus) this.setState({ btnSaveStatus: true });
                        Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
                        this.dataPaymentOld = finalDataPayments.map(item => ({ ...item }));
                    } else {
                        Config.notify.show("error", Config.lang("ERP_Luu_lich_su_khong_thanh_cong"), 2000);
                    }
                })
            }
        });
    };

    renderPaymentColumn = () => {
        const { getFlexCaption, classes, getDecimalQuantity } = this.props;
        const { OriginalDecimal = 0 } = getDecimalQuantity;
        const supColumn = getFlexCaption.map((item, i) => {
            const { Caption } = item;
            const width = Caption.length > 20 ? 330 : 150;
            let { FieldName } = item;
            let fieldType = "";
            let formatDate = "";
            let alignment = "left";
            const limitFieldName = FieldName.slice(0, parseInt("-" + (FieldName.length - 3).toString()));
            switch (limitFieldName) {
                case "Num":
                    fieldType = "number";
                    alignment = "right";
                    break;
                case "Dat":
                    fieldType = "date";
                    alignment = "center";
                    formatDate = "dd/MM/yyyy";
                    break;
                case "Str":
                    fieldType = "string";
                    break;
                default:
                    break;
            }
            return (
                <Column
                    key={i}
                    caption={Caption}
                    dataField={FieldName}
                    dataType={fieldType}
                    format={formatDate}
                    width={width}
                    alignment={alignment}
                    // cellRender={(e) => this.cellRender(e, fieldType)}
                    {...(fieldType === "number" ? {
                        cellRender: (e) => (
                            <NumberFormat decimalScale={OriginalDecimal || 0} value={_.toNumber(e.value)} displayType={'text'} thousandSeparator={true} />
                        ),
                        editCellRender: (e) => {
                            return (
                                <NumberFormat
                                    style={{ width: '100%' }}
                                    className={classes.numberFormatInput}
                                    decimalScale={OriginalDecimal}
                                    thousandSeparator={true}
                                    isNumericString={true}
                                    value={e.value}
                                    onValueChange={(c) => e.setValue(_.toNumber(c.value))}
                                />
                            )
                        }
                    } :
                        fieldType === "date" ? {
                            editorOptions: {
                                placeholder: "DD/MM/YYYY",
                                dateSerializationFormat: 'yyyy-MM-dd'
                            }
                        } : {})
                    }
                />
            )
        });
        return supColumn;
    };

    cellRender = (e, fieldType) => {
        const { data } = e.row;
        const { column } = e;
        const { getDecimal } = this.props;
        if (!data || !column || !column.dataField) return;
        if (fieldType === "number") {
            return <NumberFormat decimalScale={getDecimal.OriginalDecimal || 0} value={_.toNumber(e.value)} displayType={'text'} thousandSeparator={true} />
        }
        let tranFormField = data[column.dataField];
        if (!_.isEmpty(tranFormField) || _.isNumber(tranFormField)) {
            data[column.dataField] = data && (tranFormField || tranFormField === 0) ? tranFormField : "";
        }
        const limitFieldName = (column.dataField).slice(0, parseInt("-" + ((column.dataField).length - 3).toString()));
        const parseDateTime = !_.isEmpty(tranFormField) ? moment.utc(tranFormField).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
        if (limitFieldName === "Dat" && !_.isNull(tranFormField) && !_.isNumber(tranFormField)) {
            tranFormField = !_.isUndefined(tranFormField) ? parseDateTime : moment();
        }
        return tranFormField;
    };

    handleClick = async (event, data, stateName, actionRow = {}) => {
        const target = event.currentTarget;
        setTimeout(() => this.setState({ [stateName]: target, currentRowSelected: data && data, actionRow }), 0);
    };

    handleClose = (stateName) => {
        this.setState({ [stateName]: null });
    };

    renderBtnMoreAction = (e, mode) => {
        return (
            mode === "mobile" ?
                this.renderIconAction(e)
                : <GridActionBar>
                    {this.renderIconAction(e)}
                </GridActionBar>
        );
    }

    renderIconAction = (e) => {
        const { data } = e.row;
        const { classes } = this.props;
        const { btnActionStatus } = this.state;
        const { paymentPopupAction } = this.state;
        return (
            <IconButton
                aria-describedby={data.PaymentInforID}
                size={"small"}
                aria-label={"view"}
                className={`${classes.divIconItem}`}
                onClick={async (a) => {
                    if (this.paymentGrid.instance.hasEditData()) await this.paymentGrid.instance.saveEditData();
                    this.handleClick(a, data, "paymentPopupAction", e)
                }}
                disabled={!(paymentPopupAction === null) || btnActionStatus}
            >
                <MoreVertIcon />
            </IconButton>
        );
    };

    onCopyPayment = async (dataSelected) => {
        const { dataPayment, btnSaveStatus } = this.state;
        const dataSelectedCopy = { ...dataSelected };
        dataSelectedCopy.keyData = this.countPaymentInforID++;
        dataSelectedCopy.PaymentInforID = _.toString(dataSelectedCopy.keyData);
        dataPayment.push(dataSelectedCopy);
        if (btnSaveStatus) this.setState({ btnSaveStatus: false });
    };

    showListAction = () => {
        const { classes } = this.props;
        const { currentRowSelected, paymentPopupAction, paymentLoaded, actionRow } = this.state;
        return (
            <>
                { !_.isNull(paymentPopupAction) && <PopoverGrid
                    id={currentRowSelected.QuotationItemID}
                    anchorEl={paymentPopupAction}
                    onClose={() => this.handleClose("paymentPopupAction")}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: Config.isMobile ? 'right' : 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: Config.isMobile ? 'left' : 'right',
                    }}
                    itemProps={
                        <div style={{ padding: 4 }}>
                            <Tooltip title={Config.lang("ERP_Sao_chep")}>
                                <span>
                                    <IconButton
                                        disabled={paymentLoaded}
                                        aria-label={"copy"}
                                        className={classes.divIconItem}
                                        onClick={() => {
                                            this.handleClose("paymentPopupAction");
                                            if (!_.isEmpty(actionRow.data)) {
                                                this.onCopyPayment(actionRow.data);
                                            }
                                        }}
                                    >
                                        <Icons name={"copy"} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title={Config.lang("ERP_Xoa")}>
                                <span>
                                    <IconButton
                                        disabled={paymentLoaded}
                                        aria-label={"delete"}
                                        className={classes.divIconItem}
                                        onClick={() => {
                                            Config.popup.show("YES_NO", Config.lang("ERP_Ban_co_chac_chan_muon_xoa_thong_tin_thanh_toan_nay_khong"), async () => {
                                                this.setState({ paymentPopupAction: null, btnSaveStatus: false }, () => {
                                                    if (_.isEmpty(actionRow)) return;
                                                    setTimeout(() => {
                                                        actionRow.component.deleteRow(actionRow.rowIndex);
                                                    }, 300);
                                                });
                                            });
                                        }}
                                    >
                                        <Icons name={"delete"} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                    }
                />}
            </>
        )
    };

    onAddNewRow = async () => {
        // const { master } = this.props;
        // const { defaultPaymentMethodData } = this.state;
        // const { ContractID } = master;
        if (this.paymentGrid) {
            this.setState({ btnActionStatus: true }, () => {
                this.paymentGrid.instance.addRow()
            });
            // if (_.isEmpty(defaultPaymentMethodData)) {
            //     await this.getPaymentMethodDefault(ContractID, (error, data) => {
            //         this.setState({ defaultPaymentMethodData: data, btnActionStatus: true }, () => {
            //             setTimeout(() => { this.paymentGrid.instance.addRow() }, 300);
            //         });
            //     });
            // }
            // else if (!_.isEmpty(defaultPaymentMethodData)) {
            //     this.setState({ btnActionStatus: true }, () => {
            //         setTimeout(() => { this.paymentGrid.instance.addRow() }, 300);
            //     });
            // }
        }
    };

    render() {
        const { dataPayment, totalPayment, paymentLoaded, btnActionStatus, btnSaveStatus } = this.state;
        const { onOpenPaymentModal, onClosePaymentModal, classes, getCboPaymentMethod, getFlexCaptionName, getFlexCaption, rowDetail } = this.props;
        const { ContractID, QuotationID, QuotationItemID } = this.filterPayment;
        const dropDownNameList = ["PaymentMethodID"];
        let rowValidation = {};
        return (
            <Modal
                open={onOpenPaymentModal}
                maxWidth={"lg"}
                fullWidth={true}
                position={"center"}
            >
                <Modal.Title disableTypography>
                    <div className={"display_row align-center align-between"} style={{ width: "100%", margin: '4px 0' }}>
                        <div className={"display_row align-center"}>
                            <Typography variant="h6" className={"mgr10 text-uppercase"}>{Config.lang("ERP_Thong_tin_thanh_toan")}</Typography>
                        </div>
                        <div className={"display_row"}>
                            <IconButton
                                disabled={paymentLoaded || btnActionStatus || btnSaveStatus}
                                aria-label={"save"}
                                size={"small"}
                                className={"mgr10"}
                                onClick={async () => {
                                    if (this.state.btnActionStatus) this.setState({ btnActionStatus: false })
                                    await this.onSavePayment();
                                    onClosePaymentModal(); //CLOSE Popup
                                }}
                            >
                                <Icons name={"save_filled"} />
                            </IconButton>
                            <IconButton
                                aria-label={"close"}
                                size={"small"}
                                onClick={() => onClosePaymentModal()}
                            >
                                <Icons name={"cancel_filled"} />
                            </IconButton>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Content style={{ marginBottom: '-6px' }}>
                    <div className={classes.lotCode} style={{ textAlign: 'right' }}>
                        <Label className={classes.divHeaderTitle}
                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                            {`${Config.lang("ERP_Ma_lot")}:`}
                        </Label>
                        <span className={`${classes.divHeaderItem}`}
                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                            {Config.lang(rowDetail.LotNo)}
                        </span>
                    </div>
                    <GridContainer
                        totalItems={totalPayment}
                        reference={(ref) => (this.paymentGrid = ref)}
                        dataSource={dataPayment}
                        loading={paymentLoaded}
                        showBorders={false}
                        typePaging={"normal"}
                        columnAutoWidth={true}
                        pagerFullScreen={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        allowCellSelection={true}
                        allowColumnResizing={true}
                        rowAlternationEnabled={true}
                        allowNavitionKey={true}
                        onKeyDown={(e) => {
                            Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.paymentGrid,
                                (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                                , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                        }}
                        onCellSelectionChanged={(e) => {
                            if (e.selectedRange) this.selectedRange = e.selectedRange;
                        }}
                        elementAttr={{
                            style: 'min-height: 300px; max-height: 520px',
                            class: `noBGSelect`
                        }}
                        typeShort={window.innerWidth < 768}
                        keyExpr={"PaymentInforID"}
                        editing={{
                            mode: "cell",
                            refreshMode: "reshape",
                            allowUpdating: true,
                            texts: { confirmDeleteMessage: "" },
                            startEditAction: "click",
                        }}
                        // onContentReady={(e) => {
                        //     if (!_.isEmpty(rowValidation)) {
                        //         const checkValidateRowAdded = dataPayment.includes(rowValidation.PaymentInforID);
                        //         if (!checkValidateRowAdded && btnActionStatus) this.setState({ btnActionStatus: false }) // Enable Add and Save Btn
                        //     }
                        // }}
                        onRowValidating={(e) => {
                            if (!e.isValid) { //isValidation
                                if (_.isEmpty(rowValidation)) rowValidation = e.newData;
                            } else { //noValidation
                                rowValidation = {};
                            }
                        }}
                        loadPanel={{ enabled: paymentLoaded }}
                        sorting={{ mode: btnActionStatus ? "none" : "single" }}
                        onRowInserted={(e) => {
                            this.setState({ btnActionStatus: false, btnSaveStatus: false })
                        }}
                        onEditorPreparing={(e) => {
                            if (e.parentType === "dataRow" && dropDownNameList.includes(e.dataField)) {
                                //Hàm này dùng để add title để show tooltip trong dropdown khi ellipsis
                                e.editorOptions.itemTemplate = (itemData, itemIndex, itemElement) => {
                                    const changeIDToName = (e.dataField.slice(0, e.dataField.indexOf("ID"))).concat("Name");
                                    itemElement.innerHTML += itemData[changeIDToName];
                                    itemElement.setAttribute("title", itemData[changeIDToName]);
                                }
                            }
                        }}
                        onInitNewRow={(eMethod) => {
                            eMethod.data.ContractID = ContractID;
                            eMethod.data.QuotationID = QuotationID;
                            eMethod.data.QuotationItemID = QuotationItemID;
                            eMethod.data.keyData = this.countPaymentInforID++;
                            eMethod.data.PaymentInforID = _.toString(eMethod.data.keyData);
                            eMethod.data.PaymentMethodID = rowDetail?.PaymentMethodID;
                            if (getFlexCaptionName.length > 0) {
                                getFlexCaptionName.forEach(fieldName => {
                                    const fieldNameObj = getFlexCaption.find(item => item.FieldName === fieldName);
                                    if (fieldName === fieldNameObj.FieldName) {
                                        eMethod.data[fieldName] = fieldNameObj.DataType === 'D' ?
                                            Config.isEmpty(fieldNameObj.ValueDefaultU) ? null : fieldNameObj.ValueDefaultU : fieldNameObj.ValueDefaultU;
                                    }
                                })
                            }
                        }}
                        onRowUpdated={e => {
                            this.setState({ btnSaveStatus: false });
                        }}
                    >
                        <Column
                            dataField={"keyData"}
                            allowSorting={false}
                            sortOrder={"desc"}
                            visible={false}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("ERP_Hanh_dong")}
                            fixed={true}
                            allowEditing={false}
                            alignment={"center"}
                            visible={Config.isMobile}
                            cellRender={(e) => this.renderBtnMoreAction(e, "mobile")}
                        />
                        <Column
                            headerCellRender={(header) =>
                            (<> {Config.lang("ERP_Ten_thong_tin_thanh_toan")}
                                <span style={{ color: 'red' }}>&nbsp;*</span>
                            </>)}
                            dataField={"PaymentName"}
                            width={220}>
                            <RequiredRule />
                        </Column>
                        <Column
                            headerCellRender={(header) =>
                            (<> {Config.lang("ERP_Phuong_thuc_thanh_toan")}
                                <span style={{ color: 'red' }}>&nbsp;*</span>
                            </>)}
                            dataField={"PaymentMethodID"}
                            lookup={{
                                dataSource: getCboPaymentMethod,
                                valueExpr: "PaymentMethodID",
                                displayExpr: "PaymentMethodName",
                            }}
                            width={220}
                            editorOptions={{
                                placeholder: Config.lang("ERP_Chon") + "...",
                            }}>
                            <RequiredRule />
                        </Column>
                        {getFlexCaption.length > 0 && this.renderPaymentColumn()}
                        <Column
                            alignment={"right"}
                            fixed={true}
                            allowEditing={false}
                            fixedPosition={"right"}
                            visible={!Config.isMobile}
                            cellRender={(e) => this.renderBtnMoreAction(e, "desktop")}
                        />
                    </GridContainer>
                    {dataPayment.length > 0 && this.showListAction()}
                    <IconButton
                        aria-label={"add"}
                        size={"small"}
                        style={{ transform: 'translateY(4px)', margin: '10px 0' }}
                        disabled={btnActionStatus}
                        onClick={() => this.onAddNewRow()}
                    >
                        <Icons name={"plus_filled"} />
                    </IconButton>
                </Modal.Content>
            </Modal >
        );
    }
}

W05F0003PaymentModal.propsTypes = {
    onOpenPaymentModal: PropTypes.bool,
    master: PropTypes.object,
    rowDetail: PropTypes.object,
    onClosePaymentModal: PropTypes.func,
};

export default compose(connect(state => ({
    getFlexCaption: state.W05F0003.getFlexCaption,
    getFlexCaptionName: state.W05F0003.getFlexCaptionName,
    getDecimalQuantity: state.W05F0003.getDecimalQuantity,
    getCboPaymentMethod: state.W05F0003.getCboPaymentMethod,
}),
    (dispatch) => ({
        w05f0003Actions: bindActionCreators(w05f0003Actions, dispatch)
    })
),
    withStyles(styles)
)(W05F0003PaymentModal);
