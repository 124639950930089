import { FormLabel as Label, Tooltip, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Column, RequiredRule } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from 'moment';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as w05f0004Actions from "../../../../redux/W0X/W05F0004/W05F0004_actions";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import GridActionbar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import PopoverGrid from "../../../grid-container/popover-grid";

const styles = {
  divHeaderTitle: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "0.85rem",
    textTransform: "uppercase",
    color: "#8F9BB3",
  },
  divHeaderItem: {
    marginLeft: "8px",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "16px",
  },
  divFlexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
    marginBottom: 15,
  },
  divAddIcon: {
    padding: 0,
    width: 30,
    height: 30,
  },
  divIconItem: {
    padding: 0,
    width: 30,
    height: 30,
    marginRight: 4,
    marginLeft: 4,
  },
  numberFormatInput: {
    backgroundColor: 'transparent',
    border: 'none',
    margin: 0
  }
};

class W05F0004PaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPayment: 0,
      paymentLoaded: false,
      btnActionStatus: false,
      currentRowSelected: {},
      defaultPaymentMethodData: {},
      dataPayment: [],
      paymentPopupAction: null,
      actionRow: null,
      enableSaveBtn: false,
      decimalPayment: {},
    };
    this.filterPayment = {
      // skip: 0,
      // limit: 10,
      PaymentInforID: "",
      ContractID: "",
      QuotationID: "",
      QuotationItemID: ""
    };
    this.paymentGrid = null;
    this.dataPaymentOld = [];
    this.deletedRows = [];
    this.countPaymentInforID = 0;
    this.selectedRange = null;
  }

  componentDidMount = () => {
    const { getCboPaymentMenthod, getFlexCaption } = this.props;
    if (_.isEmpty(getCboPaymentMenthod)) this.getPaymentMenthod();
    const { rowDetail, master } = this.props;
    const { ContractID, QuotationID } = master;
    const { QuotationItemID } = rowDetail;
    if (!_.isEmpty(rowDetail) || !_.isEmpty(master)) {
      this.filterPayment.ContractID = ContractID;
      this.filterPayment.QuotationID = QuotationID;
      this.filterPayment.QuotationItemID = QuotationItemID;
      this.loadPayments();
      this.loadDecimalPayment();
      if (_.isEmpty(getFlexCaption)) this.getFlexCaption();
    }
  }

  loadDecimalPayment = async () => {
    const { CurrencyID } = this.props.master;
    const param = { CurrencyID };
    await this.props.w05f0004Actions.getDecimalPayment(param, (error, data) => {
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show('INFO', message);
        return false;
      }
      if (data) {
        this.setState({ decimalPayment: data })
      }
    })
  };


  numberDecimalFormat = (number) => {
    const { decimalPayment } = this.state;
    if (_.isEmpty(number) && _.isString(number)) return number;
    const { OriginalDecimal = 0 } = decimalPayment;
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: OriginalDecimal,
      maximumFractionDigits: OriginalDecimal,
    });
    const numberFormatted = formatter.format(String(number));
    return !numberFormatted || numberFormatted === "NaN" ? 0 : numberFormatted;
  };

  loadPayments = () => {
    const { PaymentInforID, ContractID, QuotationID, QuotationItemID } = this.filterPayment;
    const params = {
      ContractID,
      QuotationID,
      QuotationItemID,
      PaymentInforID,
    };
    if (this.state.paymentLoaded === false) this.setState({ paymentLoaded: true })
    this.props.w05f0004Actions.getPayment(params, async (error, data) => {
      this.setState({ paymentLoaded: false });
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show('INFO', message);
        return false;
      }
      if (data) {
        // const { rows, total } = data;
        this.countPaymentInforID = data.length;
        data.forEach((item, i) => {
          item['keyData'] = this.countPaymentInforID - (i + 1);
        });
        let formatDateTimePayment = data.map(dataPayment => {
          Object.keys(dataPayment).forEach(key => {
            if (!_.isNumber(dataPayment[key]) && moment(dataPayment[key], moment.ISO_8601, true).isValid()) {
              dataPayment[key] = moment(dataPayment[key]).format('YYYY-MM-DD')
            }
          });
          return dataPayment;
        });
        this.setState({
          dataPayment: formatDateTimePayment,
          totalPayment: data.length
        }, () => {
          this.dataPaymentOld = this.state.dataPayment.map(item => ({ ...item }));
        })
      }
    });
  };

  getPaymentMenthod = () => {
    this.props.w05f0004Actions.getComboPaymentMethod(async (error) => {
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show('INFO', message);
        return false;
      }
    });
  };

  getPaymentMethodDefault = async (ContractID, cb) => {
    const { master } = this.props;
    if (_.isEmpty(master)) return;
    const params = {
      ContractID
    };
    await this.props.w05f0004Actions.getPaymentMethodDefault(params, cb)
  };

  getFlexCaption = () => {
    const GroupInfo = "V";
    const InfoID = "W05V";
    const params = {
      GroupInfo,
      InfoID,
      Language: Config.language || "84",
    };
    this.setState({ paymentLoaded: true })
    this.props.w05f0004Actions.getFlexCaption(params, async (error) => {
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show('INFO', message);
        return false;
      }
      this.setState({ paymentLoaded: false })
    });
  };

  onSaveHistory = async (newData) => {
    const { rowDetail, master, getFlexCaption } = this.props;
    const { QuotationID } = master;
    const { LotNo } = rowDetail;
    const oldData = this.dataPaymentOld;
    const captions = {
      PaymentName: "ERP_Ten_thong_tin_thanh_toan",
      PaymentMethodID: "ERP_Phuong_thuc_thanh_toan",
    };
    const options = {
      codeID: QuotationID,
      keyExpr: "PaymentInforID",
      formID: "W05F0004",
      excludeFields: ["keyData"],
      linkedTrans: "",
      itemRender: (e, type) => {
        const { mode, data, captions, dataField } = e;
        let caption84 = captions[dataField];
        let caption01 = captions[dataField];
        if (mode === "addGrid") {
          if (type === "description84") return Config.lang("ERP_Thong_tin_thanh_toan_cua_lot", "vi") + " " + LotNo;
          if (type === "description01") return Config.lang("ERP_Thong_tin_thanh_toan_cua_lot", "en") + " " + LotNo;
          if (type === "newValue") return data.PaymentName;
        }
        if (mode === "editGrid") {
          if (_.isUndefined(captions[dataField])) {
            const flexCaptionField = getFlexCaption.find(item => item.FieldName === dataField);
            caption84 = flexCaptionField.Caption84;
            caption01 = flexCaptionField.Caption01;
          }
          if (type === "description84") return Config.lang(caption84, "vi") + " " + Config.lang("ERP_Thong_tin_thanh_toan", "vi") + " " + data.PaymentMethodName + ' của LOT ' + LotNo;
          if (type === "description01") return Config.lang(caption01, "en") + " " + Config.lang("ERP_Thong_tin_thanh_toan", "en") + " " + data.PaymentMethodName + ' of LOT ' + LotNo;
        }
        if (mode === "removeGrid") {
          if (type === "description84") return Config.lang("ERP_Thong_tin_thanh_toan_cua_lot", "vi") + " " + LotNo;
          if (type === "description01") return Config.lang("ERP_Thong_tin_thanh_toan_cua_lot", "en") + " " + LotNo;
          if (type === "oldValue") return data.PaymentName;
        }
      }
    };
    let data = await Config.createSaveHistory(newData, oldData, captions, options);
    return data;
  }

  formatDateTimeData = (dataList) => {
    dataList.forEach(data => {
      // if (data.hasOwnProperty('keyData')) delete data.keyData;
      Object.keys(data).forEach(key => {
        if (data[key] && !_.isNumber(data[key]) && moment(data[key], moment.ISO_8601, true).isValid()) {
          data[key] = moment(data[key]).format('YYYY-MM-DD')
        }
      });
    });
    return dataList;
  }

  onSavePayment = async () => {
    if (this.paymentGrid) {
      await this.paymentGrid.instance.saveEditData();
      await new Promise(resolve => setTimeout(resolve, 0));
    }
    let dataPaymentsOder = [];
    const { dataPayment } = this.state;
    const { ContractID, QuotationID, QuotationItemID } = this.filterPayment;
    const dataPaymentSource = this.paymentGrid.instance.option("dataSource");
    const dataPayments = dataPaymentSource ? dataPaymentSource : dataPayment;
    let dataPaymentAddNew = dataPayments.filter((obj) => !this.dataPaymentOld.some((obj2) => obj.PaymentInforID === obj2.PaymentInforID));
    const newestPaymentData = dataPaymentAddNew.reverse();
    dataPaymentsOder = !_.isEmpty(newestPaymentData) ? [...new Set(newestPaymentData.concat(dataPayments))] : dataPayments;
    const finalDataPayments = this.formatDateTimeData(dataPaymentsOder);
    const params = {
      ContractID,
      QuotationItemID,
      QuotationID,
      payments: JSON.stringify(finalDataPayments)
    };
    await this.props.w05f0004Actions.saveDataPayments(params, async (error, data) => {
      if (this.state.enableSaveBtn) this.setState({ enableSaveBtn: false });
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show('INFO', message);
        return false;
      }
      if (data === "successful") {
        const historyStatus = await this.onSaveHistory(finalDataPayments);
        if (historyStatus !== 200) {
          Config.popup.show("INFO", Config.lang("ERP_Co_loi_khi_luu"));
          return false;
        } else {
          Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
          // if (this.props.onClosePaymentModal) this.props.onClosePaymentModal("openPaymentModal");
        }
        this.dataPaymentOld = finalDataPayments.map(item => ({ ...item }));
      }
    });
  };

  // onChangePage = (page) => {
  //   this.filterPayment.skip = page * this.filterPayment.limit;
  //   this.loadPayments();
  // };

  // onChangePerPage = (per) => {
  //   this.filterPayment.skip = 0;
  //   this.filterPayment.limit = per;
  //   this.loadPayments();
  // };

  renderPaymentColumn = () => {
    const { getFlexCaption, classes } = this.props;
    if (getFlexCaption.length < 1) return;
    const supColumn = getFlexCaption.map((item, i) => {
      const { Caption } = item;
      const width = Caption.length > 20 ? 345 : 150;
      let { FieldName } = item;
      let fieldType = "";
      let formatDate = "";
      let alignment = "left";
      const limitFieldName = FieldName.slice(0, parseInt("-" + (FieldName.length - 3).toString()));
      switch (limitFieldName) {
        case "Num":
          fieldType = "number";
          alignment = "right";
          break;
        case "Dat":
          fieldType = "date";
          alignment = "center";
          formatDate = "dd/MM/yyyy";
          break;
        case "Str":
          fieldType = "string";
          break;
        default:
          break;
      }
      return (
        <Column
          key={i}
          caption={Config.lang(Caption)}
          dataField={FieldName}
          dataType={fieldType}
          format={formatDate}
          width={width}
          alignment={alignment}
          // cellRender={(e) => this.cellRender(e, fieldType)}
          {...(fieldType === 'number' ? {
            cellRender: (e) => (
              <NumberFormat decimalScale={this.state?.decimalPayment?.OriginalDecimal || 0} value={_.toNumber(e.value)} displayType={'text'} thousandSeparator={true} />
            ),
            editCellRender: (e) => {
              const value = this.numberDecimalFormat(e.value);
              return (
                <NumberFormat
                  style={{ width: '100%' }}
                  className={classes.numberFormatInput}
                  thousandSeparator={true}
                  isNumericString={true}
                  value={value}
                  onValueChange={(c) => e.setValue(c.floatValue || c.value)}
                />
              )
            }
          } :
            fieldType === "date" ? {
              editorOptions: {
                placeholder: "DD/MM/YYYY",
                dateSerializationFormat: 'yyyy-MM-dd'
              }
            } : {})
          }
        />
      )
    });
    return supColumn;
  };

  cellRender = (e, fieldType) => {
    const { data } = e.row;
    const { column } = e;
    if (!data || !column || !column.dataField) return;
    if (fieldType === 'number') {
      const decimalValue = this.numberDecimalFormat(e.value)
      return <NumberFormat value={decimalValue} displayType={'text'} thousandSeparator={true} />
    }
    let tranFormField = data[column.dataField];
    if (!_.isEmpty(tranFormField) || _.isNumber(tranFormField)) {
      data[column.dataField] = data && (tranFormField || tranFormField === 0) ? tranFormField : "";
    }
    const limitFieldName = (column.dataField).slice(0, parseInt("-" + ((column.dataField).length - 3).toString()));
    const parseDateTime = !_.isEmpty(tranFormField) ? moment.utc(tranFormField).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
    if (limitFieldName === "Dat" && !_.isNull(tranFormField) && !_.isNumber(tranFormField)) {
      tranFormField = !_.isUndefined(tranFormField) ? parseDateTime : moment();
    }
    return tranFormField;
  };

  handleClick = (event, data, stateName, e) => {
    const target = event.currentTarget;
    setTimeout(() => {
      this.setState({ [stateName]: target, currentRowSelected: data && data, actionRow: e });
    }, 0);
  };

  handleClose = (stateName) => {
    this.setState({ [stateName]: null });
  };

  renderPaymentMoreAction = (e) => {
    const { data } = e.row;
    const { classes } = this.props;
    const { btnActionStatus } = this.state;
    const { paymentPopupAction } = this.state;

    return (
      <IconButton
        aria-describedby={data.PaymentInforID}
        size={'small'}
        aria-label="view"
        className={classes.divIconItem}
        onClick={async (a) => {
          if (this.paymentGrid.instance.hasEditData()) await this.paymentGrid.instance.saveEditData();
          this.handleClick(a, data, "paymentPopupAction", e)
        }}
        disabled={!(paymentPopupAction === null) || btnActionStatus}
      >
        <MoreVertIcon />
      </IconButton>
    );
  };

  onCopyPayment = async (dataSelected) => {
    const { dataPayment } = this.state;
    const dataSelectedCopy = { ...dataSelected };
    dataSelectedCopy.keyData = this.countPaymentInforID++;
    dataSelectedCopy.PaymentInforID = _.toString(dataSelectedCopy.keyData);
    dataPayment.unshift(dataSelectedCopy);
    if (!this.state.enableSaveBtn) this.setState({ enableSaveBtn: true })
  };

  showListAction = () => {
    const { classes } = this.props;
    const { currentRowSelected, paymentPopupAction, paymentLoaded, dataPayment, actionRow } = this.state;
    return (
      <>
        {dataPayment.length > 0 && <PopoverGrid
          id={currentRowSelected.QuotationItemID}
          anchorEl={paymentPopupAction}
          onClose={() => this.handleClose("paymentPopupAction")}
          anchorOrigin={{
            vertical: 'center',
            horizontal: Config.isMobile ? 'right' : 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: Config.isMobile ? 'left' : 'right',
          }}
          itemProps={
            <div style={{ padding: 4 }}>
              {/* <Tooltip title={Config.lang("ERP_Sua")}>
                <span>
                  <IconButton
                    disabled={paymentLoaded}
                    aria-label="edit"
                    className={classes.divIconItem}
                    onClick={async () => {
                      this.setState({ paymentPopupAction: null, btnActionStatus: true }, () => {
                        setTimeout(() => {
                          if (actionRow) actionRow.component.editRow(actionRow.rowIndex);
                        }, 300);
                      });
                    }}
                  >
                    <Icons name={"edit"} />
                  </IconButton>
                </span>
              </Tooltip> */}
              <Tooltip title={Config.lang("ERP_Sao_chep")}>
                <span>
                  <IconButton
                    disabled={paymentLoaded}
                    aria-label="copy"
                    className={classes.divIconItem}
                    onClick={() => {
                      this.handleClose("paymentPopupAction");
                      if (!_.isEmpty(actionRow.data)) {
                        this.onCopyPayment(actionRow.data);
                      }
                    }}
                  >
                    <Icons name={"copy"} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={Config.lang("ERP_Xoa")}>
                <span>
                  <IconButton
                    disabled={paymentLoaded}
                    aria-label="delete"
                    className={classes.divIconItem}
                    onClick={() => {
                      Config.popup.show("YES_NO", Config.lang("ERP_Ban_co_chac_chan_muon_xoa_thong_tin_thanh_toan_nay_khong"), () => {
                        this.setState({ paymentPopupAction: null }, () => {
                          if (_.isEmpty(actionRow)) return;
                          const { PaymentInforID } = actionRow.data;
                          const delIndex = this.dataPaymentOld.findIndex(item => item.PaymentInforID === PaymentInforID);
                          if (delIndex > -1) {
                            this.deletedRows.push(PaymentInforID);
                          }
                          setTimeout(() => {
                            actionRow.component.deleteRow(actionRow.rowIndex);
                          }, 300);
                        });
                      });
                    }}
                  >
                    <Icons name={"delete"} />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          }
        />}
      </>
    )
  };

  onAddNewRow = async () => {
    // const { master } = this.props;
    // const { defaultPaymentMethodData } = this.state;
    // const { ContractID } = master;
    if (this.paymentGrid) {
      this.setState({ btnActionStatus: true }, () => {
        this.paymentGrid.instance.addRow()
      });
      // if (_.isEmpty(defaultPaymentMethodData)) {
      //   await this.getPaymentMethodDefault(ContractID, (error, data) => {
      //     this.setState({ defaultPaymentMethodData: data, btnActionStatus: true }, () => {
      //       setTimeout(() => { this.paymentGrid.instance.addRow() }, 300);
      //     });
      //   });
      // }
      // else if (!_.isEmpty(defaultPaymentMethodData)) {
      //   this.setState({ btnActionStatus: true }, () => {
      //     setTimeout(() => { this.paymentGrid.instance.addRow() }, 300);
      //   });
      // }
    }
  };
  renderBtnMoreAction = (e) => {
    return (
      <GridActionbar>
        {this.renderPaymentMoreAction(e)}
      </GridActionbar>
    );
  };
  render() {
    const { dataPayment, totalPayment, paymentLoaded, btnActionStatus, enableSaveBtn } = this.state;
    const { openPaymentModal, onClosePaymentModal, rowDetail, classes, getCboPaymentMenthod, getFlexCaption, getFlexCaptionName } = this.props;
    const { ContractID, QuotationID, QuotationItemID } = this.filterPayment;
    let rowValidation = {};
    return (
      <Modal
        open={openPaymentModal}
        maxWidth={"lg"}
        fullWidth={true}
        position={"center"}
      >
        <Modal.Title disableTypography>
          <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%", margin: '4px 0' }}>
            <div className={"display_row align-center"}>
              <Typography variant="h6" className={"mgr10 text-uppercase"}>{Config.lang("ERP_Thong_tin_thanh_toan")}</Typography>
            </div>
            <div>
              <IconButton
                disabled={paymentLoaded || btnActionStatus || !enableSaveBtn}
                aria-label="save"
                onClick={() => {
                  if (this.state.btnActionStatus) this.setState({ btnActionStatus: false })
                  this.onSavePayment();
                }}
                size={"small"}
                className={"mgr10"}
              >
                <Icons name={"save_filled"} />
              </IconButton>
              <IconButton
                aria-label="close"
                onClick={() => onClosePaymentModal(null)}
                size={"small"}
              >
                <Icons name={"cancel_filled"} />
              </IconButton>
            </div>
          </div>
        </Modal.Title>
        <Modal.Content>
          <Row className={classes.divFlexEnd}>
            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
              <Label className={classes.divHeaderTitle}>
                {Config.lang("ERP_Ma_lot") + ":"}
              </Label>
              <Label className={classes.divHeaderItem}>
                {Config.lang((rowDetail && rowDetail.LotNo) || "")}
              </Label>
            </Col>
          </Row>
          <GridContainer
            totalItems={totalPayment}
            reference={(ref) => (this.paymentGrid = ref)}
            dataSource={dataPayment}
            loading={paymentLoaded}
            typePaging={"normal"}
            showBorders={false}
            columnAutoWidth={true}
            pagerFullScreen={false}
            showColumnLines={false}
            hoverStateEnabled={true}
            allowColumnResizing={true}
            typeShort={window.innerWidth < 768}
            keyExpr={"PaymentInforID"}
            elementAttr={{
              style: "min-height: 300px; max-height: 520px",
              class: 'noBGSelect'
            }}
            editing={{
              mode: 'cell',
              refreshMode: "reshape",
              allowUpdating: true,
              texts: { confirmDeleteMessage: "", },
              startEditAction: "click",
            }}
            loadPanel={{
              enabled: paymentLoaded
            }}
            sorting={{
              mode: btnActionStatus ? "none" : "single"
            }}
            onKeyDown={(e) => {
              Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.paymentGrid,
                (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
            }}
            onCellSelectionChanged={(e) => {
              if (e.selectedRange) this.selectedRange = e.selectedRange;
            }}
            onInitNewRow={(eMethod) => {
              eMethod.data.ContractID = ContractID;
              eMethod.data.QuotationID = QuotationID;
              eMethod.data.QuotationItemID = QuotationItemID;
              eMethod.data.keyData = this.countPaymentInforID++;
              eMethod.data.PaymentInforID = _.toString(eMethod.data.keyData);
              eMethod.data.PaymentMethodID = rowDetail?.PaymentMethodID;
              if (getFlexCaptionName.length > 0) {
                getFlexCaptionName.forEach(fieldName => {
                  const fieldNameObj = getFlexCaption.find(item => item.FieldName === fieldName);
                  if (fieldName === fieldNameObj.FieldName) {
                    eMethod.data[fieldName] = fieldNameObj.DataType === 'D' ?
                      Config.isEmpty(fieldNameObj.ValueDefaultU) ? null : fieldNameObj.ValueDefaultU : fieldNameObj.ValueDefaultU;
                  }
                })
              }
            }}
            allowCellSelection={true}
            onRowRemoved={e => {
              if (!this.state.enableSaveBtn) this.setState({ enableSaveBtn: true })
            }}
            onRowUpdated={(e) => {
              if (!this.state.enableSaveBtn) this.setState({ enableSaveBtn: true })
              if (this.state.btnActionStatus) this.setState({ btnActionStatus: false });
            }}
            onRowInserted={(e) => {
              if (!this.state.enableSaveBtn) this.setState({ enableSaveBtn: true })
              const { btnActionStatus } = this.state;
              if (btnActionStatus) this.setState({ btnActionStatus: false });
            }}
            // onContentReady={(e) => {
            //   if (!_.isEmpty(rowValidation)) {
            //     const checkValidateRowAdded = dataPayment.includes(rowValidation.PaymentInforID);
            //     if (!checkValidateRowAdded && btnActionStatus) this.setState({ btnActionStatus: false }) // Enable Add and Save Btn
            //   }
            // }}
            onRowValidating={(e) => {
              if (!e.isValid) { //isValidation
                if (_.isEmpty(rowValidation)) rowValidation = e.newData;
              } else { //noValidation
                rowValidation = {};
              }
            }}
          >
            <Column
              dataField={"keyData"}
              sortOrder={"desc"}
              visible={false}
            />
            <Column
              caption={Config.lang("ERP_Hanh_dong")}
              fixed={true}
              allowEditing={false}
              alignment={"center"}
              visible={Config.isMobile}
              cellRender={(e) => this.renderBtnMoreAction(e, 'mobile')}
            />
            <Column
              caption={Config.lang("ERP_Ten_thong_tin_thanh_toan")}
              dataField={"PaymentName"}
              width={250}>
              <RequiredRule />
            </Column>
            <Column
              caption={Config.lang("ERP_Phuong_thuc_thanh_toan")}
              dataField={"PaymentMethodID"}
              lookup={{
                dataSource: getCboPaymentMenthod,
                valueExpr: "PaymentMethodID",
                displayExpr: "PaymentMethodName",
              }}
              width={300}
              editorOptions={{
                placeholder: Config.lang("ERP_Chon") + "...",
              }}>
              <RequiredRule />
            </Column>
            <Column
              alignment={"right"}
              fixed={true}
              allowEditing={false}
              fixedPosition={"right"}
              visible={!Config.isMobile}
              cellRender={(e) => this.renderBtnMoreAction(e, 'desktop')}
            />
            {getFlexCaption.length > 0 && this.renderPaymentColumn()}
          </GridContainer>
          {this.showListAction()}
          <IconButton
            aria-label="add"
            size={"small"}
            style={{ transform: 'translateY(6px)' }}
            disabled={btnActionStatus}
            onClick={() => this.onAddNewRow()}
          >
            <Icons name={"plus_filled"} />
          </IconButton>
        </Modal.Content>
      </Modal>
    );
  }
}

W05F0004PaymentModal.propsTypes = {
  openPaymentModal: PropTypes.bool,
  master: PropTypes.object,
  rowDetail: PropTypes.object,
  onClosePaymentModal: PropTypes.func,
};

export default compose(connect(state => ({
  getFlexCaption: state.W05F0004.getFlexCaption,
  getFlexCaptionName: state.W05F0004.getFlexCaptionName,
  getCboPaymentMenthod: state.W05F0004.getCboPaymentMenthod,
}),
  (dispatch) => ({
    w05f0004Actions: bindActionCreators(w05f0004Actions, dispatch)
  })
),
  withStyles(styles)
)(W05F0004PaymentModal);
