import { FormLabel as Label, Tab, Tabs, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import ReorderIcon from '@material-ui/icons/Reorder';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as w05f0003Actions from "../../../../redux/W0X/W05F0003/W05F0003_actions";
import { Combo } from "../../../common/form-material";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import SelectIDNameModal from '../../../common/selectIDNameModal/SelectIDNameModal';
import TabContent, { TabPanel } from "../../../common/tabs/tab-content";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";

const styles = {
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
    },
    divHeaderTitle: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '0.85rem',
        textTransform: 'uppercase',
        color: "#8F9BB3",
    },
    divHeaderItem: {
        marginLeft: 8,
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: 16,
    },
    dateBoxGrid: {
        padding: '0 !important',
        maxHeight: '36px !important',
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    fumiMethod: {
        height: 180, //Chiều cao cố định 3 row
    },
    btnAddFumi: {
        marginTop: 12
    },
    requiredCaption: {
        color: 'red',
        fontSize: 15
    }
}

class W05F0003FumiModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: 0,
            btnSaveStatus: true,
            disabledAddFumi: false,
            showCompanySelect: false,
            loadFumi: false,
            dataDetail: [],
            dataMethod: [],
            cboFumigationMethod: [],
            selectedRow: [],
        };
        this.filterCboFumigationCompany = {
            O08ID: "30",
            strSearch: "",
            skip: 0,
            limit: 20,
        };
        this.filterFumigation = {
            Language: Config.language || "84",
            ContractID: "",
            QuotationID: "",
            QuotationItemID: ""
        };
        this.key = -1;
        this.GridDetail = null;
        this.GridMethod = null;
        this.selectedRowIndx = null;
        this.btnModalColumn = null;
        this.selectedRange = {};
        this.oldDataMethod = [];
        this.oldDataDetail = [];
    }

    componentDidMount = async () => {
        const { master, rowDetail } = this.props;
        if (_.isEmpty(rowDetail) || _.isEmpty(master)) return;
        const { QuotationItemID } = rowDetail;
        const { ContractID, QuotationID } = master;
        this.filterFumigation.ContractID = ContractID;
        this.filterFumigation.QuotationID = QuotationID;
        this.filterFumigation.QuotationItemID = QuotationItemID;
        await this.loadFumigation();
    }

    loadFumigation = () => {
        const { ContractID, QuotationID, QuotationItemID, Language } = this.filterFumigation;
        const params = {
            Language,
            ContractID,
            QuotationID,
            QuotationItemID
        };
        this.setState({ loadFumi: true });
        this.props.w05f0003Actions.getFumigationEdit(params, (error, data) => {
            this.setState({ loadFumi: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.loadCboFumigationMethod(data); // Có Data thì Load Combo
                if (!_.isEmpty(data?.method) || !_.isEmpty(data?.detail)) {
                    this.setState({
                        dataMethod: data?.method,
                        dataDetail: data?.detail.map((dt) => {
                            this.key++;
                            dt.key = this.key;
                            return dt;
                        }),
                    }, () => {
                        this.oldDataDetail = this.state.dataDetail.map((rs) => ({ ...rs }));
                        this.oldDataMethod = this.state.dataMethod.map((rs) => ({ ...rs }));
                    });
                }
            }
        });
    };

    loadCboFumigationMethod = (dataFumi) => {
        const { getCboFumigationMethod } = this.props;
        if (_.isEmpty(getCboFumigationMethod)) { // Chưa có data gọi Api load 
            const { Language } = this.filterFumigation;
            const params = { Language };
            this.props.w05f0003Actions.getCboFumigationMethod(params, (error, data) => {
                if (error) {
                    let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (data) {
                    this.setState({
                        disabledAddFumi: dataFumi.method.length >= data.length,
                        cboFumigationMethod: data
                    });
                }
            });
        } else { // Đã có Data chỉ cần setState
            this.setState({
                disabledAddFumi: dataFumi.method.length >= getCboFumigationMethod.length,
                cboFumigationMethod: getCboFumigationMethod
            })
        }
    };

    renderDetail = () => {
        const { dataDetail } = this.state;
        const { loadFumi, classes } = this.props;
        return (
            <GridContainer
                dataSource={dataDetail}
                loading={loadFumi}
                typePaging={"remote"}
                showBorders={false}
                pagerFullScreen={false}
                showColumnLines={false}
                hoverStateEnabled={true}
                allowCellSelection={true}
                onRowClick={(e) => this.selectedRowIndx = e.rowIndex}
                showRowLines={false}
                reference={(ref) => (this.GridDetail = ref)}
                elementAttr={{ class: `noBGSelect` }}
                editing={{
                    mode: "cell",
                    refreshMode: "reshape",
                    allowUpdating: true,
                    texts: { confirmDeleteMessage: "" },
                    startEditAction: "click",
                }}
                onKeyDown={(e) => {
                    Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.GridDetail,
                        (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                        , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                }}
                onCellSelectionChanged={(e) => {
                    if (e.selectedRange) this.selectedRange = e.selectedRange;
                }}
                onRowUpdating={(e) => {
                    if (this.state.btnSaveStatus) this.setState({ btnSaveStatus: false })
                }}
            >
                <Column
                    caption={Config.lang("ERP_Phuong_thuc")}
                    dataField={"FumigationTypeName"}
                    alignment={"left"}
                    allowEditing={false}
                    width={250}
                />
                <Column
                    caption={Config.lang("ERP_Lan")}
                    dataField={"OrderNo"}
                    alignment={"center"}
                    allowEditing={false}
                    width={120}
                />
                <Column dataField={"FumigationCompanyName"} visible={false} />
                <Column
                    caption={Config.lang("ERP_Cong_ty_phun")}
                    dataField={"FumigationCompany"}
                    alignment={"left"}
                    width={250}
                    allowEditing={false}
                    cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                />
                <Column
                    caption={Config.lang("ERP_Ngay_phun")}
                    dataField={"FumigationDate"}
                    alignment={"center"}
                    dataType={"date"}
                    format={"dd/MM/yyyy"}
                    width={130}
                    cssClass={classes.dateBoxGrid}
                />
                <Column
                    caption={Config.lang("Ghi_chu")}
                    dataField={"Notes"}
                    width={250}
                />
            </GridContainer>
        );
    };

    renderMethod = () => {
        const { classes, rowDetail, getCboFumigationMethod } = this.props;
        const { loadFumi, dataMethod, disabledAddFumi, cboFumigationMethod } = this.state;
        const { ContractID, QuotationID, QuotationItemID } = this.filterFumigation;
        const dataSourceMethod = this.GridMethod ? this.GridMethod.instance.option("dataSource") : dataMethod;
        const oldDataMethod = this.oldDataMethod;
        return (
            <>
                <GridContainer
                    dataSource={dataMethod}
                    reference={(ref) => (this.GridMethod = ref)}
                    loading={loadFumi}
                    typePaging={"remote"}
                    showBorders={false}
                    showRowLines={false}
                    columnAutoWidth={true}
                    pagerFullScreen={false}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    allowCellSelection={true}
                    editing={{
                        mode: "cell",
                        refreshMode: "reshape",
                        allowUpdating: true,
                        texts: { confirmDeleteMessage: "" },
                        startEditAction: "click",
                    }}
                    onKeyDown={(e) => {
                        Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.GridMethod,
                            (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                            , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                    }}
                    onCellSelectionChanged={(e) => {
                        if (e.selectedRange) this.selectedRange = e.selectedRange;
                    }}
                    elementAttr={{ class: `${classes.fumiMethod} noBGSelect` }}
                    onInitNewRow={(eMethod) => {
                        eMethod.data.ContractID = ContractID;
                        eMethod.data.QuotationID = QuotationID;
                        eMethod.data.QuotationItemID = QuotationItemID;
                        eMethod.data.LotNo = rowDetail.LotNo || "";
                        eMethod.data.FumigationType = "";
                        eMethod.data.FumigationTypeName = "";
                        eMethod.data.Quantity = 1;
                        eMethod.data.IsNew = true;
                        eMethod.data.Notes = "";
                    }}
                    onRowInserted={(e) => {
                        const { data } = e;
                        const dataSource = this.GridMethod.instance.option("dataSource");
                        const disabledAddFumi = dataSource.length >= this.props.getCboFumigationMethod.length;
                        const FumigationTypeName = data.FumigationTypeName = cboFumigationMethod.find(e => e.FumigationType === data.FumigationType);
                        e.data.FumigationTypeName = FumigationTypeName && FumigationTypeName.FumigationTypeName;
                        // const dataFilter = cboFumigationMethod.filter(rs => rs.FumigationType !== e.data.FumigationType)
                        this.setState({ disabledAddFumi, btnSaveStatus: false });
                        this.createDetail();
                    }}
                    onRowUpdated={(e) => {
                        const dataSource = this.GridMethod.instance.option("dataSource");
                        const disabledAddFumi = dataSource.length >= this.props.getCboFumigationMethod.length;
                        if (this.state.disabledAddFumi !== disabledAddFumi) this.setState({ disabledAddFumi });
                        if (this.state.btnSaveStatus) this.setState({ btnSaveStatus: false })
                        oldDataMethod.map((rs) => {
                            const dataNew = dataSourceMethod.find((d) => d.FumigationType === rs.FumigationType);
                            if (dataNew && dataNew.Quantity < rs.Quantity) {
                                Config.popup.show(
                                    "YES_NO",
                                    Config.lang("ERP_Ban_co_muon_tiep_tuc_sua"),
                                    () => this.createDetail(),
                                    () => this.loadFumigation()
                                );
                            }
                            return dataNew;
                        });
                        this.createDetail();
                    }}
                    onRowRemoving={(e) => {
                        if (this.state.btnSaveStatus) this.setState({ btnSaveStatus: false })
                    }}
                    onEditingStart={e => {
                        if (e.data.FumigationType !== "" && e.column.dataField === "FumigationType") e.cancel = true;
                    }}
                >
                    <Column
                        caption={Config.lang("ERP_Hanh_dong")}
                        alignment={"center"}
                        allowEditing={false}
                        visible={Config.isMobile}
                        cellRender={(e) => this.renderRemoveFumigation(e, "mobile")}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_LOT")}
                        dataField={"LotNo"}
                        alignment={"left"}
                        width={400}
                        allowEditing={false}
                    />
                    <Column dataField={"FumigationTypeName"} visible={false} />
                    <Column
                        dataField={"FumigationType"}
                        alignment={"left"}
                        cssClass={classes.dateBoxGrid}
                        headerCellRender={e => (<label>{Config.lang("ERP_Phuong_thuc")} <span className={classes.requiredCaption}>*</span></label>)}
                        width={280}
                        cellRender={(e) => (<Label> {e.data.FumigationTypeName || e.displayValue} </Label>)}
                        editCellRender={(e) => {
                            const uniqueFumigationMethodCbo = cboFumigationMethod.filter(({ FumigationType: value1 }) => !dataSourceMethod.some(({ FumigationType: value2 }) => value1 === value2));
                            const selectedItem = cboFumigationMethod.filter((c) => c.FumigationType === e.value);
                            const value = selectedItem.length > 0 ? selectedItem[0] : null;
                            return (
                                <Combo
                                    dataSource={uniqueFumigationMethodCbo}
                                    displayExpr={"FumigationTypeName"}
                                    stylingMode={"underlined"}
                                    noBackGroundColor={true}
                                    margin={"dense"}
                                    cssClass={classes.dateBoxGrid}
                                    className={"no-margin"}
                                    shrink={true}
                                    placeholder={Config.lang("ERP_Chon")}
                                    value={value}
                                    itemRender={rs => (
                                        <span title={rs.FumigationTypeName} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block', overflow: 'hidden' }}>{rs.FumigationTypeName}</span>
                                    )}
                                    onValueChanged={(rs) => {
                                        e.setValue(rs.value && (rs.value.FumigationType || ""));
                                        e.component.cellValue(e.rowIndex, "FumigationTypeName", rs.value && (rs.value.FumigationTypeName || ""));
                                    }}
                                />
                            );
                        }}
                    >
                    </Column>
                    <Column
                        caption={Config.lang("So_lan_khu_trung")}
                        dataField={"Quantity"}
                        alignment={"right"}
                        editorOptions={{ min: 1, max: 10 }}
                        dataType="number"
                        width={150}
                        cssClass={classes.dateBoxGrid}
                    />
                    <Column
                        fixed={true}
                        alignment={"right"}
                        allowEditing={false}
                        width={60}
                        fixedPosition={"right"}
                        visible={!Config.isMobile}
                        cellRender={(e) => this.renderRemoveFumigation(e, 'desktop')}
                    />
                </GridContainer>
                {
                    dataMethod.length < getCboFumigationMethod.length && <IconButton
                        aria-label={"add"}
                        size={"small"}
                        className={classes.btnAddFumi}
                        disabled={disabledAddFumi}
                        onClick={this.onAddRowFumi}
                    >
                        <Icons name={"plus_filled"} />
                    </IconButton>
                }
            </>
        );
    };

    renderIconAction = (e) => {
        const { classes } = this.props;
        return (
            <IconButton
                aria-label={"view"}
                className={classes.divIconItem}
                onClick={() => this.setState({ disabledAddFumi: false, btnSaveStatus: false }, () => this.onDeleteRowFumi(e))}
            >
                <Icons name={"delete"} />
            </IconButton>
        );
    };

    renderRemoveFumigation = (e, mode) => {
        return (
            mode === "mobile" ?
                this.renderIconAction(e)
                : <GridActionBar>
                    {this.renderIconAction(e)}
                </GridActionBar>
        );
    }

    onDeleteRowFumi = async (e) => {
        Config.popup.show("YES_NO", Config.lang("ERP_Ban_co_chac_muon_xoa?"),
            async () => {
                await e.component.deleteRow(e.rowIndex);
                await this.createDetail();
                await this.GridDetail && this.GridDetail.instance.saveEditData();
                this.setState({ disabledAddFumi: false, btnSaveStatus: false });
            }
        );
    }

    onAddRowFumi = () => {
        if (this.GridMethod) {
            this.GridMethod.instance.saveEditData();
            const dataSourceMethod = this.GridMethod.instance.option("dataSource");
            const dataFilter = dataSourceMethod.filter(rs => rs.FumigationType === "");
            if (dataFilter.length > 0) {
                Config.popup.show("INFO", Config.lang("ERP_Phuong_thuc_khong_duoc_de_trong"));
                return false;
            } else {
                this.setState({ disabledAddFumi: true }, () => {
                    setTimeout(() => { this.GridMethod.instance.addRow() }, 300);
                })
            };
        }
    }

    detailObject = (dataDetail) => {
        if (dataDetail) {
            let arr = [];
            dataDetail.map((item) => {
                let res = {
                    ContractID: item.ContractID,
                    FumigationCompany: item.FumigationCompany,
                    FumigationCompanyName: item.FumigationCompanyName,
                    FumigationDate: item.FumigationDate
                        ? moment(item.FumigationDate).format("MM/DD/YYYY")
                        : null,
                    FumigationType: item.FumigationType,
                    FumigationTypeName: item.FumigationTypeName,
                    LotNo: item.LotNo,
                    OrderNo: item.OrderNo,
                    QuotationID: item.QuotationID,
                    QuotationItemID: item.QuotationItemID,
                    Notes: item.Notes,
                };
                return arr.push(res);
            });
            return arr;
        }
        return [];
    };

    methodObject = (dataMethod) => {
        if (dataMethod) {
            let arr = [];
            dataMethod.map((item) => {
                let res = {
                    ContractID: item.ContractID,
                    FumigationType: item.FumigationType,
                    FumigationTypeName: item.FumigationTypeName,
                    LotNo: item.LotNo,
                    Quantity: item.Quantity,
                    QuotationID: item.QuotationID,
                    QuotationItemID: item.QuotationItemID,
                };
                return arr.push(res);
            });
            return arr;
        }
        return [];
    };

    createDetail = async () => {
        if (this.GridDetail && this.GridDetail) {
            await this.GridMethod.instance.option("dataSource");
            await this.GridDetail.instance.option("dataSource");
            const dataSourceMethod = this.GridMethod
                ? this.GridMethod.instance.option("dataSource")
                : this.state.dataMethod;
            const dataSourceDetail = this.GridDetail
                ? this.GridDetail.instance.option("dataSource")
                : this.state.dataDetail;
            let newDataSourceDetail = [];
            if (dataSourceMethod) {
                dataSourceMethod.map((item) => {
                    const dataDetail_FumiType = dataSourceDetail.filter(detail => detail.FumigationType === item.FumigationType);
                    let count = 1;
                    if (item.Quantity !== dataDetail_FumiType.length) {
                        for (let i = 0; i < item.Quantity; i++) {
                            if (dataDetail_FumiType[i]) {
                                dataDetail_FumiType[i].Quantity = count;
                                newDataSourceDetail.push(dataDetail_FumiType[i]);
                            } else {
                                newDataSourceDetail.push({
                                    ContractID: item.ContractID ? item.ContractID : "",
                                    FumigationCompany: "",
                                    FumigationCompanyName: "",
                                    FumigationDate: item.FumigationDate || null,
                                    FumigationType: item.FumigationType || "",
                                    FumigationTypeName: item.FumigationTypeName || "",
                                    LotNo: item.LotNo || "",
                                    OrderNo: count,
                                    QuotationID: item.QuotationID || "",
                                    QuotationItemID: item.QuotationItemID || "",
                                    IsNew: true,
                                });
                            }
                            count++;
                        }
                    } else {
                        newDataSourceDetail = newDataSourceDetail.concat(dataDetail_FumiType);
                    }
                    return newDataSourceDetail;
                });
                newDataSourceDetail.map((item) => {
                    if (item.IsNew) {
                        item.key = this.key;
                        this.key++;
                    }
                    return item;
                });
                this.setState({ dataDetail: newDataSourceDetail });
            }
        }
    };

    handleChangeTab = (event, value) => {
        if (this.GridDetail && this.GridDetail) {
            this.GridMethod.instance.saveEditData();
            this.GridDetail.instance.saveEditData();
        }
        if (value === 1) {
            const dataSourceMethod =
                this.GridMethod && this.GridMethod.instance.option("dataSource");
            const dataFilter = dataSourceMethod.filter(rs => rs.FumigationType === '');
            if (dataFilter.length > 0) {
                Config.popup.show("INFO", Config.lang("ERP_Phuong_thuc_khong_duoc_de_trong"));
                return null;
            }
            this.createDetail();
        }
        this.setState({ indexTab: value });
    };

    onSaveFumi = async (master) => {
        await this.createDetail();
        const { ContractID, QuotationID, QuotationItemID } = this.filterFumigation;
        if (this.GridDetail && this.GridDetail) {
            await this.GridMethod.instance.saveEditData();
            await this.GridDetail.instance.saveEditData();
        }
        const dataSourceMethod = this.GridMethod
            ? this.GridMethod.instance.option("dataSource")
            : this.state.dataMethod;
        const dataSourceDetail = this.GridDetail
            ? this.GridDetail.instance.option("dataSource")
            : this.state.dataDetail;
        // Kiem tra FumigationType !== ""
        const dataFilter = await dataSourceMethod.filter(rs => rs.FumigationType === "");
        if (dataFilter.length > 0) {
            Config.popup.show("INFO", Config.lang("ERP_Phuong_thuc_khong_duoc_de_trong"));
            return false;
        }
        const params = {
            ContractID,
            QuotationID,
            QuotationItemID,
            method: JSON.stringify(this.methodObject(dataSourceMethod)),
            detail: JSON.stringify(this.detailObject(dataSourceDetail)),
        };
        this.setState({ loadFumi: true })
        this.props.w05f0003Actions.saveFumigation(params, async (error, data) => {
            if (error) {
                this.setState({ loadFumi: false })
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data === "successful") {
                this.setState({ loadFumi: false, btnSaveStatus: true })
                const historyStatus = await this.onSaveHistoryFumi(
                    dataSourceMethod,
                    dataSourceDetail,
                    master
                );
                if (historyStatus !== 200) {
                    Config.popup.show("INFO", Config.lang("ERP_Co_loi_khi_luu"));
                    return false;
                } else {
                    Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
                }
            }
        });
    };

    getNumberByOrderNo = (orderNo) => {
        orderNo = _.toNumber(orderNo);
        let stt = "";
        switch (true) {
            case orderNo === 1:
                stt = "st"
                break;
            case orderNo === 2:
                stt = "nd"
                break;
            case orderNo === 3:
                stt = "rd"
                break;
            case orderNo > 4:
                stt = "th"
                break;
            default:
                break;
        }
        return { stt }
    }

    onSaveHistoryFumi = async (newDataSourceMethod, newDataSourceDetail) => {
        let el = {};
        let data = [];
        let status = 200;
        const oldDataMethod = this.oldDataMethod;
        const oldDataDetail = this.oldDataDetail;
        //get "Field Name"
        const keyMethod = Object.keys(
            oldDataMethod[0] ? oldDataMethod[0] : newDataSourceMethod[0] || []
        );
        const keyDetail = Object.keys(
            oldDataDetail[0] ? oldDataDetail[0] : newDataSourceDetail[0] || []
        );

        if (oldDataMethod.length > 0) {
            //DataSource Method
            //Them moi phuong thuc
            newDataSourceMethod.forEach((item) => {
                if (item.IsNew) {
                    let el = {};
                    el.codeID = item.ContractID;
                    el.formID = "W05F0003";
                    el.linkedTrans = "";
                    el.newValue = `${item.FumigationTypeName} - ${Config.lang('ERP_So_luong')}: ${item.Quantity}`;
                    el.hasURL = false;
                    el.description84 = `Phương thức phun trùng của Lot ${item.LotNo}`;
                    el.description01 = `Fumigation Type of Lot ${item.LotNo}`;
                    el.action = 0;
                    data.push(el);
                }
            });
            //Xoa phuong thuc
            let newData = newDataSourceMethod.map((item) => item.FumigationType);
            oldDataMethod.forEach((dataCu) => {
                if (newData.indexOf(dataCu.FumigationType) < 0) {
                    let el = {};
                    // console.log("neu moi nho hon cu", dataCu);
                    el.codeID = dataCu.ContractID;
                    el.formID = "W05F0003";
                    el.linkedTrans = "";
                    el.oldValue = `${dataCu.FumigationTypeName} - ${Config.lang('ERP_So_luong')}: ${dataCu.Quantity} `;
                    el.hasURL = false;
                    el.description84 = `Phương thức phun trùng của Lot ${dataCu.LotNo}`;
                    el.description01 = `Fumigation Type of Lot ${dataCu.LotNo}`;
                    el.action = 3;
                    data.push(el);
                }
            });
            //Sua phuong thuc
            newDataSourceMethod.forEach((dataMoi) => {
                oldDataMethod.forEach((dataCu) => {
                    keyMethod.map((fieldName) => {
                        if (
                            dataMoi[fieldName] !== dataCu[fieldName] &&
                            dataMoi.FumigationType === dataCu.FumigationType &&
                            dataMoi.IsNew === undefined
                        ) {
                            let el = {};
                            el.codeID = dataMoi.ContractID;
                            el.formID = "W05F0003";
                            el.linkedTrans = "";
                            el.oldValue = dataCu["Quantity"];
                            el.newValue = dataMoi["Quantity"];
                            el.hasURL = false;
                            el.description84 = `Số lượng - ${dataMoi.FumigationTypeName} của Lot ${dataMoi.LotNo}`;
                            el.description01 = `Quantity - ${dataMoi.FumigationTypeName} of Lot ${dataMoi.LotNo}`;
                            el.action = 1;
                            return data.push(el);
                        }
                        return fieldName;
                    });
                });
            });
            //dataSource Detail
            const dataKeyCu = oldDataDetail.map((d) => d.key);
            const dataKeyMoi = newDataSourceDetail.map((d) => d.key);
            const added = newDataSourceDetail.filter((d) => {
                return dataKeyCu.indexOf(d.key) < 0;
            });
            const edited = newDataSourceDetail.filter((d) => {
                return dataKeyCu.indexOf(d.key) > -1;
            });
            const deleted = oldDataDetail.filter((d) => {
                return dataKeyMoi.indexOf(d.key) < 0;
            });
            //Them chi tiet
            if (added.length > 0) {
                added.forEach((item) => {
                    if (item.IsNew) {
                        let el = {};
                        el.codeID = item.ContractID;
                        el.formID = "W05F0003";
                        el.linkedTrans = "";
                        el.newValue = `${item.FumigationTypeName} - ${Config.lang('ERP_Lan')} ${item.OrderNo}`;
                        el.hasURL = false;
                        el.description84 = `Chi tiết phun trùng của Lot ${item.LotNo}`;
                        el.description01 = `Detail Fumigation of Lot ${item.LotNo}`;
                        el.action = 0;
                        data.push(el);
                    }
                });
            }
            //Sua chi tiet
            if (edited.length > 0) {
                edited.forEach((dataMoi) => {
                    oldDataDetail.forEach((dataCu) => {
                        keyDetail.map((fieldName) => {
                            if (
                                dataMoi[fieldName] !== dataCu[fieldName] &&
                                dataMoi.key === dataCu.key &&
                                dataMoi.FumigationType === dataCu.FumigationType &&
                                fieldName !== "FumigationCompany"
                            ) {
                                const { stt } = this.getNumberByOrderNo(dataMoi.OrderNo);
                                const de = this.getLang(fieldName);
                                let el = {};
                                let newValue = '';
                                let oldValue = '';
                                switch (fieldName) {
                                    case "FumigationDate":
                                        newValue = dataMoi["FumigationDate"] !== null ? moment(dataMoi["FumigationDate"]).format('DD/MM/YYYY') : '';
                                        oldValue = dataCu["FumigationDate"] !== null ? moment(dataCu["FumigationDate"]).format('DD/MM/YYYY') : '';
                                        break;
                                    default:
                                        newValue = dataMoi[fieldName];
                                        oldValue = dataCu[fieldName];
                                        break;
                                }
                                el.codeID = dataMoi.ContractID;
                                el.formID = "W05F0003";
                                el.linkedTrans = "";
                                el.oldValue = oldValue;
                                el.newValue = newValue;
                                el.hasURL = false;
                                el.description84 = `${Config.lang(de, "vi")} ${dataMoi.FumigationTypeName} - ${Config.lang("ERP_Lan", "vi")} ${dataMoi.OrderNo} của Lot ${dataMoi.LotNo}`;
                                el.description01 = `${Config.lang(de, "en")} ${dataMoi.FumigationTypeName} - ${Config.lang("ERP_Lan", "en")} ${dataMoi.OrderNo} ${stt} of Lot ${dataMoi.LotNo}`;
                                el.action = 1;
                                return data.push(el);
                            }
                            return fieldName;
                        });
                    });
                });
            }
            //Xoa chi tiet
            if (deleted.length > 0) {
                deleted.forEach((dataMoi) => {
                    let el = {};
                    el.codeID = dataMoi.ContractID;
                    el.formID = "W05F0003";
                    el.linkedTrans = "";
                    el.oldValue = `${dataMoi.FumigationTypeName} - ${Config.lang("ERP_Lan")} ${dataMoi.OrderNo}`;
                    el.hasURL = false;
                    el.description84 = `Chi tiết phun Trùng của Lot ${dataMoi.LotNo}`;
                    el.description01 = `Detail Fumigation of Lot ${dataMoi.LotNo}`;
                    el.action = 3;
                    data.push(el);
                });
            }
        } else {
            //Them phuong thuc khi du lieu rong
            newDataSourceMethod.forEach((dataMoi) => {
                if (dataMoi.IsNew) {
                    // console.log("data rong thi moi thuc hien", dataMoi);
                    el.codeID = dataMoi.ContractID;
                    el.formID = "W05F0003";
                    el.linkedTrans = "";
                    el.newValue = `Lot ${dataMoi.LotNo} ${dataMoi.FumigationTypeName}`;
                    el.hasURL = false;
                    el.description84 = `Phương thức phun trùng`;
                    el.description01 = `Fumigation Type`;
                    el.action = 0;
                    data.push(el);
                }
            });
            newDataSourceDetail.forEach((dataMoi) => {
                if (dataMoi.IsNew) {
                    // console.log("data rong thi moi thuc hien", dataMoi);
                    let el = {};
                    el.codeID = dataMoi.ContractID;
                    el.formID = "W05F0003";
                    el.linkedTrans = "";
                    el.newValue = `Lot ${dataMoi.LotNo} ${dataMoi.FumigationTypeName} ${dataMoi.FumigationCompanyName} ${dataMoi.FumigationDate}`;
                    el.hasURL = false;
                    el.description84 = `Chi tiết`;
                    el.description01 = `Fumigation Details`;
                    el.action = 0;
                    data.push(el);
                }
            });
        }
        if (data.length > 0) {
            const params = {
                attributes: JSON.stringify(data),
            };
            await this.props.w05f0003Actions.addHistory(params, (error) => {
                this.setState({ loadFumi: false })
                status = error ? 400 : 200;
                if (status === 200) {
                    //Set lại Old data để không lưu lịch sử nữa 
                    this.oldDataDetail = this.state.dataDetail.map((rs) => ({ ...rs }));
                    this.oldDataMethod = this.state.dataMethod.map((rs) => ({ ...rs }));
                }
            });
        }
        return status;
    };

    getLang(fieldName) {
        let listField = {
            FumigationCompany: "ERP_Cong_ty_phun",
            FumigationCompanyName: "ERP_Cong_ty_phun",
            FumigationDate: "ERP_Ngay_phun",
            Notes: "Ghi_chu",
        };
        return listField[fieldName];
    }

    showCompanyModal = (data) => {
        const selectedRow = data ? data.replace(/,/g, ';').split(';') : [];
        this.setState({ selectedRow, showCompanySelect: true });
    };

    cellRenderPopupAction = (cellData) => {
        const dataField = cellData.column.dataField,
            rowData = cellData.data;
        return (
            <div className={"pull-left mgr5"} style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton ref={ref => this.btnModalColumn = ref} className={`btn_popup_modal`} size={'small'} style={{ display: `${this.props.isView ? 'none' : 'block'}` }} onClick={() => this.showCompanyModal(rowData[dataField])}>
                    <ReorderIcon fontSize={"small"} />
                </IconButton>
                <div className={"pull-left"} title={rowData.FumigationCompanyName}>{rowData.FumigationCompanyName}</div>
            </div>
        );
    };


    onSelectedPopup = (data, field) => {
        this.filterCboFumigationCompany.limit = 20;
        this.filterCboFumigationCompany.skip = 0;
        if (data.length > 0) {
            this.GridDetail.instance.cellValue(this.selectedRowIndx, field, data[0].ObjectID);
            this.GridDetail.instance.cellValue(this.selectedRowIndx, "FumigationCompanyName", data[0].ObjectNameU);
            this.GridDetail.instance.saveEditData();
        }
        this.closeCompanySelect();
    };

    closeCompanySelect = () => {
        if (this.state.showCompanySelect) this.setState({ showCompanySelect: false });
    };

    render() {
        const { master, modalOpen, onCloseModal, rowDetail, classes } = this.props;
        const { indexTab, showCompanySelect, selectedRow, btnSaveStatus } = this.state;
        return (
            <>
                {showCompanySelect && <SelectIDNameModal
                    mode={'single'}
                    maxWidth={'sm'}
                    modalType={'Company'}
                    selectedRow={selectedRow}
                    openModal={showCompanySelect}
                    selectedRowKeys={(e) => this.setState({ selectedRow: e })}
                    onChosen={(data) => this.onSelectedPopup(data, "FumigationCompany")}
                    onClose={this.closeCompanySelect}
                    listPerPage={[15, 30, 45, 60]}
                />}
                <Modal
                    open={modalOpen}
                    maxWidth={"md"}
                    fullWidth={true}
                    position={"center"}
                >
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%", margin: '4px 0' }}>
                            <div className={"display_row align-center"}>
                                <Typography variant="h6" className={"mgr10"}>{Config.lang("ERP_Phun_trung")}</Typography>
                            </div>
                            <div>
                                <IconButton
                                    disabled={btnSaveStatus}
                                    aria-label={"save"}
                                    onClick={() => this.onSaveFumi(master)}
                                    size={"small"}
                                    className={"mgr10"}
                                >
                                    <Icons name={"save_filled"} />
                                </IconButton>
                                <IconButton
                                    aria-label={"close"}
                                    onClick={onCloseModal}
                                    size={"small"}
                                >
                                    <Icons name={"cancel_filled"} />
                                </IconButton>
                            </div>
                        </div>
                    </Modal.Title>
                    <Modal.Content>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <Tabs
                                    indicatorColor={"primary"}
                                    textColor={"primary"}
                                    value={indexTab}
                                    onChange={this.handleChangeTab}
                                >
                                    <Tab value={0} label={Config.lang("ERP_Phuong_thuc")} />
                                    <Tab value={1} label={Config.lang("ERP_Chi_tiet")} />
                                </Tabs>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {indexTab === 1 &&
                                    <div className={classes.ellipsis} {...(Config.isMobile ? { style: { marginBottom: 15, marginTop: 15 } } : { style: { marginTop: 10 } })}>
                                        <Label className={classes.divHeaderTitle}>
                                            {`${Config.lang("ERP_Ma_lot")}:`}
                                        </Label>
                                        <span className={`${classes.divHeaderItem}`}>
                                            {`${rowDetail && rowDetail.LotNo}`}
                                        </span>
                                    </div>
                                }
                            </Col>
                        </Row>
                        <TabContent
                            activeKey={indexTab}
                            disableSwipe={true}
                            lazyLoading={false}
                        >
                            <TabPanel index={0}>{this.renderMethod()}</TabPanel>
                            <TabPanel index={1}>{this.renderDetail()}</TabPanel>
                        </TabContent>
                    </Modal.Content>
                </Modal>
            </>
        );
    }
}

W05F0003FumiModal.propsTypes = {
    modalOpen: PropTypes.bool,
    master: PropTypes.object,
    rowDetail: PropTypes.object,
    onCloseModal: PropTypes.func,
};

export default compose(
    connect((state) => ({
        getCboFumigationMethod: state.W05F0003.getCboFumigationMethod,
    }), (dispatch) => ({
        w05f0003Actions: bindActionCreators(w05f0003Actions, dispatch),
    })), withStyles(styles))(W05F0003FumiModal);

