import { FormLabel as Label, Tooltip } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as w05f0004Actions from "../../../../redux/W0X/W05F0004/W05F0004_actions";
import ButtonGeneral from "../../../common/button/button-general";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";

const styles = {
  divIcon: {
    right: "50px",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    width: "120px",
    height: "36px",
    padding: 0,
    zIndex: 999,
    position: "absolute",
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
    listStyle: "none",
    marginTop: "-17px",
    borderRadius: "4px",
  },
  divIconItem: {
    padding: 0,
    width: 30,
    height: 30,
    marginRight: 4,
  },
  divAddIcon: {
    padding: 0,
    width: 30,
    height: 30,
    margin: "0 0 0 32px",
  },
  columnActions: {
    height: "20px !important",
  },
  divHeaderTitle: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "0.85rem",
    textTransform: "uppercase",
    color: "#8F9BB3",
  },
  divHeaderItem: {
    marginLeft: "8px",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "16px",
  },
  divFlexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
    marginBottom: 15,
  },
  lotCode: {
    display: 'block',
    textAlign: 'right',
    marginBottom: 15
  },
  checkBoxGrid: {
    // backgroundColor: 'transparent !important'
  },
  cellPadding: {
    '& .dx-datagrid-content': {
      '& tr': {
        '& td': {
          paddingTop: '8px !important',
          paddingBottom: '8px !important',
        }
      }
    }
  }
};

class W05F0004VoucherModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalReceipt: 0,
      dataReceipt: [],
      isCompleteVoucher: false,
      disabledSave: false,
    };
    // this.filterReceipt = {
    //   skip: 0,
    //   limit: 10,
    // }
    this.GridReceipt = null;
    this.oldData = [];
    this.checkedData = [];
  }

  componentDidMount = () => {
    this.loadReceipts(true);
  }

  loadReceipts = (eReset) => {
    if (this.state.receiptsLoaded === false) this.setState({ receiptsLoaded: true })
    const { rowDetail } = this.props;
    if (_.isEmpty(rowDetail)) return;
    const { QuotationItemID } = rowDetail;
    // const { skip, limit } = this.filterReceipt
    const params = {
      QuotationItemID: QuotationItemID || "",
      Language: Config.language || "84",
    };
    this.props.w05f0004Actions.loadReceipt(params, (error, data) => {
      this.setState({ receiptsLoaded: false })
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show('INFO', message);
        return false;
      }
      if (data.length > 0) {
        const dataReceipt = data.map(data => { return ({ ...data }) });
        // const totalReceipt = data.total;
        // if (this.checkedData.length > 0) {
        //   const dataPaymentAddNew = dataReceipt.filter((obj) => this.checkedData.some((obj2) => obj.VoucherTypeID === obj2.VoucherTypeID));
        //   if (dataPaymentAddNew.length > 0) {
        //     dataReceipt.map(obj => {
        //       const newData = this.checkedData.find(item => item.VoucherTypeID === obj.VoucherTypeID);
        //       if (!_.isEmpty(newData)) {
        //         const foundIndex = dataReceipt.findIndex(x => x.VoucherTypeID === newData.VoucherTypeID);
        //         dataReceipt[foundIndex] = newData;
        //       }
        //       return obj;
        //     })
        //   }
        // }
        this.setState({
          dataReceipt: dataReceipt,
          totalReceipt: dataReceipt.length,
          isCompleteVoucher: data[0].IsCompleteVoucher === 1,
        }, () => {
          this.oldData = this.state.dataReceipt.map(item => ({ ...item }));
        })
      }
    });
  };

  onChangePage = (page) => {
    this.filterReceipt.skip = page * this.filterReceipt.limit;
    // this.loadReceipts();
  };

  onChangePerPage = (per) => {
    this.filterReceipt.skip = 0;
    this.filterReceipt.limit = per;
    // this.loadReceipts();
  };

  saveHistoryReceipt = async (newData, saveReceiptStatus) => {
    let params = {};
    let status = 0;
    const oldData = this.oldData;
    const { LotNo } = this.props.rowDetail;
    const captions = {
      //master
      IsUse: "ERP_Su_dung",
      IsComplete: "ERP_Hoan_thanh"
    };
    const options = {
      codeID: newData[0].QuotationID,
      keyExpr: "VoucherTypeID",
      formID: "W05F0004",
      mode: "edit",
      itemRender: (e, type) => {
        const { mode, data, captions, dataField, oldData } = e;
        if (mode === "editGrid") {
          const description84 = dataField !== 'VoucherTypeName' ? Config.lang(captions[dataField], "vi") + ' chứng từ ' + data.VoucherTypeName :
            Config.lang('Ten_dieu_chinh_chung_tu_cua_lot', 'vi') + ' ' + LotNo;
          const description01 = dataField !== 'VoucherTypeName' ? Config.lang(captions[dataField], "en") + ' Lot ' + data.VoucherTypeName :
            Config.lang('Ten_dieu_chinh_chung_tu_cua_lot', 'en') + ' ' + LotNo;
          if (type === "description84") return description84;
          if (type === "description01") return description01;
          if (type === "newValue") return dataField !== 'VoucherTypeName' ? data[dataField] === 0 ? 'UnChecked' : 'Checked' : data[dataField];
          if (type === "oldValue") return dataField !== 'VoucherTypeName' ? oldData[dataField] === 0 ? 'UnChecked' : 'Checked' : oldData[dataField];
        }
      }
    };
    let data = await Config.createSaveHistory(newData, oldData, captions, options, true);
    // const results = await Config.createSaveHistory(newDataGrid, oldDataGrid, captions, {...options, ortherData: data});
    if (data.length > 0) {
      // data.map(rs => {
      //   rs.oldValue = rs.oldValue === 0 ? 'UnChecked' : 'Checked'
      //   rs.newValue = rs.newValue === 0 ? 'UnChecked' : 'Checked'
      //   return rs;
      // });
      if (saveReceiptStatus) {
        //Nếu Save complete có data thì nối chuỗi của save complete luôn
        await this.saveHistoryCompleteReceipt(true).then(dataHistoryComplete => {
          data = data.concat(dataHistoryComplete);
        })
      }
      params = {
        attributes: JSON.stringify(data),
      }
      status = await Config.saveHistory(params);
    }
    return status;
  }

  onSaveChangeVoucher = (noLoadHistory) => {
    if (this.GridReceipt) {
      const dataSource = this.GridReceipt
        ? this.GridReceipt.instance.option("dataSource")
        : this.state.dataReceipt;
      const { QuotationID, ContractID } = this.props.master;
      const { QuotationItemID } = this.props.rowDetail;
      const params = {
        ContractID: ContractID ? ContractID : "",
        QuotationID: QuotationID ? QuotationID : "",
        QuotationItemID: QuotationItemID ? QuotationItemID : "",
        receipts: dataSource.length > 0 ? JSON.stringify(dataSource.filter((item) => (item.IsUse === 1))) : ""
      };
      this.props.w05f0004Actions.saveReceipt(params, async (error, data) => {
        if (error) {
          let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
          Config.popup.show("INFO", message);
          return false;
        }
        if (data) {
          if (noLoadHistory) { // Không cần load save lịch sử
            Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
            this.oldData = dataSource.map(item => ({ ...item }));
            this.loadReceipts();
          } else {
            this.saveHistoryReceipt(dataSource, false).then(status => {
              if (status === 200 || status === 0) {
                Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
                if (this.state.disabledSave) this.setState({ disabledSave: false });
                if (status === 200) this.oldData = dataSource.map(item => ({ ...item }));// ReSet Old Data
              } else {// 400
                Config.notify.show("error", Config.lang("ERP_Luu_lich_su_khong_thanh_cong"), 2000);
              }
            })
          }
        }
      });
    }
  }

  saveHistoryCompleteReceipt = async (historySave) => {
    const { LotNo } = this.props.rowDetail;
    const { QuotationID } = this.props.master;
    let el = {};
    let data = [];
    let status = "";
    el.codeID = QuotationID;
    el.formID = "W05F0004";
    el.linkedTrans = "";
    el.hasURL = false;
    el.description84 = Config.lang("Cập nhật lại trạng thái thông tin chứng từ của") + " Lot " + LotNo;
    el.description01 = Config.lang("Changed the status vourcher of") + " Lot " + LotNo;
    el.action = 2;
    data.push(el);
    if (historySave) {
      status = data;
    } else {
      const params = { attributes: JSON.stringify(data) };
      await this.props.generalActions.saveHistory(params, (error) => {
        status = error ? 400 : 200
      });
    }
    return status;
  }

  onSaveCompleteVoucher = () => {
    if (this.GridReceipt) {
      const { isCompleteVoucher } = this.state;
      const dataSource = this.GridReceipt
        ? this.GridReceipt.instance.option("dataSource")
        : this.state.dataReceipt;
      const { QuotationItemID } = this.props.rowDetail;
      const params = {
        QuotationItemID: QuotationItemID ? QuotationItemID : "",
        IsCompleteVoucher: isCompleteVoucher ? 1 : 0
      };
      this.props.w05f0004Actions.completeReceipt(params, async (error, data) => {
        if (error) {
          let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
          Config.popup.show("INFO", message);
          return false;
        }
        if (data) {
          this.saveHistoryReceipt(dataSource, true).then(async status => { // Kiểm tra 
            if (status === 200) {//Save lịch sử chứng từ + nút hoàn thành và tiếp tục save chứng từ đó 
              await this.onSaveChangeVoucher(true);
            } else { //Chỉ save lịch sử nút hoàn thành
              await this.saveHistoryCompleteReceipt(false).then(status => {
                if (status === 200) {
                  Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
                  this.loadReceipts(); // Reload Data Popup receipt
                } else {
                  Config.notify.show("error", Config.lang("ERP_Luu_lich_su_khong_thanh_cong"), 2000);
                }
              })
            }
          });
        }
      });
    }
  }

  render() {
    const { receiptsLoaded, dataReceipt, totalReceipt, isCompleteVoucher, disabledSave } = this.state;
    const { openVoucherModal, onCloseVoucherModal, classes, rowDetail } = this.props;
    return (
      <Modal
        open={openVoucherModal}
        maxWidth={"sm"}
        fullWidth={true}
        position={"center"}
      >
        <Modal.Title disableTypography>
          <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
            <div className={"display_row align-center"}>
              <Typography variant="h6" className={"mgr10 text-uppercase"}>{Config.lang("ERP_Cap_nhat_chung_tu")}</Typography>
              <Tooltip title={Config.lang("ERP_Hoan_tat")}>
                <span>
                  <ButtonGeneral
                    variant={"outlined"}
                    color={"primary"}
                    aria-label={Config.lang(!isCompleteVoucher ? "ERP_Hoan_tat" : "ERP_Da_hoan_tat")}
                    name={Config.lang(!isCompleteVoucher ? "ERP_Hoan_tat" : "ERP_Da_hoan_tat")}
                    className={classes.divIconItem}
                    style={{ minWidth: "6rem" }}
                    onClick={() => {
                      let message = !isCompleteVoucher ? "ERP_Khi_xac_nhan_hoan_tat_ban_se_khong_duoc_sua" : "ERP_Thong_bao_xac_nhan_chung_tu";
                      Config.popup.show(
                        "YES_NO",
                        Config.lang(message),
                        () => {
                          this.onSaveCompleteVoucher();
                        }
                      );
                    }}
                  />
                </span>
              </Tooltip>
            </div>
            <div>
              <IconButton
                disabled={isCompleteVoucher || !disabledSave}
                aria-label="save"
                size={"small"}
                className={"mgr10"}
                onClick={() => this.onSaveChangeVoucher()}
              >
                <Icons name={"save_filled"} />
              </IconButton>
              <IconButton
                aria-label="close"
                size={"small"}
                onClick={() => {
                  onCloseVoucherModal(null);
                }}
              >
                <Icons name={"cancel_filled"} />
              </IconButton>
            </div>
          </div>

        </Modal.Title>
        <Divider />
        <Modal.Content style={{ marginBottom: 15 }}>
          <div className={classes.lotCode}>
            <Label className={classes.divHeaderTitle}
              {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
              {Config.lang("ERP_Ma_lot") + ":"}
            </Label>
            <Label className={classes.divHeaderItem}>
              {Config.lang(rowDetail.LotNo)}
            </Label>
          </div>
          <div className={classes.cellPadding}>
            <GridContainer
              totalItems={totalReceipt}
              // itemPerPage={this.filterReceipt.limit}
              // skipPerPage={this.filterReceipt.skip}
              dataSource={dataReceipt}
              loading={receiptsLoaded}
              reference={(ref) => (this.GridReceipt = ref)}
              keyExpr={'VoucherTypeID'}
              typePaging={"normal"}
              allowColumnResizing={true}
              hoverStateEnabled={true}
              pagerFullScreen={false}
              columnAutoWidth={true}
              showBorders={false}
              typeShort={window.innerWidth < 768}
              showColumnLines={false}
              elementAttr={{
                style: "max-height: 500px",
                class: 'noBGSelect'
              }}
              editing={{
                mode: 'cell',
                refreshMode: "reshape",
                allowUpdating: true,
                texts: {
                  confirmDeleteMessage: ""
                },
              }}
              onRowUpdated={(e) => {
                if (!disabledSave) setTimeout(() => { this.setState({ disabledSave: true }); }, 600);
              }}
            // onChangePage={this.onChangePage}
            // onChangePerPage={this.onChangePerPage}
            // onRowUpdating={(e) => {
            //   const oldRowData = this.oldData.find(item => item.VoucherTypeID === e.key);
            //   const difference = Object.keys(e.newData).filter(k => oldRowData[k] !== e.newData[k]);
            //   if (difference.length > 0) {
            //     this.checkedData.push(e.oldData);
            //   }
            // }}
            >
              <Column
                caption={Config.lang("ERP_Chung_tu")}
                dataField={'VoucherTypeName'}
                width={200}
              />
              <Column
                caption={Config.lang("ERP_Su_dung")}
                dataField={'IsUse'}
                alignment={'center'}
                width={180}
                allowEditing={false}
                cssClass={classes.checkBoxGrid}
                cellRender={(e) => {
                  const { data } = e;
                  return <Checkbox
                    label={""}
                    checked={!!data.IsUse}
                    color="primary"
                    size={"small"}
                    style={{ padding: "3px" }}
                    disabled={isCompleteVoucher}
                    onChange={(chk) => {
                      if (data.IsComplete === 1) {
                        Config.popup.show("INFO", Config.lang("ERP_Ban_da_check_hoan_tat_chung_tu_nay"));
                      } else {
                        e.component.cellValue(e.rowIndex, "IsUse", chk.target.checked ? 1 : 0);
                        e.component.saveEditData();
                      }
                    }}
                  />
                }}
              />
              <Column
                caption={Config.lang("ERP_Hoan_thanh")}
                dataField={'IsComplete'}
                alignment={'center'}
                width={180}
                allowEditing={false}
                cssClass={classes.checkBoxGrid}
                cellRender={(e) => {
                  const { data } = e;
                  return <Checkbox
                    label={""}
                    checked={!!data.IsComplete}
                    color="primary"
                    style={{ padding: "3px" }}
                    size={"small"}
                    disabled={isCompleteVoucher}
                    onChange={(chk) => {
                      if (isCompleteVoucher) return;
                      if (data.IsUse === 0) {
                        Config.popup.show("INFO", Config.lang("ERP_Ban_phai_check_su_dung_truoc"));
                      } else {
                        e.component.cellValue(e.rowIndex, "IsComplete", chk.target.checked ? 1 : 0);
                        e.component.saveEditData();
                      }
                    }}
                  />
                }}
              />
            </GridContainer>
          </div>
        </Modal.Content>
      </Modal>
    )
  }
}
W05F0004VoucherModal.propsTypes = {
  w05f0004Actions: PropTypes.any,
};

export default compose(
  connect(
    (state) => ({
      getListDeployments: state.W05F0004.getListDeployments,
      getCboStatus: state.W05F0004.getCboStatus,
    }),
    (dispatch) => ({
      generalActions: bindActionCreators(generalActions, dispatch),
      w05f0004Actions: bindActionCreators(w05f0004Actions, dispatch),
    })
  ),
  withStyles(styles)
)(W05F0004VoucherModal);
