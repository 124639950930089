import { Tab, Tabs } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as W05F0003Actions from "../../../../redux/W0X/W05F0003/W05F0003_actions";
import Icons from "../../../common/icons/";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import PopoverAction from "../../../grid-container/popover-action";
const styles = {
    divIcon: {
        right: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        padding: '3px 11px',
        zIndex: 999,
        position: 'absolute',
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        listStyle: 'none',
        marginTop: '-17px',
        borderRadius: 4,
    },
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
        '&:last-child': {
            marginRight: 0
        }
    },
    divIconMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        width: 90,
        padding: 0,
        zIndex: 999,
        position: 'absolute',
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        listStyle: 'none',
        borderRadius: '4px',
    },
    tabTitle: {
        fontSize: 16,
        fontWeight: 500,
        marginRight: 40
    },
    customPadding: {
        '& .dx-datagrid-headers': {
            '& td': {
                paddingTop: '5px !important',
                paddingBottom: '5px !important',
            }
        }
    }
};


class W05F0003TabMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saleContractsLoaded: false,
            mappingBySaleContractLoaded: false,
            totalDataMapping: 0,
            indexSaleContractTab: 1,
            mode: "single",
            btnMappingAction: null,
            currentMappingRow: {},
            selectedRowKeys: [],
            dataSaleContracts: [],
            dataBySaleContracts: []
        };
        this.filterSaleContract = {
            StrSearch: "",
            ContractID: "",
            ContractNo: "",
            VoucherNo: "",
            ObjectID: "",
            LotNo: "",
            InventoryID: "",
            skip: 0,
            limit: 10
        };
        this.filterMappingBySaleContract = {
            skip: 0,
            limit: 10
        };
        this.changePage = false;
        this.isCtrlKey = false;
        this.popoverGrid = null;
        this.dataMappingGrid = null;
        this.tmpSelectedRowData = [];
        this.tmpSelectedRowKeys = [];
    }

    componentDidMount = () => {
        const { dataMaster, permission, contractIDBefore, dataSaleContracts, dataBySaleContracts } = this.props;
        if (dataMaster.ContractID && permission > 0) {
            const { ContractID } = dataMaster;
            this.filterSaleContract.ContractID = dataMaster.ContractID;
            if (ContractID !== contractIDBefore) {
                this.loadListSaleContract(true);
            } else {
                if (_.isEmpty(dataSaleContracts.rows)) return;
                const dataSource = dataSaleContracts.rows ? dataSaleContracts.rows.map((item) => ({ ...item })) : [];
                const currentSelectKey = [dataSource[0].QuotationItemID];
                const selectedRowKeys = _.isString(currentSelectKey) ? currentSelectKey.split(" ") : currentSelectKey;
                this.setState({
                    dataSaleContracts,
                    dataBySaleContracts,
                    selectedRowKeys
                });
            }
        }
    }

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }

    onChangeSaleContractPage = (page) => {
        this.filterSaleContract.skip = page * this.filterSaleContract.limit;
        this.loadListSaleContract();
    };

    onChangePerSaleContractPage = (per) => {
        this.filterSaleContract.skip = 0;
        this.filterSaleContract.limit = per;
        this.loadListSaleContract();
    };

    onChangeBySaleContractPage = (page) => {
        this.filterMappingBySaleContract.skip = page * this.filterMappingBySaleContract.limit;
        this.loadMappingBySaleContract();
    };

    onChangePerBySaleContractPage = (per) => {
        this.filterMappingBySaleContract.skip = 0;
        this.filterMappingBySaleContract.limit = per;
        this.loadMappingBySaleContract();
    };

    loadListSaleContract = (isReset) => {
        const { ContractID = "", skip, limit, StrSearch, ContractNo, VoucherNo, ObjectID, LotNo, InventoryID } = this.filterSaleContract;
        const params = {
            skip,
            limit,
            LotNo,
            ContractID,
            Language: Config.language || "84",
            ContractNo,
            StrSearch,
            VoucherNo,
            ObjectID,
            InventoryID,
        };
        if (!isReset) {
            this.changePage = true;
        }
        this.setState({ saleContractsLoaded: true });
        this.props.w05f0003Actions.loadSaleContracts(params, (error, data) => {
            this.setState({ saleContractsLoaded: false })
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const dataSource = data.rows ? data.rows.map((item) => ({ ...item })) : [];
                const selectKeys = dataSource.map(d => d.QuotationItemID).filter(d => this.tmpSelectedRowKeys.indexOf(d) > -1);
                const currentSelectKey = isReset ? [dataSource[0].QuotationItemID] : selectKeys;
                const selectedRowKeys = _.isString(currentSelectKey) ? currentSelectKey.split(" ") : currentSelectKey;
                this.setState({
                    dataSaleContracts: data,
                    selectedRowKeys
                });
            }
        });
    };

    loadMappingBySaleContract = (data) => {
        if (data.length < 1) {
            this.setState({ dataBySaleContracts: [] });
            return;
        }
        const { skip, limit } = this.filterMappingBySaleContract;
        const values = JSON.stringify(data);
        const params = {
            values,
            skip,
            limit
        };
        this.setState({ mappingBySaleContractLoaded: true, test: false });
        this.props.w05f0003Actions.loadMappingBySaleContract(params, (error, data) => {
            this.setState({ mappingBySaleContractLoaded: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({
                    dataBySaleContracts: data
                });
            }
        });
    };

    handleChangedSaleContractTab = (event, value) => {
        this.setState({ indexSaleContractTab: value });
    };

    handleClick = (e, data) => {
        const target = e.currentTarget;
        this.setState({
            btnMappingAction: e.currentTarget,
            currentMappingRow: data && data
        });
        if (this.popoverGrid) this.popoverGrid.instance.show(target);
    };

    handleClose = (stateName) => {
        this.setState({ [stateName]: null });
    };

    popupActionMapping = (e) => {
        const { currentMappingRow } = this.state;
        const { classes, permission } = this.props;
        return (
            <>
                <PopoverAction
                    reference={ref => this.popoverGrid = ref}
                    position={"right"}
                    maxWidth={300}
                    deferRendering={false}>
                    <div style={{ padding: 4 }}>
                        <Tooltip title={Config.lang("ERP_Xem")}>
                            <IconButton
                                disabled={!(permission > 1)}
                                aria-label={"View"}
                                className={classes.divIconItem}
                                onClick={() => this.onBtnMappingAction("view", currentMappingRow)}
                            >
                                <Icons name={"view"} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Sua")}>
                            <IconButton
                                disabled={!(permission > 2)}
                                aria-label={"Edit"}
                                className={classes.divIconItem}
                                onClick={() => this.onBtnMappingAction("edit", currentMappingRow)}
                            >
                                <Icons name={"edit"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </PopoverAction>
            </>
        );
    };

    onBtnMappingAction = (mode, data) => {
        this.redirectScreen(mode, data);
    }

    redirectScreen = (mode, data = "") => {
        if (!data) return;
        let screen = "";
        let params = {};
        switch (mode) {
            case "view":
                params = {
                    MappingID: data.MappingID,
                    mode: "view"
                }
                screen = "W05F0007";
                break;
            case "edit":
                params = {
                    MappingID: data.MappingID,
                    mode: "edit"
                }
                screen = "W05F0007";
                break;
            default:
                break;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: params
        });
    };

    renderIconAction = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        return (
            <>
                <GridActionBar>
                    <IconButton
                        aria-label={"view"}
                        aria-describedby={data.MappingItemID}
                        className={classes.divIconItem}
                        onClick={(e) => this.handleClick(e, data)}
                    // disabled={!_.isNull(this.state.btnMappingAction)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </GridActionBar>
            </>
        );
    };

    renderBtnMappingAction = (e, mode) => {
        return (
            mode === "mobile" ?
                this.renderIconAction(e)
                : <GridActionBar>
                    {this.renderIconAction(e)}
                </GridActionBar>
        );
    };

    setSelected = (e) => {
        const selectedRowsData = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (currentDeselectedRowKeys.length > 0 && !this.changePage) {
            this.tmpSelectedRowData = this.tmpSelectedRowData.filter((d) => {
                return currentDeselectedRowKeys.indexOf(d.QuotationItemID) < 0;
            });
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((d) => {
                return currentDeselectedRowKeys.indexOf(d) < 0;
            });
        }
        if (selectedRowsData.length > 0) {
            selectedRowsData.forEach((val) => {
                if (this.tmpSelectedRowKeys.indexOf(val.QuotationItemID) < 0) {
                    this.tmpSelectedRowData.push(val);
                    this.tmpSelectedRowKeys.push(val.QuotationItemID);
                }
            });
        }
    };

    onSelectionChanged = (e) => {
        const { mode, selectedRowKeys } = this.state;
        const { currentSelectedRowKeys, currentDeselectedRowKeys } = e;
        if (this.changePage) {
            this.changePage = false;
        } else {
            if (mode === "single") {
                if (
                    currentSelectedRowKeys.length === 0 &&
                    currentDeselectedRowKeys.length > 0
                ) {
                    this.setState({ selectedRowKeys: currentDeselectedRowKeys });
                    return;
                } else {
                    let isNewSelected = false;
                    this.tmpSelectedRowKeys.forEach((item) => {
                        if (e.selectedRowKeys.indexOf(item) < 0) {
                            isNewSelected = true;
                            return false;
                        }
                    });
                    if (this.tmpSelectedRowKeys.length <= 0 || isNewSelected) {
                        if (this.isCtrlKey && isNewSelected) {
                            this.setSelected(e);
                            this.setState({ mode: "multiple" }, () => {
                                this.setState({
                                    selectedRowKeys: selectedRowKeys.concat(this.tmpSelectedRowKeys)
                                }, () => {
                                    let values = this.tmpSelectedRowData.map(
                                        ({ QuotationID, QuotationItemID }) => {
                                            return { QuotationID, QuotationItemID };
                                        }
                                    );
                                    this.loadMappingBySaleContract(values);
                                }
                                );
                            });

                        } else {
                            const { paramBySaleBefore } = this.props;
                            this.tmpSelectedRowKeys = e.selectedRowKeys;
                            this.tmpSelectedRowData = e.selectedRowsData;
                            this.setState({ selectedRowKeys: e.selectedRowKeys }, () => {
                                let values = e.selectedRowsData.map(
                                    ({ QuotationID, QuotationItemID }) => {
                                        return { QuotationID, QuotationItemID };
                                    }
                                );
                                const paramBySaleContractLoaded = values.filter((obj) => !paramBySaleBefore.some((obj2) => obj.QuotationItemID === obj2.QuotationItemID));
                                //Chỉ load 1 lần data By Sale Contract Default
                                if (!_.isEmpty(paramBySaleContractLoaded)) this.loadMappingBySaleContract(values);
                            });
                        }
                    } else {
                        this.setState({ selectedRowKeys: e.selectedRowKeys });
                    }
                }
            } else {
                if (this.isCtrlKey) {
                    this.setSelected(e);
                    this.setState({ selectedRowKeys: e.selectedRowKeys }, () => {
                        let values = this.tmpSelectedRowData.map(
                            ({ QuotationID, QuotationItemID }) => {
                                return { QuotationID, QuotationItemID };
                            }
                        );
                        this.loadMappingBySaleContract(values);
                    });
                } else {
                    this.setSelected(e);
                    this.setState({ selectedRowKeys: e.selectedRowKeys }, () => {
                        let values = this.tmpSelectedRowData.map(
                            ({ QuotationID, QuotationItemID }) => {
                                return { QuotationID, QuotationItemID };
                            }
                        );
                        this.loadMappingBySaleContract(values);
                    });
                }
                if (this.tmpSelectedRowKeys.length < 2) {
                    this.setState({ mode: "single" });
                }
            }
        }
    };

    render() {
        const { classes } = this.props;
        const { indexSaleContractTab, dataSaleContracts, mode, selectedRowKeys,
            mappingBySaleContractLoaded, saleContractsLoaded, dataBySaleContracts } = this.state;
        const visibleMapping = indexSaleContractTab === 1;
        return (
            <>
                <div {...(!Config.isMobile ? { style: { display: 'flex', marginTop: 16 } } : { style: { marginTop: 16 } })}>
                    <span className={classes.tabTitle} style={{ marginTop: 9 }}>{Config.lang("ERP_Hop_dong_ban")}</span>
                    <Tabs
                        indicatorColor={"primary"}
                        textColor={"primary"}
                        value={indexSaleContractTab}
                        onChange={this.handleChangedSaleContractTab}
                    >
                        <Tab value={0} label={Config.lang("ERP_Thong_tin_chung")} />
                        <Tab value={1} label={Config.lang("ERP_Chi_tiet")} />
                    </Tabs>
                </div>
                <div style={{ marginBottom: 24, backgroundColor: '#fff' }}>
                    <GridContainer
                        itemPerPage={this.filterSaleContract.limit}
                        skipPerPage={this.filterSaleContract.skip}
                        totalItems={_.get(dataSaleContracts, "total", 0)}
                        dataSource={_.get(dataSaleContracts, "rows", [])}
                        loading={saleContractsLoaded}
                        keyExpr={"QuotationItemID"}
                        showBorders={false}
                        showRowLines={false}
                        columnAutoWidth={true}
                        showColumnLines={false}
                        pagerFullScreen={false}
                        hoverStateEnabled={true}
                        elementAttr={{ style: 'min-height: 200px' }}
                        selection={{
                            allowSelectAll: false,
                            mode: mode,
                            selectAllMode: "",
                            showCheckBoxesMode: mode === "multiple" ? "always" : "none",
                        }}
                        onCellClick={(e) => {
                            this.isCtrlKey = e.event.ctrlKey;
                            const { mode, selectedRowKeys } = this.state;
                            if (mode === "multiple" && e.rowIndex > -1) {
                                if (e.event.ctrlKey) {
                                    const key = e.component.getKeyByRowIndex(e.rowIndex);
                                    if (selectedRowKeys.indexOf(key) > -1)
                                        e.component.deselectRows([key]);
                                }
                            }
                        }}
                        selectedRowKey={selectedRowKeys}
                        onSelectionChanged={this.onSelectionChanged}
                        onChangePage={this.onChangeSaleContractPage}
                        onChangePerPage={this.onChangePerSaleContractPage}
                    >
                        {/* General Information Mapping Tab */}
                        <Column
                            caption={Config.lang("ERP_So_hop_dong")}
                            dataField={"ContractNo"}
                            width={200}
                            visible={!visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Khach_hang")}
                            dataField={"BuyerName"}
                            width={200}
                            visible={!visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Phuong_tien")}
                            dataField={"Transportation"}
                            width={150}
                            visible={!visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Dot_trien_khai")}
                            dataField={"VoucherNo"}
                            width={150}
                            visible={!visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Thoi_gian_giao_hang_du_kien_cua_dot_trien_khai")}
                            dataField={"ValidTo"}
                            width={360}
                            alignment={"center"}
                            dataType={"date"}
                            cellRender={(e) => {
                                const { data } = e.row;
                                let validDate = null;
                                if (Config.isValidDateTime(data.ValidTo) && Config.isValidDateTime(data.ValidFrom)) {
                                    validDate = `${moment(data.ValidFrom).format("DD/MM/YYYY")} - ${moment(data.ValidTo).format("DD/MM/YYYY")}`;
                                }
                                return validDate;
                            }}
                            visible={!visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_So_luong_tong_cua_dot_trien_khai")}
                            dataField={"TotalQuantity"}
                            width={120}
                            visible={!visibleMapping}
                        />
                        {/* Detail Mapping Tab */}
                        <Column
                            caption={Config.lang("ERP_Ma_lot")}
                            dataField={"LotNo"}
                            width={220}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Ma_hang")}
                            dataField={"InventoryID"}
                            width={180}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Ten_hang")}
                            dataField={"InventoryNameU"}
                            width={150}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_So_luong_lot")}
                            dataField={"TotalQuantity"}
                            width={120}
                            alignment={"right"}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_So_luong_da_mapping")}
                            dataField={"MAPQuantity"}
                            width={180}
                            alignment={"right"}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_So_luong_con_lai")}
                            dataField={"Quantity"}
                            width={150}
                            alignment={"right"}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Quy_cach_dong_goi")}
                            dataField={"PackingMethodName"}
                            width={150}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Loai_bao_dong_goi")}
                            dataField={"PackingTypeName"}
                            width={150}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Nhan_mac")}
                            dataField={"MarkingName"}
                            width={150}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Thong_tin_bao_bi")}
                            dataField={"PackNote"}
                            width={180}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Giam_dinh")}
                            dataField={"EstimateName"}
                            width={150}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Hun_trung")}
                            dataField={"Fumigation"}
                            width={180}
                            visible={visibleMapping}
                        />
                        <Column
                            caption={Config.lang("ERP_Thoi_gian_giao_hang_theo_lot")}
                            dataField={"DeliveryDateTo"}
                            width={260}
                            alignment={"center"}
                            dataType={"date"}
                            cellRender={(e) => {
                                const { data } = e.row;
                                let validDate = null;
                                if (Config.isValidDateTime(data.DeliveryDateTo) && Config.isValidDateTime(data.DeliveryDateFrom)) {
                                    validDate = `${moment(data.DeliveryDateFrom).format("DD/MM/YYYY")} - ${moment(data.DeliveryDateFrom).format("DD/MM/YYYY")}`;
                                }
                                return validDate;
                            }}
                            visible={visibleMapping}
                        />
                    </GridContainer>
                </div>
                {/* Grid Mapping By Sale Contract */}

                <div className={classes.tabTitle} style={{ marginBottom: 15 }}>{Config.lang("ERP_Thong_tin_mapping")}</div>
                <div className={classes.customPadding}>
                    <GridContainer
                        itemPerPage={this.filterMappingBySaleContract.limit}
                        skipPerPage={this.filterMappingBySaleContract.skip}
                        totalItems={_.get(dataBySaleContracts, 'total', 0)}
                        dataSource={_.get(dataBySaleContracts, 'rows', [])}
                        loading={mappingBySaleContractLoaded}
                        typePaging={"normal"}
                        keyExpr={"MappingItemID"}
                        showBorders={false}
                        columnAutoWidth={true}
                        pagerFullScreen={false}
                        hoverStateEnabled={true}
                        allowColumnResizing={true}
                        elementAttr={{ style: 'min-height: 100px' }}
                        onChangePage={this.onChangeBySaleContractPage}
                        onChangePerPage={this.onChangePerBySaleContractPage}
                    >
                        <Column
                            caption={Config.lang("ERP_Hanh_dong")}
                            alignment={"center"}
                            visible={Config.isMobile}
                            cellRender={(e) => this.renderBtnMappingAction(e, "mobile")}
                        />
                        <Column
                            caption={Config.lang("ERP_Ma_mapping")}
                            dataField={"MappingID"}
                            width={150}
                        />
                        <Column
                            caption={Config.lang("ERP_So_mapping")}
                            dataField={"MappingNo"}
                            width={150}
                            alignment={"right"}
                        />
                        <Column
                            caption={Config.lang("ERP_So_hop_dong_mua")}
                            dataField={"ContractNo"}
                            width={180}
                        />
                        <Column
                            caption={Config.lang("ERP_Nha_cung_cap")}
                            dataField={"Supplier"}
                            width={150}
                        />
                        <Column
                            caption={Config.lang("ERP_Ma_lot")}
                            dataField={"LotNo"}
                            width={180}
                        />
                        <Column caption={Config.lang("ERP_Ma_hang")}
                            cssClass={"no-padding"}>
                            <Column
                                caption={Config.lang("ERP_Hop_dong_mua")}
                                dataField={"InventoryIDPO"}
                                width={150}
                                cssClass={"no-padding"}
                            />
                        </Column>
                        <Column caption={Config.lang("ERP_Ten_hang")}>
                            <Column
                                caption={Config.lang("ERP_Hop_dong_mua")}
                                dataField={"InventoryNamePO"}
                                width={150}
                            />
                        </Column>
                        <Column caption={Config.lang("ERP_Ma_hang")}>
                            <Column
                                caption={Config.lang("ERP_Hop_dong_ban")}
                                dataField={"InventoryIDSO"}
                                width={150}
                            /></Column>
                        <Column caption={Config.lang("ERP_Ten_hang")}>
                            <Column
                                caption={Config.lang("ERP_Hop_dong_ban")}
                                dataField={"InventoryNameSO"}
                                width={150}
                            />
                        </Column>
                        <Column caption={Config.lang("ERP_So_luong")}
                            alignment={"right"}>
                            <Column
                                caption={Config.lang("ERP_Bao_yeu_cau")}
                                dataField={"QuantityPack"}
                                width={120}
                                alignment={"right"}
                            />
                        </Column>
                        <Column caption={Config.lang("ERP_So_luong_yeu_cau")}
                            alignment={"right"}>
                            <Column
                                caption={Config.lang("ERP_Mapping")}
                                dataField={"Quantity"}
                                width={180}
                                alignment={"right"}
                            />
                        </Column>
                        <Column caption={Config.lang("ERP_So_luong")}
                            alignment={"right"}
                            cssClass={"no-padding"}>
                            <Column
                                caption={Config.lang("ERP_Thuc_hien_mapping")}
                                dataField={"QuantityActual"}
                                width={200}
                                alignment={"right"}
                                cssClass={"no-padding"}
                            />
                        </Column>
                        <Column
                            caption={Config.lang("ERP_So_luong_con_lai")}
                            dataField={"QuantitRemain"}
                            width={150}
                            alignment={"right"}
                        />
                        <Column caption={Config.lang("ERP_Thoi_gian_dong_hang")} alignment={"center"} >
                            <Column
                                caption={Config.lang("ERP_Bat_dau")}
                                dataField={"StartDate"}
                                alignment={"center"}
                                dataType={"date"}
                                width={150}
                                format={'dd/MM/yyyy'}
                            />
                            <Column
                                caption={Config.lang("ERP_Ket_thuc")}
                                dataField={"EndDate"}
                                alignment={"center"}
                                dataType={"date"}
                                width={150}
                                format={'dd/MM/yyyy'}
                            />
                        </Column>
                        <Column
                            caption={Config.lang("ERP_Don_vi_det_bao")}
                            dataField={"UnitPackID"}
                            width={150}
                        />
                        <Column
                            alignment={"right"}
                            fixed={true}
                            fixedPosition={"right"}
                            visible={!Config.isMobile}
                            cellRender={(e) => this.renderBtnMappingAction(e, "desktop")}
                        />
                        {_.get(dataBySaleContracts, "rows", []).length > 0 && this.popupActionMapping()}
                    </GridContainer>
                </div>

            </>
        );
    }
}

W05F0003TabMapping.propsTypes = {
    permission: PropTypes.number,
    dataMaster: PropTypes.object,
};

export default compose(connect((state) => ({
    contractIDBefore: state.W05F0003.contractIDBefore,
    dataSaleContracts: state.W05F0003.dataSaleContracts,
    paramBySaleBefore: state.W05F0003.paramBySaleBefore,
    dataBySaleContracts: state.W05F0003.dataBySaleContracts,
}),
    dispatch => ({
        w05f0003Actions: bindActionCreators(W05F0003Actions, dispatch)
    })
), withStyles(styles))(W05F0003TabMapping);
