/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/07/2020
 * @Example
 */
import { withStyles } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as W05F0003Actions from "../../../../redux/W0X/W05F0003/W05F0003_actions";
import { Combo, TextField } from "../../../common/form-material";
import { MoneyInput } from 'diginet-core-ui/components';

const styles = {
  labelNumberFormat: {
    padding: "6.5px 0 !important",
    transform: "translateY(10px) !important",
    color: "#8F9BB3 !important"
  },
};
class W05F0003GeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataCboEmployees: {
        rows: [],
        total: 0
      },
      loadingCboEmployee: false,
    };
    this.filterCboEmployees = {
      strSearch: "",
      skip: 0,
      limit: 10
    };
  }

  componentDidMount = () => {
    const { dataCboEmployees } = this.props;
    // const { formDataMaster, getDecimalQuantity } = this.props;
    // this.setState({ ExchangeRate: this.numberFormat(formDataMaster.ExchangeRate) });
    // if (formDataMaster.ExchangeRate && getDecimalQuantity.ExchangeRateDecimals) { // mode === copy or edit // then format exchangeRate
    //   this.setState({ ExchangeRateDecimals: getDecimalQuantity.ExchangeRateDecimals });
    //   const ExchangeRate = this.numberFormat(formDataMaster.ExchangeRate, getDecimalQuantity.ExchangeRateDecimals);
    //   this.setState({ ExchangeRate })
    // }

    if (_.isEmpty(dataCboEmployees)) {
      this.loadCboEmployees();
    } else {
      const { total, rows } = dataCboEmployees;
      this.setState({
        dataCboEmployees: {
          rows,
          total
        }
      });
    }
  }

  loadCboEmployees = (isReset) => {
    const param = {
      StrSearch: this.filterCboEmployees.strSearch,
      skip: this.filterCboEmployees.skip,
      limit: this.filterCboEmployees.limit
    };
    this.setState({ loading: true, loadingCboEmployee: true });
    this.props.w05f0003Actions.loadCboEmployees(param, (error, data) => {
      this.setState({ loading: false, loadingCboEmployee: false });
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show("INFO", message);
        return false;
      }
      if (data) {
        const { dataCboEmployees } = this.state;
        const d = data.rows ? data.rows : data;
        const total = data.total ? data.total : 0;
        this.setState({
          dataCboEmployees: {
            rows: isReset ? d : dataCboEmployees.rows.concat(d),
            total: total
          }
        });
      }
    });
  };

  // numberFormat = (number, quanlityDecimal) => {
  //   const formatter = new Intl.NumberFormat("en-US", {
  //     minimumFractionDigits: quanlityDecimal,
  //     maximumFractionDigits: quanlityDecimal,
  //   });
  //   const numberFormatted = formatter.format(String(number));
  //   const removeComma = numberFormatted.replace(/,/gi, '');
  //   return !removeComma || removeComma === "NaN" ? 0 : removeComma;
  // };

  render() {
    const { formDataMaster, getDecimalQuantity, classes } = this.props;
    const { dataCboEmployees } = this.state;
    const { ContractTypeName, ContractDate, EStatusName, ContractNo, CurrencyID, StartDate, EndDate, Employee, ExchangeRate, ExceedContract } = formDataMaster;
    return (
      <div style={{ width: "100%" }}>
        <Row>
          <Col md={12} lg={9}>
            <Row>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Loai_hop_dong")}
                  variant={"standard"}
                  margin={"normal"}
                  value={ContractTypeName}
                  InputLabelProps={{ shrink: true }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_So_hop_dong")}
                  variant={"standard"}
                  margin={"normal"}
                  value={ContractNo}
                  disabled={true}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Trang_thai")}
                  variant={"standard"}
                  margin={"normal"}
                  value={EStatusName}
                  disabled={true}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Loai_tien")}
                  variant={"standard"}
                  margin={"normal"}
                  value={CurrencyID}
                  disabled={true}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <label className={`MuiFormLabel-root ${classes.labelNumberFormat}`}>
                  {Config.lang("ERP_Ty_gia")}
                </label>
                <NumberFormat
                  fullWidth
                  customInput={TextField}
                  thousandSeparator={true}
                  isNumericString={true}
                  disabled={true}
                  decimalScale={getDecimalQuantity.ExchangeRateDecimals}
                  value={ExchangeRate}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Ngay_ky")}
                  variant={"standard"}
                  margin={"normal"}
                  value={ContractDate}
                  InputLabelProps={{ shrink: true }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <Combo.Employees
                  acceptCustomValue={true}
                  dataSource={dataCboEmployees}
                  value={Employee}
                  label={Config.lang("ERP_Nguoi_lap")}
                  displayExpr={"EmployeeName"}
                  subTextExpr={"EmployeeID"}
                  stylingMode={"underlined"}
                  valueExpr={"EmployeeID"}
                  keyExpr={"EmployeeID"}
                  showClearButton={true}
                  disabled={true}
                  shrink={true}
                  height={37}
                  onHoverAvatar={() => false}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Ngay_bat_dau")}
                  variant={"standard"}
                  margin={"normal"}
                  value={StartDate}
                  InputLabelProps={{ shrink: true }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Ngay_ket_thuc")}
                  variant={"standard"}
                  margin={"normal"}
                  value={EndDate}
                  InputLabelProps={{ shrink: true }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <MoneyInput
                  thousandSeparator={','}
                  decimalDigit={2}
                  allowZero
                  label={Config.lang("Dung_sai")}
                  disabled
                  value={ExceedContract}
                  endIcon={<div>%</div>}
                />
              </Col>
            </Row>
          </Col>
          <Col md={0} lg={3}></Col>
        </Row>

      </div>
    );
  }
}

W05F0003GeneralInfo.propTypes = {
  getDecimalQuantity: PropTypes.object,
  formDataMaster: PropTypes.object,
};

export default compose(connect(
  (state) => ({
    dataCboEmployees: state.W05F0003.dataCboEmployees,
  }),
  dispatch => ({
    w05f0003Actions: bindActionCreators(W05F0003Actions, dispatch)
  })
), withStyles(styles, { withTheme: true }))(W05F0003GeneralInfo);