/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 06/15/2020
 * @Example
 */
import { Accordion as ExpansionPanel, AccordionDetails as ExpansionPanelDetails, AccordionSummary as ExpansionPanelSummary, Tab, Tabs, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import moment from 'moment';
import React from "react";
import { Col, FormGroup, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W05F0003Actions from "../../../../redux/W0X/W05F0003/W05F0003_actions";
import ButtonExportExcel from "../../../common/button/button-export-excel";
import ButtonGeneral from "../../../common/button/button-general";
import AttachmentCustom from "../../../common/files/Attachments";
import TabContent, { TabPanel } from "../../../common/tabs/tab-content";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ViewHistory from '../../../common/view-history/ViewHistory';
import W05F0003GeneralInfo from "./W05F0003GeneralInfo";
import W05F0003Inventory from "./W05F0003Inventory";
import W05F0003ObjectCustomer from "./W05F0003ObjectCustomer";
import W05F0003OtherInfo from "./W05F0003OtherInfo";
import W05F0003PhaseGrid from "./W05F0003PhaseGrid";
import W05F0003ShippingPayment from "./W05F0003ShippingPayment";
import W05F0003TabMapping from "./W05F0003TabMapping";
const styles = theme => {
    return {
        collapseBoardContent: {
            marginBottom: 18,
            padding: '0 !important'
        },
        heading: {
            fontSize: 12,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            transform: 'translateY(3px)',
            color: theme.palette.info.main
        },
        collapseBoard: {
            position: 'initial !important',
            boxShadow: 'initial !important',
            margin: '0 0 5px 0 !important',
            '& div.MuiAccordionSummary-content': {
                margin: '0 !important'
            }
        },
        ExpansionPanelSummary: {
            minHeight: 'initial !important',
            padding: '0 !important',
            width: '100%',
            color: theme.palette.primary.main,
            '& .MuiExpansionPanelSummary-content': {
                margin: '0 !important'
            },
            '&.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
    };
};

class W05F0003 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            formLoading: false,
            phaseLoading: false,
            loadApiStatus: true,
            historyExpand: false,
            formViewLoading: false,
            generalInforExpand: true,
            shippingPaymentExpand: false,
            btnReadMoreLoading: false,
            otherInforExpand: false,
            btnReadMoreStatus: true,
            mappingTabLoaded: false,
            inventoryExpand: false,
            historyLoading: false,
            cboReportPopup: false,
            customerExpand: true,
            mobileOnly: window.innerWidth < 576,
            tabKey: 0,
            totalPhase: 0,
            totalHistory: 0,
            phasePermission: 0,
            mappingPermission: 0,
            ReportID: "",
            dataMaster: {},
            contractTypesData: {},
            dataPhase: [],
            dataHistory: [],
            dataAttachments: [],
            dataInventories: [],
            activeInventoryColumn: [],
            W06F0004Permission: 0,
            W06F0005Permission: 0,
        };
        this.filterHistory = {
            skip: 0,
            limit: 10,
            sort: "",
            keyword: ""
        };
        this.filterPhase = {
            ContractID: "",
            ContractNo: "",
            Status: "",
            Object: [],
            selectedDate: 5,
            DateFrom: "",
            DateTo: "",
            Language: Config.language || "84",
            skip: 0,
            limit: 2,
        };
        this.dataInfo = {
            mode: "",
            ContractID: "",
            isPermission: "",
            cboContractTypes: [],
            Mode: 0
        };
        this.TableName = "D49T2000-SO";
    }

    showLoadingFollowApi = (apiName) => {
        switch (apiName) {
            case "loadHistory":
                this.setState({ historyLoading: true });
                break;
            case "loadFormView":
                this.setState({ formViewLoading: true });
                break;
            default:
                this.setState({ formLoading: true });
                break;
        }
    }

    closeLoadingFollowApi = (apiName) => {
        switch (apiName) {
            case "loadHistory":
                this.setState({ historyLoading: false });
                break;
            case "loadFormView":
                this.setState({ formViewLoading: false });
                break;
            default:
                break;
        }
    }

    handleLoadDataApi = (apiName, data) => {
        const { dataDetailSupplement } = this.props;
        const { Language } = this.filterPhase;
        switch (apiName) {
            case "loadFormView":
                const { master = {}, attachments = [], inventories = [] } = data;
                const _master = { ...master };
                Object.keys(_master).forEach(dataMaster => {
                    if (Config.isValidDateTime(_master[dataMaster])) {
                        _master[dataMaster] = moment(_master[dataMaster]).format("DD/MM/YYYY")
                    }
                });
                const { cboContractTypes } = this.dataInfo;
                const contractTypesData = cboContractTypes && cboContractTypes.find(
                    (element) => element.TransTypeID === master.TransTypeID
                );
                if (_master?.ExceedContract) {
                    _master.ExceedContract = _master.ExceedContract * 100;
                }
                this.setState({
                    contractTypesData,
                    dataMaster: {
                        ..._master,
                        Employee: {
                            EmployeeID: master.EmployeeID || "",
                            EmployeeName: master && master.EmployeeName ? master.EmployeeName : master.EmployeeID,
                            UserPictureURL: master.UserPictureURL || null,
                        }
                    },
                    dataAttachments: attachments,
                    dataInventories: inventories
                }, () => {//Load Detail Supplement
                    if (_.isEmpty(dataDetailSupplement)) {
                        this.loadApi("getDetailSupplement", { Language }, true);
                    } else {
                        this.handleActiveInventoryColumn(dataDetailSupplement);
                    }
                });
                if (master.CurrencyID) {
                    const { CurrencyID } = data.master;
                    this.loadApi("getDecimalQuanlity", { CurrencyID }, true);
                }
                if (master.ContractID) {
                    const { phasePermission } = this.state;
                    if (phasePermission > 0) {
                        this.filterPhase.ContractID = master.ContractID || "";
                        this.filterPhase.ContractNo = master.ContractNo || "";
                        this.filterPhase.ObjectID = master.ObjectID || "";
                        this.loadListDeployments();
                    }
                }
                break;
            case "loadHistory":
                this.setState({
                    dataHistory: data.rows || [],
                    totalHistory: data.total || 0
                });
                break;
            case "getDetailSupplement":
                this.handleActiveInventoryColumn(data);
                break;
            default:
                break;
        }
    }

    handleActiveInventoryColumn = (dataDetailSupplement) => {
        let activeInventoryColumn = [];
        const { contractTypesData } = this.state;
        if (dataDetailSupplement.length > 0 && !_.isEmpty(contractTypesData)) {
            const displayName = "Display";
            activeInventoryColumn = dataDetailSupplement.filter((keyName) => {
                if (keyName.FieldName.includes("Str") && keyName.FieldName.includes("U")) {
                    keyName.FieldName = keyName.FieldName.slice(0, -1) // Bỏ chứ U phía sau DisplayStr..U  vì Data trả về là DisplayStr..
                } else if (keyName.FieldName.includes("Dat")) {
                    keyName.DefaultValueU = moment(keyName.DefaultValueU, "DD/MM/YYYY").format('YYYY-MM-DD');
                }
                return contractTypesData[displayName.concat(keyName.FieldName)] === 1
            });
            this.setState({ activeInventoryColumn })
        }
    }

    loadApi = (cboName, params = {}, ownAction = false) => {
        this.showLoadingFollowApi(cboName);
        this.props[ownAction ? "w05f0003Actions" : "generalActions"][cboName](params, (error, data = null) => {
            this.setState({ formLoading: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            else if (cboName && data) {
                this.handleLoadDataApi(cboName, data);
            }
            this.closeLoadingFollowApi(cboName);
        });
    };

    loadListDeployments = (eReset) => {
        const { ContractID, ContractNo, ObjectID, skip, Status, limit, DateFrom, DateTo, Language } = this.filterPhase;
        const { totalPhase } = this.state;
        const params = {
            ContractID,
            ContractNo,
            ObjectID,
            Language,
            DateFrom,
            DateTo,
            Status,
            skip,
            limit,
        };
        if (!(skip > 1) || totalPhase > skip) {
            if (skip === 0) this.setState({ phaseLoading: true })
            this.props.w05f0003Actions.getListDeployments(params, (error, data) => {
                this.setState({ phaseLoading: false })
                if (!(skip > 1) || totalPhase > skip) {
                    if (error) {
                        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    }
                    if (data) {
                        let { dataPhase } = this.state;
                        const newData = data && data.rows ? data.rows : [];
                        this.setState(
                            {
                                dataPhase: !eReset ? dataPhase.concat(newData) : newData,
                                totalPhase: data.total || 0
                            }, () => {
                                this.setState({ btnReadMoreLoading: false });
                            }
                        );
                    }
                }
            });
        }
    };

    onReadMore = () => {
        this.setState({ btnReadMoreLoading: true, btnReadMoreStatus: true });
        const { totalPhase } = this.state;
        this.filterPhase.skip += this.filterPhase.limit;
        if (totalPhase - this.filterPhase.skip <= this.filterPhase.limit) {
            this.setState({ btnReadMoreStatus: false })
        }
        this.loadListDeployments();
    };

    getInfo = () => {
        const { location } = this.props;
        if (location && !_.isEmpty(location.state)) {
            if (location.state.isPermission <= 0) return;
            this.dataInfo.mode = location.state.mode || "add";
            this.dataInfo.ContractID = location.state.ContractID || "";
            this.dataInfo.cboContractTypes = location.state.cboContractTypes || [];
            this.dataInfo.isPermission = location.state.isPermission || "";
            this.dataInfo.Mode = location.state.Mode || 0;
        } else {
            this.setState({ loadApiStatus: false }, () => {
                browserHistory.push(Config.getRootPath() + "W05F0001");
                return null;
            })
        }
    };

    checkPageName = async (permisionItem) => {
        const { ScreenID, Permission } = permisionItem;
        const { tabKey, phasePermission } = this.state;
        let _currentTabKey = 0;
        switch (ScreenID) {
            case "W05F0004":
                if (!(Permission > 0)) {
                    _currentTabKey = 1;
                }
                await this.setState({ phasePermission: Permission });
                break;
            case "W05F0006":
                if (!(Permission > 0) && !(phasePermission > 0)) {
                    _currentTabKey = 2;
                }
                await this.setState({ mappingPermission: Permission });
                break;
            case "W06F0004":
                this.setState({ W06F0004Permission: Permission });
                break;
            case "W06F0005":
                this.setState({ W06F0005Permission: Permission });
                break;
            default:
                break;
        }
        if (tabKey !== _currentTabKey) this.setState({ tabKey: _currentTabKey });
    }

    loadPermission = async (formID) => {
        await this.props.generalActions.getPermission(formID, (isPer) => {
            if (!_.isEmpty(isPer)) {
                isPer.map(item => this.checkPageName(item));
            }
        }, true);
    };

    componentDidMount = async () => {
        await this.getInfo();
        if (this.state.loadApiStatus) {
            const { ContractID, Mode } = this.dataInfo;
            const { Language } = this.filterPhase;
            const { getCboBaseCurrency, paramsMasterSup } = this.props;
            const { limit, skip, sort, keyword } = this.filterHistory;
            const TableName = this.TableName;
            const condition = JSON.stringify({ codeID: ContractID });
            //Load phase permision and mapping permision 
            await this.loadPermission(["W05F0004", "W05F0006", "W06F0004", "W06F0005"]);
            //Load Data
            await this.loadApi("loadFormView", { ContractID, Language, Mode }, true);
            //Load Data History
            this.loadApi("loadHistory", { keyword, condition, limit, skip, sort }, false);
            //Other Information
            if (paramsMasterSup && paramsMasterSup.TableName !== TableName) this.loadApi("getMasterSupplement", { TableName, Language }, true);
            //Base Currency
            if (_.isEmpty(getCboBaseCurrency)) this.loadApi("loadBaseCurrency", { Language }, true);
        }
    };

    onBack = () => {
        browserHistory.push(Config.getRootPath() + "W05F0001");
    };

    redirectScreen = (mode) => {
        const { ContractID } = this.dataInfo;
        let param = { mappingID: "" };
        let screen = "";
        switch (mode) {
            case "addPhase":
                screen = "W05F0005";
                param.ContractID = ContractID;
                param.mode = "add";
                break;
            case "addMapping":
                screen = "W05F0007";
                param.mode = "add";
                break;
            case "W06F0004":
                screen = "W06F0004";
                break;
            case "W06F0005":
                screen = "W06F0005";
                break;
            default:
                break;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: param
        });
    };

    redirectEditScreen = () => {
        const { contractTypesChosen, cboContractTypes, ContractID, isPermission } = this.dataInfo;
        const screen = "W05F0002";
        const mode = "edit"
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: {
                mode,
                ContractID,
                isPermission,
                cboContractTypes,
                contractTypesChosen
            }
        });
    };

    onChangeHistoryPage = (page) => {
        const { ContractID } = this.dataInfo;
        this.filterHistory.skip = page * this.filterHistory.limit;
        this.loadApi("loadHistory", {
            keyword: "",
            condition: JSON.stringify({ codeID: ContractID }),
            limit: this.filterHistory.limit,
            skip: this.filterHistory.skip,
            sort: ""
        }, false);
    };

    onChangeHistoryPerPage = (perPage) => {
        const { ContractID } = this.dataInfo;
        this.filterHistory.skip = 0;
        this.filterHistory.limit = perPage;
        this.loadApi("loadHistory", {
            keyword: "",
            condition: JSON.stringify({ codeID: ContractID }),
            limit: this.filterHistory.limit,
            skip: this.filterHistory.skip,
            sort: ""
        }, false);
    };

    handleTabChange = (e, key) => {
        this.setState({ tabKey: key });
    };

    render() {
        const { cboContractTypes, isPermission, Mode } = this.dataInfo;
        if (isPermission <= 0) return null;
        const { generalInforExpand, customerExpand, shippingPaymentExpand, otherInforExpand, inventoryExpand, historyExpand, formLoading,
            dataHistory, totalHistory, ReportID, historyLoading, formViewLoading, totalPhase, phasePermission, mappingPermission, contractTypesData,
            dataMaster, dataAttachments, dataInventories, dataPhase, btnReadMoreLoading, btnReadMoreStatus, phaseLoading, tabKey, activeInventoryColumn, mobileOnly, W06F0004Permission, W06F0005Permission } = this.state;
        const { classes, getDecimalQuantity } = this.props;
        const { ContractID, TransTypeID, TransTypeName } = dataMaster;
        const checkInvenLoadingExpand = inventoryExpand ? formViewLoading : false;
        const checkHistoryLoadingExpand = historyExpand ? historyLoading : false;
        const iconArrowDown = require('../../../../assets/images/general/arrow_down.svg');
        const iconArrowRight = require('../../../../assets/images/general/arrow_right.svg');
        const generalIconExpandSrc = generalInforExpand ? iconArrowDown : iconArrowRight;
        const customerIconExpandSrc = customerExpand ? iconArrowDown : iconArrowRight;
        const shippingPaymentIconExpandSrc = shippingPaymentExpand ? iconArrowDown : iconArrowRight;
        const otherInforIconExpandSrc = otherInforExpand ? iconArrowDown : iconArrowRight;
        const inventoryIconExpandSrc = inventoryExpand ? iconArrowDown : iconArrowRight;
        const historyIconExpandSrc = historyExpand ? iconArrowDown : iconArrowRight;
        const StoreParams = [
            { id: "ContractID", type: "VarChar", value: ContractID },
            { id: "ReportID", type: "VarChar", value: ReportID },
            { id: "Language", type: "Varchar", value: this.filterPhase.Language },
            { id: "UserID", type: "VarChar", value: Config.profile.UserID || "" },
            { id: "FormID", type: "VarChar", value: "W05F0003" },
            { id: "DataType", type: "VarChar", value: "ExportReport" },
            { id: "Mode", type: "Tinyint", value: Mode }
        ];
        const btnAddPhaseStatus = !(phasePermission > 1) || _.toNumber(dataMaster.EStatus) !== 3;
        const btnAddMappingStatus = !(mappingPermission > 1) || _.toNumber(dataMaster.EStatus) !== 3;
        return (
            <>
                <ActionToolbar
                    allwaysTop
                    title={Config.lang("ERP_Chi_tiet_hop_dong_ban")}
                    onBack={this.onBack}>
                    <div style={{ display: mobileOnly ? '' : 'inline-flex' }}>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            {Mode !== 1 &&
                                <ButtonGeneral
                                    name={Config.lang("ERP_Sua")}
                                    size={"large"}
                                    typeButton={"edit"}
                                    className={"mgr10"}
                                    disabled={formLoading || isPermission < 3}
                                    style={{ textTransform: 'uppercase' }}
                                    onClick={this.redirectEditScreen}
                                />
                            }
                            <ButtonExportExcel
                                name={Config.lang("ERP_Xuat_du_lieu")}
                                size={"large"}
                                color={"primary"}
                                variant={"outlined"}
                                style={{ textTransform: 'uppercase' }}
                                disabled={formLoading}
                                ReportTypeID={"W05F0003"}
                                ModuleID={"05"}
                                StoreParams={StoreParams}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} {...(window.innerWidth >= 992 ? { style: { textAlign: 'right', position: 'absolute', right: 0 } } : {})} >
                            {TransTypeName &&
                                <ButtonGeneral
                                    className={"bg_none"}
                                    name={`${TransTypeID} - ${TransTypeName}`}
                                    typeButton={"custom_edit"}
                                    reverseIcon={true}
                                    variant={"custom"}
                                    size={"large"}
                                    disabled={true} //Mode = view = disable
                                    style={{ textTransform: "uppercase", maxWidth: '100%', justifyContent: 'end', transform: mobileOnly ? 'translateY(6px)' : '', display: mobileOnly ? 'inline-grid' : '' }}
                                />
                            }
                        </Col>
                    </div>
                </ActionToolbar>
                {
                    (!_.isEmpty(dataMaster) && !_.isEmpty(getDecimalQuantity)) &&
                    <div style={{ paddingTop: 16, width: '100%' }}>
                        <React.Fragment>
                            <ExpansionPanel
                                expanded={generalInforExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ generalInforExpand: !generalInforExpand })}
                            >
                                <ExpansionPanelSummary className={classes.ExpansionPanelSummary} >
                                    <Image src={generalIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>{Config.lang("ERP_Thong_tin_chung")}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                    <W05F0003GeneralInfo
                                        formDataMaster={dataMaster}
                                        getDecimalQuantity={getDecimalQuantity}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={customerExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ customerExpand: !customerExpand })}
                            >
                                <ExpansionPanelSummary
                                    className={classes.ExpansionPanelSummary}
                                >
                                    <Image src={customerIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>
                                        {Config.lang("ERP_Khach_hang")}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                    <W05F0003ObjectCustomer
                                        contractTypesData={contractTypesData}
                                        formDataMaster={dataMaster}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={shippingPaymentExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ shippingPaymentExpand: !shippingPaymentExpand })}
                            >
                                <ExpansionPanelSummary
                                    className={classes.ExpansionPanelSummary}
                                >
                                    <Image src={shippingPaymentIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>
                                        {Config.lang("ERP_Van_chuyen_thanh_toan")}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                    <W05F0003ShippingPayment
                                        formDataMaster={dataMaster}
                                        getDecimalQuantity={getDecimalQuantity}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={otherInforExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ otherInforExpand: !otherInforExpand })}
                            >
                                <ExpansionPanelSummary
                                    className={classes.ExpansionPanelSummary}
                                >
                                    <Image src={otherInforIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>
                                        {Config.lang("ERP_Thong_tin_khac")}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                    <W05F0003OtherInfo
                                        formDataMaster={dataMaster}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={inventoryExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ inventoryExpand: !inventoryExpand })}
                            >
                                <ExpansionPanelSummary
                                    className={classes.ExpansionPanelSummary}
                                >
                                    <Image src={inventoryIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>
                                        {Config.lang("ERP_Hang_hoa")}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                    <W05F0003Inventory
                                        loading={checkInvenLoadingExpand}
                                        formDataMaster={dataMaster}
                                        contractTypesData={contractTypesData}
                                        cboContractTypes={cboContractTypes}
                                        formDataInventories={dataInventories}
                                        getDecimalQuantity={getDecimalQuantity}
                                        activeInventoryColumn={activeInventoryColumn}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={historyExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ historyExpand: !historyExpand })}
                            >
                                <ExpansionPanelSummary
                                    className={classes.ExpansionPanelSummary}
                                >
                                    <Image src={historyIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>
                                        {Config.lang("ERP_Lich_su")}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{ padding: '18px 18px 0 18px' }}>
                                    <ViewHistory
                                        dataHistory={dataHistory}
                                        totalItems={totalHistory}
                                        loading={checkHistoryLoadingExpand}
                                        skip={this.filterHistory.skip}
                                        limit={this.filterHistory.limit}
                                        onChangePage={this.onChangeHistoryPage}
                                        onChangePerPage={this.onChangeHistoryPerPage}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            {!_.isEmpty(dataAttachments) && <div style={{ marginTop: 30 }}>
                                <AttachmentCustom data={dataAttachments} />
                            </div>}
                            <Tabs
                                value={tabKey}
                                onChange={this.handleTabChange}
                                indicatorColor={"primary"}
                                textColor={"primary"}
                                component={"div"}
                                variant={"scrollable"}
                                scrollButtons={"off"}
                                style={{ fontSize: 16, marginTop: 13 }}
                                aria-label={"full width tabs"}
                            >
                                {phasePermission > 0 && <Tab value={0} label={Config.lang("ERP_Dot_trien_khai")} />}
                                {mappingPermission > 0 && <Tab value={1} label={Config.lang("ERP_Mapping")} />}
                            </Tabs>
                            <TabContent
                                style={{ backgroundColor: '#fff' }}
                                activeKey={tabKey}
                                disableSwipe={true}
                                lazyLoading={true}
                                onChangeIndex={this.handleChangeIndex}>
                                <TabPanel index={0}>
                                    <ButtonGeneral
                                        name={Config.lang("ERP_Them")}
                                        size={"large"}
                                        typeButton={"add"}
                                        disabled={btnAddPhaseStatus}
                                        style={{ textTransform: "uppercase", marginTop: 24 }}
                                        onClick={() => this.redirectScreen("addPhase")}
                                    />
                                    {dataPhase &&
                                        dataPhase.map(({ detail, master }, index) => {
                                            return (
                                                <W05F0003PhaseGrid
                                                    key={index}
                                                    master={master}
                                                    dataSource={detail}
                                                    phaseLoading={phaseLoading}
                                                    permission={phasePermission}
                                                    loadListDeployments={() => this.loadListDeployments(true)}
                                                />
                                            );
                                        })}
                                    {dataPhase && dataPhase.length < 1 && (
                                        <W05F0003PhaseGrid permission={isPermission} master={{}} dataSource={[]} phaseLoading={phaseLoading} />)}
                                    {!_.isEmpty(dataPhase) && btnReadMoreStatus && totalPhase > this.filterPhase.limit && (
                                        <FormGroup className={classes.viewMore}>
                                            <ButtonGeneral
                                                name={Config.lang("ERP_Xem_them")}
                                                fullWidth
                                                size={"large"}
                                                typeButton={"add"}
                                                variant={"text"}
                                                color={"inherit"}
                                                icon={require("./../../../../assets/images/viewmore.svg")}
                                                loading={btnReadMoreLoading}
                                                onClick={() => this.onReadMore()}
                                            />
                                        </FormGroup>
                                    )}
                                </TabPanel>
                                <TabPanel index={1}>
                                    <ButtonGeneral
                                        name={Config.lang("ERP_Them_mapping")}
                                        size={"large"}
                                        typeButton={"add"}
                                        disabled={btnAddMappingStatus}
                                        style={{ textTransform: "uppercase", marginTop: 24 }}
                                        onClick={() => this.redirectScreen("addMapping")}
                                    />
                                    {W06F0004Permission > 0 &&
                                        <ButtonGeneral
                                            name={Config.lang("ERP_Thong_bao_giao_hang")}
                                            size={"large"}
                                            typeButton={"W06F0004"}
                                            style={{ textTransform: "uppercase", marginTop: 24, marginLeft: Config.isMobile ? 0 : 10 }}
                                            onClick={() => this.redirectScreen("W06F0004")}
                                        />
                                    }
                                    {W06F0005Permission > 0 &&
                                        <ButtonGeneral
                                            name={Config.lang("ERP_Xac_nhan_giao_hang")}
                                            size={"large"}
                                            typeButton={"confirm_delivery"}
                                            style={{ textTransform: "uppercase", marginTop: 24, marginLeft: Config.isMobile ? 0 : 10 }}
                                            onClick={() => this.redirectScreen("W06F0005")}
                                        />
                                    }
                                    <W05F0003TabMapping
                                        permission={mappingPermission}
                                        dataMaster={dataMaster}
                                    />
                                </TabPanel>
                            </TabContent>
                        </React.Fragment>
                    </div>
                }
            </>
        );
    }
}
export default compose(
    connect(
        (state) => ({
            getDecimalQuantity: state.W05F0003.getDecimalQuantity,
            paramsMasterSup: state.W05F0003.paramsMasterSup,
            dataDetailSupplement: state.W05F0003.dataDetailSupplement,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w05f0003Actions: bindActionCreators(W05F0003Actions, dispatch)
        })
    ),
    withStyles(styles, { withTheme: true })
)(W05F0003);
